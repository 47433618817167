import React from "react";
import styled from "styled-components";

function TagsViewer({ tags,all, style={}  }) {
    let showTags =  all ? tags : tags.slice(0, 4)
  return (
    <Container style={{ ...style }}>
      {showTags.map((tag) => (
        <Tag>{tag}</Tag>
      ))}
      {!all && tags.length > 4 && <Tag>+{tags.length - 4}</Tag>}
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Tag = styled.div`
  background-color: #333;
  padding: 1px 4px;
  border-radius: 5px;
  margin: 5px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
`;
export default TagsViewer;
