import React, { useEffect, useState } from "react";

import * as S from "../../styles";
import { TitleContainer } from "../../styles";
import { Button, Input, Label, TwoColumns } from "../../../main-styles";
import WYSEditor from "../../../../components/WYSEditor";
import { Content, ReportSlot } from "../styled";
import { REQ_STATUS } from "../../../../util/constants";
import { useDispatch } from "react-redux";
import { USERS } from "../../../../api/USERS";
import { Spinner } from "reactstrap";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { UTILS } from "../../../../api/UTILS";
import { uploadImage } from "../../../../util/functions";
import { updateUser } from "../../../../redux/reducers/auth";

function Perfil({noheader}) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    name: "",
    email: "",
    thumbnail: "",
    signature: "",
    password: "",
    passwordConfirmation: "",
    currentPassword: "",
    fotoFile: null,
    preview: null,
  });

  const {
    status,
    passwordConfirmation,
    password,
    currentPassword,
    name,
    email,
    thumbnail,
    signature,
    fotoFile,
    preview,
  } = state;

  useEffect(() => {
    load();
  }, []);

  async function load() {
    try {
      const data = await USERS.get.me();
      console.log("updated user:", data);
      setState({
        status: REQ_STATUS.IDLE,
        name: data.name,
        email: data.email,
        thumbnail: data.thumbnail,
        signature: data.signature,
      });
    } catch (e) {
      setState({ status: REQ_STATUS.ERROR, data: {} });
    }
  }

  async function changePassword() {
    const { currentPassword, password, passwordConfirmation } = state;
    if (!currentPassword || !password || !passwordConfirmation) {
      toast.error("Preencha todos os campos");
      return;
    }
    if (password.length < 6) {
      toast.error("A senha deve ter no mínimo 6 caracteres");
      return;
    }
    if (password !== passwordConfirmation) {
      toast.error("As senhas não conferem");
      return;
    }

    try {
      await USERS.set.updatePassword(password, currentPassword);
      toast.success("Senha alterada com sucesso");
      setState((s) => ({
        ...s,
        password: "",
        passwordConfirmation: "",
        currentPassword: "",
      }));
    } catch (e) {
      console.error(e);
      toast.error(e.response.data.error);
    }
  }
  function selecionaFoto() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = (e) => {
      const file = e.target.files[0];
      if (!file) return; // caso o usuário cancele a seleção

      const reader = new FileReader();
      reader.onload = (event) => {
        setState((prevState) => ({
          ...prevState,
          fotoFile: file,
          preview: event.target.result,
        }));
      };
      reader.readAsDataURL(file);
    };

    // Chama o clique para abrir a janela de seleção
    input.click();
  }

  async function updateProfile() {
    if (name.length < 2) {
      toast.error("O campo nome é obrigatório");
      return;
    }
    try {
      let profileURL = thumbnail;
      if (preview) {
        //faz upload da foto
        profileURL = await uploadImage(fotoFile);
        console.log("profileURL", profileURL);
      }

      let response = await USERS.set.updateProfile(name, profileURL, signature);
      console.log("response", response);
      toast.success("Perfil atualizado com sucesso");
      setState((s) => ({
        ...s,
        thumbnail: profileURL,
        preview: null,
        fotoFile: null,
      }));
      dispatch(
        updateUser({
          name,
          thumbnail: profileURL,
          signature,
        })
      );
    } catch (e) {
      console.error(e);
      toast.error("Erro ao atualizar perfil");
    }
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <Content>
        <Spinner />
      </Content>
    );
  }

  return (
    <>
      {!noheader && <TitleContainer bottom={true}>Configurações de Perfil</TitleContainer>}
      <TwoColumns style={{ flex: 1, alignItems: "flex-start" }}>
        <Content>
          <h4>Perfil</h4>
          <Label>Nome</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setState((s) => ({ ...s, name: e.target.value }))}
          />
          <Label>Email</Label>
          <Input type="email" value={email} disabled={true} />
          <Label>Foto do perfil</Label>
          <div style={{ position: "relative" }}>
            <img
              src={preview ? preview : thumbnail}
              alt="thumbnail"
              onClick={selecionaFoto}
              style={{
                width: 100,
                height: 100,
                objectFit: "cover",
                borderRadius: "50%",
                cursor: "pointer",
                border: "1px solid #ddd",
              }}
            />
            {preview && (
              <div
                style={{
                  cursor: "pointer",
                  color: "red",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  maxWidth: 100,
                  fontSize: 20,
                }}
                onClick={() => {
                  setState((s) => ({ ...s, preview: null, fotoFile: null }));
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            )}
          </div>

          <Label>Assinatura</Label>
          <WYSEditor
            value={signature}
            onChange={(value) => setState((s) => ({ ...s, signature: value }))}
          />
        </Content>
        <Content>
          <h4>Troca de senha</h4>
          <Label>Senha atual</Label>
          <Input
            type="password"
            value={currentPassword}
            onChange={(e) =>
              setState((s) => ({ ...s, currentPassword: e.target.value }))
            }
          />
          <Label>Senha</Label>
          <Input
            type="password"
            value={password}
            onChange={(e) =>
              setState((s) => ({ ...s, password: e.target.value }))
            }
          />
          <Label>Confirmação de senha</Label>
          <Input
            type="password"
            value={passwordConfirmation}
            onChange={(e) =>
              setState((s) => ({ ...s, passwordConfirmation: e.target.value }))
            }
          />
        </Content>
        <Content>
          <Button onClick={updateProfile}>Salvar</Button>
        </Content>
        <Content>
          <Button onClick={changePassword}>Alterar senha</Button>
        </Content>
      </TwoColumns>
    </>
  );
}

export default Perfil;
