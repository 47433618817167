import React, { useState } from "react";

import * as S from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

function FAQ() {
  const { t } = useTranslation();
  return (
    <S.FAQContainer id="FAQ">
      <h1>{t("home.faq.faq")}</h1>
        <Question
          question={t('home.faq.questions.o-que-e-um-sistema-de-help-desk')}
          answer={t('home.faq.answers.um-sistema-de-help-desk-e-uma-ferramenta')}
          // answer=
        />
        <Question
          question={t('home.faq.questions.como-funciona-a-precificacao')}
          answer={t('home.faq.answers.como-funciona-a-precificacao')}
        />
        <Question
          question={t('home.faq.questions.o-que-e-um-agente')}
          answer={t('home.faq.answers.o-que-e-um-agente')}
        />

        <Question
          question={t('home.faq.questions.e-se-minha-equipe-precisar-de-mais-agentes-no-futuro')}
          answer={t('home.faq.answers.e-se-minha-equipe-precisar-de-mais-agentes-no-futuro')}
        />
        <Question
          question={t('home.faq.questions.posso-testar-o-help-desk-app-antes-de-comprar')}
          answer={t('home.faq.answers.posso-testar-o-help-desk-app-antes-de-comprar')}
        />
        <Question
          question={t('home.faq.questions.e-possivel-personalizar-o-help-desk-app-com-a-identidade-visual-da-minha-empresa')}
          answer={t('home.faq.answers.e-possivel-personalizar-o-help-desk-app-com-a-identidade-visual-da-minha-empresa')}
        />
        <Question
          question={t('home.faq.questions.existe-algum-tipo-de-relatorio-ou-painel-de-analise-disponivel')}
          answer={t('home.faq.answers.existe-algum-tipo-de-relatorio-ou-painel-de-analise-disponivel')} 
        />
        <Question
          question={t('home.faq.questions.o-help-desk-app-oferece-alguma-ferramenta-de-base-de-conhecimento-ou-faq')}
          answer={t('home.faq.answers.o-help-desk-app-oferece-alguma-ferramenta-de-base-de-conhecimento-ou-faq')}
        />
        <Question
          question={t('home.faq.questions.voces-tem-integracao-com-inteligencia-artificial')}
          answer={t('home.faq.answers.voces-tem-integracao-com-inteligencia-artificial')}
        />
        <Question
          question={t('home.faq.questions.outros-sistemas-cobram-por-ticket-voces-tambem')}
          answer={t('home.faq.answers.outros-sistemas-cobram-por-ticket-voces-tambem')}
        />
        <Question
          question={t('home.faq.questions.voces-cobram-por-pesquisa-de-satisfacao')}
          answer={t('home.faq.answers.voces-cobram-por-pesquisa-de-satisfacao')}
        />
        <Question
          question={t('home.faq.questions.e-possivel-criar-um-ticket-automaticamente-quando-receber-um-email')}
          answer={t('home.faq.answers.e-possivel-criar-um-ticket-automaticamente-quando-receber-um-email')}
        />
    </S.FAQContainer>
  );
}

function Question({ question, answer }) {
  const [open, setOpen] = useState(false);
  return (
    <S.FAQQuestion onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
      <h6>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={open ? faAngleDown : faAngleRight}
          style={{ marginRight: 10 }}
        />
        {question}
      </h6>
      {open && <p style={{ marginLeft: 30 }}>{answer}</p>}
    </S.FAQQuestion>
  );
}

export default FAQ;
