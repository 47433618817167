import React from "react";

import { TitleContainer } from "../../routes/agents/styles";
import { FilterArea } from "../../routes/agents/Reports/styled";
import { Button, Input, Label } from "reactstrap";
import { Description } from "../../routes/main-styles";
import moment from "moment-timezone";

function ReportLayout({
  title,
  subtitle,
  children,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  fetchData,
  style = {},
  button = true,
}) {
  return (
    <div>
      <TitleContainer bottom={true}>
        {title}
        {subtitle && (
          <>
            <br />
            <Description>{subtitle}</Description>
          </>
        )}
      </TitleContainer>
      <FilterArea>
        <Label style={{ textWrap: "nowrap" }} for="startDate">
          Data Inicial
        </Label>
        <Input
          type="date"
          id="startDate"
          value={moment(startDate).toISOString()?.split("T")[0]}
          onChange={(e) => setStartDate(new Date(e.target.value))}
        />
        <Label style={{ textWrap: "nowrap" }} for="endDate">
          Data Final
        </Label>
        <Input
          type="date"
          id="endDate"
          value={moment(endDate).toISOString()?.split("T")[0]}
          onChange={(e) =>
            setEndDate(moment(e.target.value).endOf("day").toDate())
          }
        />
        {button && (
          <Button color="primary" onClick={fetchData}>
            Atualizar
          </Button>
        )}
      </FilterArea>
      <div style={{ paddingTop: "3rem", ...style }}>{children}</div>
    </div>
  );
}

export default ReportLayout;
