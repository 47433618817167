import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Outlet,
  useNavigate
} from "react-router-dom";
import { Subtitle } from "../../main-styles.js";
import { TitleContainer } from "../styles.js";
import {
  Links,
  ReportArea,
  ReportLink,
  Sidebar,
  Wrapper
} from "./styled.js";
import { useSelector } from "react-redux";

const reports = [
  { divider: true, name: "Usuário", roles: ["agent", "manager", "admin"] },
  { id: "/usuario/perfil", name: "Perfil", roles: ["agent", "manager", "admin"]  },
  { divider: true, name: "Empresa", roles: ["manager", "admin"]  },
  { id: "/empresa/aparencia", name: "Layout", roles: ["manager", "admin"]  },
  { id: "/empresa/agents", name: "Agentes" , roles: ["manager", "admin"] },
  { id: "/empresa/grupos", name: "Grupos" , roles: ["manager", "admin"] },
];

const Settings = () => {
  const { role } = useSelector((state) => state.authReducer.user);
  const navigate = useNavigate();

  function goto(path) {
    navigate(path);
  }

  return (
    <Wrapper>
      {/* Sidebar com a lista de relatórios */}
      <Sidebar>
        <TitleContainer bottom={true}>Configurações</TitleContainer>
        <Links>
          {reports.map((report) => {
            if(report.roles && !report.roles.includes(role)) return null;
            if (report.divider) {
              return (
                <Subtitle
                  style={{
                    color: "#666",
                    fontSize: "1.01rem",
                    fontWeight: "bold",
                  }}
                >
                  {report.name}
                </Subtitle>
              );
            }

            return (
              <ReportLink
                key={report.id}
                onClick={() => goto(`/agents/configuracoes${report.id}`)}
              >
                <FontAwesomeIcon icon={faChevronRight} />
                {report.name}
              </ReportLink>
            );
          })}
        </Links>
      </Sidebar>

      {/* Área de Relatórios */}
      <ReportArea>
        <Outlet />
      </ReportArea>
    </Wrapper>
  );
};

export default Settings;
