import React from "react";

import * as S from "../styles";
import { Button } from "../../main-styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CTA() {
  const { t, i18n } = useTranslation();

  const { language } = i18n;
  return (
    <S.CTA>
      {t("home.cta.experimente-sem-compromisso-e-veja-como-")} <br />
      <Link to={`/${language}/workspace/new`}>
        <Button>
          {t("home.cta.comece-sua-avaliacao-gratuita-de-14-dias")}
        </Button>
      </Link>
    </S.CTA>
  );
}

export default CTA;
