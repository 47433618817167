import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { REPORTS } from "../../../../../api/REPORTS";
import toast from "react-hot-toast";
import moment from "moment-timezone";
import { TitleContainer } from "../../../styles";
import { FilterArea, GraphArea } from "../../styled";
import ReportLayout from "../../../../../components/ReportLayout";

// Registrar os componentes do Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GroupPerformanceReport = ({ tenantId }) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("day").toDate());
  const [groupData, setGroupData] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setFetching(true);
      // se a data de início for maior que a data final, não faz nada
      if (startDate > endDate) {
        toast.error("Data inicial não pode ser maior que a data final");
        return;
      }
      const response = await REPORTS.get.groups.performance(
        startDate,
        endDate,
        tenantId
      );
      console.log("Group performance data:", response);
      setGroupData(response);
      setFetching(false);
    } catch (error) {
      console.error("Error fetching group performance data:", error);
    }
  };

  const chartData = {
    labels: groupData.map((item) => item.group_name),
    datasets: [
      {
        label: "Tickets Atendidos",
        data: groupData.map((item) => item.tickets_count),
        backgroundColor: "#007bff",
      },
    ],
  };

  return (
    <ReportLayout
      title="Tickets por Grupos de Suporte"
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      fetchData={fetchData}
    >
      <Container style={{ overflow: "auto" }}>
        {fetching && <p>Carregando...</p>}
        {groupData.length > 0 && (
          <>
            <div style={{ maxHeight: "400px" }}>
              <GraphArea>
                <Bar
                  data={chartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                  }}
                />
              </GraphArea>
            </div>
            <Row className="mt-4">
              <Col md={12}>
              <hr/>
                <h4>Detalhes</h4>
                <Table striped>
                  <thead>
                    <tr>
                      <th>Grupo</th>
                      <th>Tickets Atendidos</th>
                      <th>Tempo Médio de Resolução</th>
                      <th>CSAT Médio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.group_name}</td>
                        <td>{item.tickets_count}</td>
                        <td>
                          {item.avg_resolution_time
                            ? moment
                                .duration(
                                  parseFloat(item.avg_resolution_time),
                                  "hours"
                                )
                                .humanize()
                            : "-"}
                        </td>
                        <td>
                          {parseFloat(item.avg_csat)?.toFixed(2) || "-"} (
                          {item.csat_count || 0} avaliações)
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </ReportLayout>
  );
};

export default GroupPerformanceReport;
