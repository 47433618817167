// src/hooks/useWebSocket.js
import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import io from "socket.io-client";
import toast from "react-hot-toast";
import styled from "styled-components";

import NotificationSound from "../../assets/notification/1.wav";
import { setLocked } from "../../redux/reducers/auth";
import { useNavigate } from "react-router-dom";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Example server URL - adjust as needed.
 */
const SERVERURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3005"
    : "https://api.help-desk.app";

export default function useWebSocket() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const socketRef = useRef(null); // holds the socket instance
  const [events, setEvents] = useState([]);

  // ---------------------------------------------------------------------------
  // Helper: play notification sound
  // ---------------------------------------------------------------------------
  const playNotificationSound = useCallback(() => {
    try {
      const audio = new Audio(NotificationSound);
      audio?.play();
    } catch (error) {
      console.error("Failed to play notification sound:", error);
    }
  }, []);

  // ---------------------------------------------------------------------------
  // Helper: show browser notification
  // ---------------------------------------------------------------------------
  const mostrarNotificacao = useCallback(
    (id, mensagem, titulo = "Novo Ticket", link) => {
      // If the window is focused, do not show the browser notification
      if (document.hasFocus()) {
        return;
      }
      console.log("Mostrar notificação:", titulo, mensagem);

      if (!("Notification" in window)) {
        console.log("Este navegador não suporta notificações de desktop.");
        return;
      }

      if (Notification.permission === "granted") {
        const notificacao = new Notification(titulo, {
          body: mensagem,
          icon: "https://www.help-desk.app/assets/favicon.png",
        });

        notificacao.onclick = function () {
          if (id) {
            window.focus();
            navigate(link);
          }
        };
      } else if (Notification.permission !== "denied") {
        Notification?.requestPermission().then((permission) => {
          if (permission === "granted") {
            const notificacao = new Notification(titulo, {
              body: mensagem,
              icon: "https://www.help-desk.app/assets/favicon.png",
            });

            notificacao.onclick = function () {
              if (id) {
                window.focus();
                navigate(link);
              }
            };
          }
        });
      }
    },
    []
  );

  // ---------------------------------------------------------------------------
  // Effect: initialize socket connection once
  // ---------------------------------------------------------------------------
  useEffect(() => {
    // If already connected, do nothing
    if (socketRef.current) {
      console.log("Socket already connected.");
      return;
    }

    // Example of retrieving a token from localStorage
    const token = localStorage.getItem("token");
    if (!token) {
      console.error(
        "Nenhum token encontrado. A conexão WebSocket não será estabelecida."
      );
      return;
    }

    // Initialize socket.io client
    const socket = io(SERVERURL, {
      auth: { token },
      transports: ["websocket"],
    });

    socketRef.current = socket;

    // -------------------------------------------------------------------------
    // Socket event: connect
    // -------------------------------------------------------------------------
    socket.on("connect", () => {
      console.log("Conectado ao servidor WebSocket.");
    });

    // -------------------------------------------------------------------------
    // Socket event: connect_error
    // -------------------------------------------------------------------------
    socket.on("connect_error", (err) => {
      console.error("Erro na conexão:", err.message);
      toast((t) => {
        return (
          <NotificationObj
            link={`https://www.google.com`}
            title={"Opa! Parece que você está offline."}
            message={"Verifique sua conexão e tente novamente."}
            icon={
              <FontAwesomeIcon icon={faTriangleExclamation} color={"#d42700"} />
            }
          />
        );
      });
    });

    // -------------------------------------------------------------------------
    // Socket event: disconnect
    // -------------------------------------------------------------------------
    socket.on("disconnect", (reason) => {
      console.log("Desconectado do servidor WebSocket. Razão:", reason);
    });

    // -------------------------------------------------------------------------
    // Socket event: forceDisconnect (used to lock user, for example)
    // -------------------------------------------------------------------------
    socket.on("forceDisconnect", (reason) => {
      dispatch(setLocked({ locked: true, reason }));
      socket.disconnect();
    });

    // -------------------------------------------------------------------------
    // Socket event: novoTicket
    // -------------------------------------------------------------------------
    socket.on("novoTicket", (data) => {
      console.log("[novoTicket]", data);
      playNotificationSound();
      mostrarNotificacao(
        data.id,
        data.description,
        data.title,
        `/agents/tickets/${data.id}`
      );

      // Show toast notification with a custom component
      toast((t) => {
        return (
          <NotificationObj
            link={`/agents/tickets/${data.id}`}
            title={data.title}
            message={data.description}
            image="https://www.help-desk.app/assets/favicon.png"
          />
        );
      });

      setEvents((prev) => [...prev, { event: "novoTicket", data }]);
    });

    socket.on("ticketRespondido", (data) => {
      console.log("[ticketRespondido]", data);
      playNotificationSound();
      mostrarNotificacao(
        data.id,
        data.description,
        data.title,
        `/agents/tickets/${data.id}`
      );

      // Show toast notification with a custom component
      toast((t) => {
        console.log("data", data);
        return (
          <NotificationObj
            link={`/agents/tickets/${data.id}`}
            title={data.title}
            message={data.description}
            image="https://www.help-desk.app/assets/favicon.png"
          />
        );
      });

      setEvents((prev) => [...prev, { event: "ticketRespondido", data }]);
    });

    // -------------------------------------------------------------------------
    // Example of other event types
    // -------------------------------------------------------------------------
    const eventTypes = ["ticketFechado", "atualizacaoTicket"];
    eventTypes.forEach((eventType) => {
      socket.on(eventType, (data) => {
        toast.success(`${eventType} recebido.`);
        console.log(`[${eventType}]`, data);
        setEvents((prev) => [...prev, { event: eventType, data }]);
      });
    });

    // Example: test the connection
    socket.emit("test", { message: "Teste de conexão" });

    // -------------------------------------------------------------------------
    // Cleanup on unmount
    // -------------------------------------------------------------------------
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
        console.log("Socket desconectado no cleanup.");
      }
    };
  }, [dispatch, mostrarNotificacao, playNotificationSound]);

  return {
    events,
    /**
     * Optionally, you could return a sendMessage or other
     * utilities for sending data to the server, e.g.:
     *
     * sendMessage: (channel, data) => {
     *   if (socketRef.current) {
     *     socketRef.current.emit(channel, data);
     *   }
     * }
     */
  };
}

/**
 * Notification UI component used in toast
 */
function NotificationObj({ link = "/", title, message, image, icon }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(link);
  };

  return (
    <NotificationContainer onClick={handleClick}>
      {image && <NotificationImage src={image} alt="Notification icon" />}
      {icon && icon}
      <div>
        <NotificationTitle>{title}</NotificationTitle>
        <NotificationMessage>{message}</NotificationMessage>
      </div>
    </NotificationContainer>
  );
}

// Styled components for toast UI
const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 300px;
  cursor: pointer;
  align-items: center;
`;

const NotificationTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

const NotificationMessage = styled.div`
  font-size: 12px;
  color: #666;
`;

const NotificationImage = styled.img`
  width: 20px;
  height: 20px;
`;
