import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Bordered, FilterArea } from "../styled";
import { TitleContainer } from "../../styles";
import { Button, FH, Label, Subtitle } from "../../../main-styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/pro-solid-svg-icons";
import { REQ_STATUS } from "../../../../util/constants";
import { KNOWLEDGEBASE } from "../../../../api/KNOWLEDGEBASE";
import { Spinner, Table } from "reactstrap";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import TagsViewer from "../../../../components/TagsViewer";

export const ArticlesContext = React.createContext(null);

function Artigos() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    artigos: [],
    selectedArticle: null,
    tags: [],
  });

  const { status, artigos, articleSearch, selectedArticle, tags } = state;

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    loadArticles();
  }, [articleSearch]);

  async function init() {
    const promises = [loadArticles(), getTags()];
    await Promise.all(promises);
    setState((s) => ({ ...s, status: REQ_STATUS.IDLE }));
  }

  async function getTags() {
    let tags = await KNOWLEDGEBASE.get.tags();
    if (tags) {
      setState((s) => ({ ...s, tags }));
    }
  }

  async function loadArticles() {
    setState((s) => ({ ...s, status: REQ_STATUS.LOADING }));
    let response = await KNOWLEDGEBASE.get.articles(null, articleSearch);
    if (response) {
      setState((s) => ({
        ...s,
        artigos: response,
      }));
    } else {
      setState((s) => ({ ...s, status: REQ_STATUS.ERROR }));
    }
  }

  function selectArticle(article) {
    setState((s) => ({ ...s, selectedArticle: article }));
  }

  async function updateArticle(article) {
    let response = await KNOWLEDGEBASE.set.update_article(article);
    if (response) {
      setState((s) => ({
        ...s,
        artigos: s.artigos.map((a) => (a.id === article.id ? article : a)),
      }));
    }
  }

  function editArticle() {
    navigate(`/agents/conhecimento/artigos/editar/${selectedArticle.id}`);
  }

  function excluirArtigo() {
    if (selectedArticle.published > 0) {
      return toast.error(
        "Não é possível excluir um artigo que está em uma pasta."
      );
    }
    confirmAlert({
      title: "Excluir Artigo",
      message: "Tem certeza que deseja excluir este artigo?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            let response = await KNOWLEDGEBASE.set.delete_article(
              selectedArticle.id
            );
            if (response) {
              setState((s) => ({
                ...s,
                artigos: s.artigos.filter((a) => a.id !== selectedArticle.id),
                selectedArticle: null,
              }));
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <Bordered>
        <Spinner style={{ margin: 20 }} />
      </Bordered>
    );
  }

  return (
    <ArticlesContext.Provider
      value={{ state, setState, loadArticles, updateArticle, selectArticle }}
    >
      <Navegador>
        <ColumnArtigos>
          <Bordered style={{ gap: 0 }}>
            <TitleContainer bottom={true}>Biblioteca de Artigos</TitleContainer>
            <FilterArea>
              <Button
                style={{
                  gap: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 5,
                  backgroundColor: "#707070",
                }}
                onClick={() => navigate("/agents/conhecimento/artigos/novo")}
              >
                <FontAwesomeIcon icon={faPlusCircle} />
                Novo Artigo
              </Button>
            </FilterArea>
            <ArticleList>
              <Table
                striped
                style={{
                  border: "1px solid #e8e8e8",
                  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                  fontSize: 13,
                  borderRadius: 8,
                  overflow: "hidden",
                }}
              >
                <thead>
                  <tr>
                    <th>Artigo</th>
                    <th>Tags</th>
                    <th style={{ textAlign: "center" }}>Última Atualização</th>
                    <th style={{ textAlign: "center" }}>Publicado</th>
                    <th style={{ textAlign: "center" }}>Público</th>
                  </tr>
                </thead>
                <tbody>
                  {artigos.map((artigo) => (
                    <ArticleLine
                      key={artigo.id}
                      artigo={artigo}
                      selected={selectedArticle?.id === artigo.id}
                    />
                  ))}
                </tbody>
              </Table>
            </ArticleList>
          </Bordered>
        </ColumnArtigos>
        <ColumnEditor>
          {selectedArticle && (
            <Bordered>
              <TitleContainer bottom={true}>
                {" "}
                {selectedArticle?.title}
              </TitleContainer>
              <ArticleList style={{ gap: 5 }}>
                <Label>
                  Tags:
                </Label>
                <TagsViewer tags={selectedArticle.tags} all={true} />
                <hr/>
                <Button
                  style={{ backgroundColor: "#ccc", padding: 5 }}
                  onClick={editArticle}
                >
                  Editar
                </Button>
                <Button
                  style={{ backgroundColor: "#ff0000", padding: 5 }}
                  onClick={excluirArtigo}
                >
                  Excluir
                </Button>
              </ArticleList>
            </Bordered>
          )}
        </ColumnEditor>
      </Navegador>
    </ArticlesContext.Provider>
  );
}

function ArticleLine({ artigo, selected }) {
  const { selectArticle } = useContext(ArticlesContext);
  const SELECTED_COLOR = "#b5e8fb";
  return (
    <tr
      style={{ cursor: "pointer" }}
      onClick={() => selectArticle(selected ? null : artigo)}
    >
      <td style={{ backgroundColor: selected ? SELECTED_COLOR : "" }}>
        {artigo.title}
      </td>
      <td style={{ backgroundColor: selected ? SELECTED_COLOR : "" }}>
        <TagsViewer tags={artigo.tags} />
      </td>
      <td
        style={{
          width: 200,
          textAlign: "center",
          backgroundColor: selected ? SELECTED_COLOR : "",
        }}
      >
        {moment(artigo.last_updated).format("DD/MM/YYYY HH:mm")}
      </td>
      <td
        style={{
          width: 100,
          textAlign: "center",
          backgroundColor: selected ? SELECTED_COLOR : "",
        }}
      >
        {artigo.published} pasta(s)
      </td>
      <td
        style={{
          width: 100,
          textAlign: "center",
          backgroundColor: selected ? SELECTED_COLOR : "",
        }}
      >
        {artigo.public ? "Sim" : "Não"}
      </td>
    </tr>
  );
}

const Navegador = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  gap: 1rem;
  height: 100%;
`;

const ColumnArtigos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ColumnEditor = styled.div`
  // span 2
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ArticleList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export default Artigos;
