import React from "react";

import { Button, Title } from "../../../../../main-styles";
import WYSEditor from "../../../../../../components/WYSEditor";
import { TICKETS } from "../../../../../../api/TICKETS";
import toast from "react-hot-toast";

function Solve({ ticket, update }) {
  const [state, setState] = React.useState({
    actions: ticket.actions,
  });

  async function register() {
    const response = await TICKETS.set.solveCSAT(ticket, state.actions);
    if (response) {
      toast.success("Ações registradas com sucesso!");
    }
    update(ticket,  state.actions );
  }

  return (
    <div style={{ padding: "1rem" }}>
      <Title>Ações tomadas</Title>
      <WYSEditor
        value={state.actions}
        onChange={(actions) => setState({ ...state, actions })}
      />
      <Button onClick={register}>Salvar</Button>
    </div>
  );
}

export default Solve;
