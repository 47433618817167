import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { REQ_STATUS } from "../../../../../util/constants";
import { TitleContainer } from "../../../styles";
import { Content } from "../../styled";
import { Button, Description, FH, Input, Label } from "../../../../main-styles";
import toast from "react-hot-toast";
import { USERS } from "../../../../../api/USERS";
import { KNOWLEDGEBASE } from "../../../../../api/KNOWLEDGEBASE";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faGlobeAmericas,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/pro-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import { Spinner } from "reactstrap";
const roles = [
  { value: "agent", label: "Agente" },
  { value: "manager", label: "Gerente" },
  { value: "admin", label: "Administrador" },
];
function NovaPasta({ toggle, id, parent }) {
  const { app } = useSelector((state) => state.appReducer);

  const [state, setState] = useState({
    status:  id ? REQ_STATUS.LOADING : REQ_STATUS.IDLE,
    nome: "",
    description: "",
    visible: true,
    isPublic: false,
  });

  const { nome, status, description, visible, isPublic } = state;

  useEffect(() => {
    if (id) {
      loadFolders();
    } else {
      setState({
        ...state,
        status: REQ_STATUS.IDLE,
        nome: "",
        description: "",
        visible: true,
        isPublic: false,
      });
    }
  }, []);

  async function loadFolders() {
    try {
      const response = await KNOWLEDGEBASE.get.folder(id);
      console.log("RESPONSESESES", response);
      setState({
        ...state,
        nome: response.name,
        description: response.description,
        visible: response.visible,
        isPublic: response.public,
        status: REQ_STATUS.IDLE,
      });
    } catch (error) {
      toast.error("Erro ao carregar grupo");
    }
  }

  async function deleteFolder(){
    confirmAlert({
      title: 'Excluir pasta',
      message: 'Tem certeza que deseja excluir esta pasta?',
      buttons: [
        {
          label: 'Sim',
          onClick: doDelete
        },
        {
          label: 'Não',
          onClick: () => {}
        }
      ]
    });
   
  }

  async function doDelete(){
    if (state.status !== REQ_STATUS.IDLE) return;
    setState((s) => ({ ...state, status: REQ_STATUS.SENDING }));
    try {
      await KNOWLEDGEBASE.set.deleteFolder(id);
      toast.success("Pasta excluída com sucesso");
      setState({ ...state, status: REQ_STATUS.IDLE });
      toggle({ nova: false, folder: null, deleted: {id} });
    } catch (error) {
      console.log(error);
      toast.error("A pasta não pode ser excluída, retire todos os itens dela antes");
      setState({ ...state, status: REQ_STATUS.IDLE });
    }
  }

  async function save() {
    if (state.status !== REQ_STATUS.IDLE) return;
    if (!nome) {
      return toast.error("Nome é obrigatório");
    }
    setState((s) => ({ ...state, status: REQ_STATUS.SENDING }));
    if (id) {
      try {
        const response = await KNOWLEDGEBASE.set.updateFolder(
          id,
          nome,
          description,
          visible,
          isPublic
        );
        toast.success("Pasta atualizada com sucesso");
        setState({ ...state, status: REQ_STATUS.IDLE });
        toggle({ nova: false, folder: response });
      } catch (error) {
        console.log(error);
        toast.error("Erro ao atualizar pasta");
        setState({ ...state, status: REQ_STATUS.IDLE });
      }
    } else {
      try {
        const response = await KNOWLEDGEBASE.set.new_folder(
          parent,
          nome,
          description,
          visible,
          isPublic
        );
        toast.success("Pasta adicionada com sucesso");
        setState({
          ...state,
          nome: "",
          description: "",
          visible: false,
          isPublic: false,

          status: REQ_STATUS.IDLE,
        });
        toggle({
          nova: true,
          folder: response,
        });
      } catch (error) {
        console.log(error);

        let erro = error.response.data.error;
        switch (erro) {
          case "Folder already exists":
            toast.error("Já existe uma pasta com este nome");
            break;

          default:
            toast.error(erro);
            break;
        }
        setState({ ...state, status: REQ_STATUS.IDLE });
      }
    }
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <Spinner />
    );
  }

  return (
    <div style={{ overflow: "auto" }}>
      <TitleContainer bottom={true}>
        {id ? "Editar Pasta" : "Nova Pasta"}
      </TitleContainer>
      <Content>
        <Label>Nome</Label>
        <Input
          value={nome}
          onChange={(e) => setState({ ...state, nome: e.target.value })}
        />
        <Label>Descrição</Label>
        <Input
          value={description}
          onChange={(e) => setState({ ...state, description: e.target.value })}
        />
        <FH
          style={{ cursor: "pointer" }}
          onClick={(e) => setState((s) => ({ ...state, visible: !s.visible }))}
        >
          <FontAwesomeIcon icon={faEye} style={{}} />
          <FontAwesomeIcon icon={!visible ? faToggleOn : faToggleOff} /> Oculta
        </FH>
        {!visible && (
          <Description
            style={{ backgroundColor: "#cfcfcf", padding: 10, borderRadius: 5 }}
          >
            Pasta oculta temporariamente
          </Description>
        )}
        <FH
          style={{ cursor: "pointer" }}
          onClick={(e) =>
            setState((s) => ({ ...state, isPublic: !s.isPublic }))
          }
        >
          <FontAwesomeIcon icon={faGlobeAmericas} style={{}} />
          <FontAwesomeIcon icon={isPublic ? faToggleOn : faToggleOff} /> Pública
        </FH>
        {isPublic && (
          <Description
            style={{ backgroundColor: "#cfcfcf", padding: 10, borderRadius: 5 }}
          >
            Esta pasta ficará visível para usuários não logados.
          </Description>
        )}

        {id ? (
          <Button onClick={save}>
            {status === REQ_STATUS.SENDING ? "Salvando..." : "Salvar"}
          </Button>
        ) : (
          <Button onClick={save}>
            {status === REQ_STATUS.SENDING ? "Adicionando..." : "Adicionar"}
          </Button>
        )}
        {id && <>
        <hr/>
        <Button style={{
          backgroundColor: 'red',
          color: 'white',
          border: 'none',
          padding: '5px',
        }} onClick={deleteFolder}>Excluir pasta</Button></>}
      </Content>
    </div>
  );
}

export default NovaPasta;
