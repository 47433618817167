import React, { useEffect } from "react";
import styled from "styled-components";

function ImageSelector({
  value,
  onChange,
  containerStyle = {},
  imageStyle = {},
  background,
}) {
  const [updatedImage, setUpdatedImage] = React.useState(value);

  function selecionaImagem() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = (e) => {
      const file = e.target.files[0];
      if (!file) {
        setUpdatedImage(value);
      }
      setUpdatedImage(URL.createObjectURL(file));
      onChange(file);
    };

    // Chama o clique para abrir a janela de seleção
    input.click();
  }

  return (
    <Container
      onClick={selecionaImagem}
      style={containerStyle}
      background={background}
    >
      <Image src={updatedImage} style={imageStyle} />
    </Container>
  );
}

const Container = styled.div`
  border: 1px solid #ccc;
  background-color: ${(props) => props.background || "#fff"};
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export default ImageSelector;
