import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { FH, Text } from "../../../../main-styles";
import { Button } from "reactstrap";

function Preview({
  public_name,
  page_title,
  logo,
  darklogo,
  favicon,
  main_color,
  secondary_color,
  font_color,
  action_color,
  background_color,
  page_title_color,
  title_color,
}) {
  function getBrowserName() {
    // Opera 8.0+
    const isOpera = !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== "undefined";

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 71
    const isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Blink engine detection
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    switch (true) {
      case isOpera:
        return "Opera";
      case isFirefox:
        return "Firefox";

      case isIE:
        return "Microsoft Internet Explorer";
      case isEdge:
        return "Microsoft Edge";
      case isChrome:
        return "Chrome";
      case isBlink:
        return "Blink";
      default:
        return "Chrome";
    }
  }

  const browserName = getBrowserName();

  return (
    <Container>
      <BrowserHeader text={page_title} favicon={favicon}>
        <FH>
          <Favicon src={favicon} />
          <BrowserTitle>
            {page_title} - {browserName}
          </BrowserTitle>
        </FH>
        <FontAwesomeIcon icon={faTimes} color={"#fff"} />
      </BrowserHeader>
      <Browser style={{ backgroundColor: background_color || "#fff" }}>
        <Header
          style={{ backgroundColor: main_color, color: page_title_color }}
        >
          <Logo src={logo} />
          <Title style={{ color: page_title_color, fontSize: 20 }}>
            {page_title}
          </Title>
        </Header>
        <Body>
          <Content
            style={{
              backgroundColor: background_color,
              color: font_color,
            }}
          >
            <Logo src={darklogo} />
            <br />
            <br />
            <Title style={{ color: title_color }}>
              Seja bem-vindo ao suporte {public_name}
            </Title>
            <Text style={{ color: font_color, marginBottom: 20 }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              cursus, dui et lacinia ultricies, libero nunc tincidunt nunc, nec
              a uctor arcu erat nec erat. Nullam cursus, dui et lacinia
              ultricie.
            </Text>
            <Button
              style={{
                backgroundColor: action_color,
                borderColor: action_color,
              }}
            >
              Ação
            </Button>
          </Content>
        </Body>
      </Browser>
    </Container>
  );
}

const Container = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #d5d5d5;
  box-shadow: -10px 20px 10px rgba(0, 0, 0, 0.1);
`;

const BrowserHeader = styled.div`
  display: flex;
  align-items: center;

  padding: 5px;
  background-color: #9ac1e7;
  /* border-bottom: 1px solid #ddd; */
  color: #666;
  margin: 3px;
  border-radius: 3px;
  gap: 5px;
  line-height: 1;
  padding: 10px;
`;

const Favicon = styled.img`
  width: 16px;
  height: 16px;
`;

const BrowserTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
`;

const Browser = styled.div`
  border: 1px solid #ddd;
  margin: 3px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
`;

const Logo = styled.img`
  width: 100px;
`;

const Title = styled.div``;

const Body = styled.div`
  padding: 10px;
`;

const Content = styled.div``;

export default Preview;
