import React from "react";

import {
  faBooks,
  faBuilding,
  faCog,
  faHeadset,
  faLineChart,
  faSignOut,
  faTicket,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet, useNavigate } from "react-router-dom";
import useWebSocket from "../../components/WebSocketProvider";
import { Container, Menu, MenuItem } from "./styles";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/reducers/auth";

function Agents() {
  const { app } = useSelector((state) => state.appReducer);
  const { user } = useSelector((state) => state.authReducer);
  const { role } = user;
  const dispatch = useDispatch();
  // title
  document.title = app.title;
  const { events } = useWebSocket();
  const navigate = useNavigate();

  function goTo(path) {
    navigate(path);
  }

  return (
    <Container>
      <Menu>
        <MenuItem
          onClick={() => {
            goTo("/agents/tickets");
          }}
        >
          <FontAwesomeIcon icon={faTicket} />
        </MenuItem>
        {role !== "agent" && (
          <MenuItem
            onClick={() => {
              goTo("/agents/reports");
            }}
          >
            <FontAwesomeIcon icon={faLineChart} />
          </MenuItem>
        )}
        <div
          style={{
            width: "30px",
            height: "1px",
            backgroundColor: "#e3e3e3",
            margin: "5px 0",
          }}
        />
        <MenuItem
          onClick={() => {
            goTo("/agents/conhecimento");
          }}
        >
          <FontAwesomeIcon icon={faBooks} />
        </MenuItem>
        <div
          style={{
            width: "30px",
            height: "1px",
            backgroundColor: "#e3e3e3",
            margin: "5px 0",
          }}
        />
        <MenuItem
          onClick={() => {
            goTo("/agents/usuarios");
          }}
        >
          <FontAwesomeIcon icon={faUser} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            goTo("/agents/empresas");
          }}
        >
          <FontAwesomeIcon icon={faBuilding} />
        </MenuItem>
        <div
          style={{
            width: "30px",
            height: "1px",
            backgroundColor: "#e3e3e3",
            margin: "5px 0",
          }}
        />
        <MenuItem
          onClick={() => {
            // open link in new window
            window.open("https://support.help-desk.app", "_blank");
          }}
        >
          <FontAwesomeIcon icon={faHeadset} />
        </MenuItem>
        <div
          style={{
            width: "30px",
            height: "1px",
            backgroundColor: "#e3e3e3",
            margin: "5px 0",
          }}
        />
        <MenuItem
          onClick={() => {
            goTo("/agents/configuracoes");
          }}
        >
          <FontAwesomeIcon icon={faCog} />
        </MenuItem>
        <div
          style={{
            width: "30px",
            height: "1px",
            backgroundColor: "#e3e3e3",
            margin: "5px 0",
          }}
        />
        <MenuItem
          onClick={() => {
            dispatch(logout())
          }}
        >
          <FontAwesomeIcon icon={faSignOut} />
        </MenuItem>
      </Menu>
      <Outlet />
      {/*
        mostra aviso de que o sistema deve ser utilizado em um navegador para melhores resultados

        
      */}
      <SmallWarning>
        <strong>Atenção!</strong> Este sistema foi desenvolvido para ser
        utilizado em um navegador. Alguns recursos podem não funcionar
        corretamente em dispositivos móveis.
      </SmallWarning>
    </Container>
  );
}

const SmallWarning = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  left: 30px;
  padding: 1rem;
  background-color: #ffce3a;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  strong {
    font-weight: bold;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export default Agents;
