import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import BasicContent from "../../components/BasicContent";
import {
  faBook,
  faFolder,
  faNewspaper,
  faSpinner,
  faText,
} from "@fortawesome/pro-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { REQ_STATUS } from "../../util/constants";
import { KNOWLEDGEBASE } from "../../api/KNOWLEDGEBASE";
import styled from "styled-components";
import { Description, FH } from "../main-styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function KnowledgeBase() {
  const { id } = useParams();
  const { app } = useSelector((state) => state.appReducer);
  const { color } = app;
  const authReducer = useSelector((state) => state.authReducer);
  const { isAuthenticated } = authReducer;
  console.log("isAuthenticated", isAuthenticated);
  const navigate = useNavigate();
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    folderInfo: null,
    pastas: [],
    artigos: [],
    articleSearch: "",
  });
  const {
    status,
    pastas,
    artigos,
    articleSearch,
    showNew,
    newFolderName,
    newFolderPublic,
    newFolderVisible,
    selectedFolder,
    folderInfo,
  } = state;

  useEffect(() => {
    loadContent();
  }, [id]);

  async function loadContent() {
    setState((s) => ({ ...s, status: REQ_STATUS.LOADING }));
    const onlyPublic = !isAuthenticated;
    const promises = [
      loadFolders(onlyPublic),
      loadArticles(onlyPublic),
      loadFolderInfo(onlyPublic),
    ];

    const [folders, articles, folderInfo] = await Promise.all(promises);
    setState((s) => ({
      ...s,
      status: REQ_STATUS.IDLE,
      pastas: folders,
      artigos: articles,
      folderInfo,
    }));
  }

  async function loadFolderInfo(onlyPublic) {
    if (!id) return null;
    const folderInfo = (await onlyPublic)
      ? KNOWLEDGEBASE.get.public_folder_info(id)
      : KNOWLEDGEBASE.get.folder(id);
    return folderInfo;
  }

  async function loadArticles(onlyPublic) {
    let articles = onlyPublic
      ? KNOWLEDGEBASE.get.public_articles(id)
      : KNOWLEDGEBASE.get.articles(id);
    console.log("articles", articles);
    return articles;
  }

  async function loadFolders(onlyPublic) {
    let folders = onlyPublic
      ? KNOWLEDGEBASE.get.public_folders(id)
      : KNOWLEDGEBASE.get.folders(id, true);
    console.log("folders", folders);
    return folders;
  }

  function goBack() {
    folderInfo.parent
      ? navigate(`/folder/${folderInfo.parent}`)
      : navigate("/folder");
  }

  if (status === REQ_STATUS.LOADING) {
    return <BasicContent title="Carregando..." icon={faSpinner} />;
  }
  console.log("folderInfo", folderInfo);

  return (
    <BasicContent
      title={folderInfo ? folderInfo.name : "Base de Conhecimento"}
      subtitle={folderInfo?.description}
      icon={folderInfo ? faFolder : faBook}
      back={folderInfo ? goBack : null}
    >
      {status === REQ_STATUS.LOADING ? (
        <Spinner />
      ) : (
        <ContentHolder>
          {pastas.length === 0 && artigos.length === 0 ? (
            <Description>Não há nada aqui</Description>
          ) : (
            <>
              {pastas.length > 0 && (
                <Folders
                  pastas={pastas}
                  parent={id ? folderInfo?.parent : undefined}
                />
              )}
              {id && (
                <>
                  {pastas.length > 0 && <hr />}
                  <Articles artigos={artigos} folder={id} />
                </>
              )}
            </>
          )}
        </ContentHolder>
      )}
    </BasicContent>
  );
}

const ContentHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

function Folders({ pastas, parent }) {
  const { app } = useSelector((state) => state.appReducer);
  const { colors } = app;
  const navigate = useNavigate();
  return (
    <FoldersContainer repeat={pastas.length > 1 ? 2 : 1}>
      {pastas.map((folder) => (
        <FolderLine
          key={folder.id}
          onClick={() => navigate(`/folder/${folder.id}`)}
          borderColor={colors.main}
        >
          <FontAwesomeIcon icon={faFolder} style={{ color: colors.main }} />
          <div style={{ lineHeight: 1.3 }}>
            <FolderName color={colors.main}>{folder.name}</FolderName>
            {folder.description !== "" && (
              <Description>{folder.description}</Description>
            )}
          </div>
        </FolderLine>
      ))}
    </FoldersContainer>
  );
}

function Articles({ artigos, folder = 0 }) {
  const { app } = useSelector((state) => state.appReducer);
  const { colors } = app;
  const navigate = useNavigate();

  function gotoArticle(id) {
    navigate(`/article/${folder}/${id}`);
  }

  return (
    <ArticleContainer>
      {artigos.map((artigo) => (
        <ArticleLine
          key={artigo.id}
          onClick={() => gotoArticle(artigo.id)}
          borderColor={colors.text_color}
        >
          <FontAwesomeIcon
            icon={faNewspaper}
            style={{ color: colors.text_color }}
          />
          <div style={{ lineHeight: 1.3 }}>
            <FolderName color={colors.text_color}>{artigo.title}</FolderName>
            {artigo.description !== "" && (
              <Description>{artigo.description}</Description>
            )}
          </div>
        </ArticleLine>
      ))}
      {artigos.length === 0 && (
        <Description>Não há nenhum artigo nesta pasta</Description>
      )}
    </ArticleContainer>
  );
}

const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
`;

const ArticleLine = styled.div`
  display: flex;
  padding: 30px;
  align-items: center;
  gap: 30px;
  font-size: 30px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: ${(props) =>
    props.borderColor ? `${props.borderColor}f1` : "#f9f9f9f1"};
`;

const FoldersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.repeat}, 1fr);
  gap: 10px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FolderLine = styled.div`
  display: flex;
  padding: 30px;
  align-items: center;
  gap: 30px;
  font-size: 30px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: ${(props) =>
    props.borderColor ? `${props.borderColor}24` : "#f9f9f924"};
`;

const FolderName = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => (props.color ? props.color : "#616161")};
`;

export default KnowledgeBase;
