import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

function LineUser({ image, name, noborder = false }) {
  return (
    <User noborder={noborder}>
      <Thumbnail src={image} alt={name} />
      <div>{name}</div>
    </User>
  );
}

const User = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 12px;
  color: #333;
  ${(props) =>
    !props.noborder &&
    `
  border: 1px solid #e9e9e9;`}
  border-radius: 5px;
  padding: 2px 5px;
`;

const Thumbnail = styled.img`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
`;

export default LineUser;
