import React, { useEffect, useState } from "react";

import {
  faPlusCircle,
  faTrash,
  faTrashSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Modal, Spinner, Table } from "reactstrap";
import styled from "styled-components";
import { USERS } from "../../../../api/USERS";
import { REQ_STATUS } from "../../../../util/constants";
import { Button, Description } from "../../../main-styles";
import { TitleContainer } from "../../styles";
import { Content } from "../styled";
import NovoGrupo from "./Novo";

function GroupSettings() {
  const { user } = useSelector((state) => state.authReducer);
  const { app } = useSelector((state) => state.appReducer);

  const [state, setState] = useState({
    status: REQ_STATUS.IDLE,
    groups: [],
    newVisible: false,
    selectedGroup: null
  });

  const { status, groups, newVisible, selectedGroup } = state;

  useEffect(() => {
    if (!newVisible) {
      loadGroups();
    }
  }, [newVisible]);

  function toggle() {
    setState({ ...state, newVisible: !newVisible });
  }

  function editGroup(id){
    setState(s=>({...s, selectedGroup: id, newVisible: true}))
  }

  async function deleteGroup(id) {
    // checa se o usuario eh vc mesmo

    confirmAlert({
      title: "Deletar grupo",
      message: "Tem certeza que deseja deletar este grupo?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await USERS.set.deleteGroup(id);
              toast.success("Grupo excluído com sucesso");
              loadGroups();
            } catch (error) {
              toast.error("Erro ao deletar grupo");
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  async function loadGroups() {
    try {
      setState({ ...state, status: REQ_STATUS.LOADING });
      const response = await USERS.get.groups();
      setState({ ...state, status: REQ_STATUS.SUCCESS, groups: response });
    } catch (error) {
      setState({ ...state, status: REQ_STATUS.ERROR });
      toast.error("Erro ao carregar grupos");
    }
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <Content>
        <Spinner />
      </Content>
    );
  }

  return (
    <div style={{ overflow: "auto" }}>
      <TitleContainer bottom={true}>Grupos</TitleContainer>
      <Content>
        <Description>
          Grupos podem ser setores, cidades ou qualquer outro agrupamento de
          agentes.
        </Description>

        <UsersList>
          <Table>
            <thead>
              <tr>
                <td colSpan={4}>
                  {" "}
                  <Button style={{ padding: 5 }} onClick={toggle}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      style={{ marginRight: 5 }}
                    />
                    Adicionar novo grupo
                  </Button>
                </td>
              </tr>
              <tr>
                <th>Nome</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group) => {
                return (
                  <Group
                    data={group}
                    deleteGroup={() => deleteGroup(group.id)}
                    editGroup={() => editGroup(group.id)}
                  />
                );
              })}
            </tbody>
          </Table>
        </UsersList>
      </Content>
      <Modal isOpen={newVisible} toggle={toggle}>
        <NovoGrupo toggle={toggle} id={selectedGroup} />
      </Modal>
    </div>
  );
}

function Group({ data, deleteGroup, editGroup }) {
  return (
    <tr>
      <td onClick={editGroup} style={{cursor: 'pointer'}}>{data.name}</td>

      <td
        style={{
          textAlign: "right",
        }}
      >
        <FontAwesomeIcon
          icon={faTrash}
          style={{ marginRight: 10, color: "#ff0000", cursor: "pointer" }}
          onClick={deleteGroup}
        />
      </td>
    </tr>
  );
}

const UsersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default GroupSettings;
