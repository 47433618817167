import React, { useEffect, useState } from "react";

import {
  faPaperPlane,
  faPencil,
  faPencilSlash,
  faPlusCircle,
  faTrash,
  faTrashSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Modal, Spinner, Table } from "reactstrap";
import styled from "styled-components";
import { USERS } from "../../../../api/USERS";
import { REQ_STATUS, USER_STATUS } from "../../../../util/constants";
import { Button } from "../../../main-styles";
import { TitleContainer } from "../../styles";
import { Content } from "../styled";
import NovoAgente from "./Novo";
import { useNavigate } from "react-router-dom";
import UserStatus from "../../../../components/UserStatus";

function AgentsSettings() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authReducer);
  const { app } = useSelector((state) => state.appReducer);

  const [state, setState] = useState({
    status: REQ_STATUS.IDLE,
    agents: [],
    seats: 4,
    newVisible: false,
    selectedAgent: null,
  });

  const { status, agents, seats, newVisible, selectedAgent } = state;

  useEffect(() => {
    if (!newVisible) {
      loadAgents();
    }
  }, [newVisible]);

  function editAgent(agent) {
    if (agent === user.id) {
      navigate("/agents/configuracoes/usuario/perfil");
      return;
    }
    setState((s) => ({ ...s, selectedAgent: agent, newVisible: true }));
  }

  function toggle() {
    setState({ ...state, newVisible: !newVisible });
  }

  async function deleteUser(id) {
    // checa se o usuario eh vc mesmo

    confirmAlert({
      title: "Deletar agente",
      message: "Tem certeza que deseja deletar este agente?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              await USERS.set.delete(id);
              toast.success("Agente deletado com sucesso");
              loadAgents();
            } catch (error) {
              toast.error("Erro ao deletar agente");
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  async function loadAgents() {
    try {
      setState({ ...state, status: REQ_STATUS.LOADING });
      const response = await USERS.get.agents();
      setState({ ...state, status: REQ_STATUS.SUCCESS, agents: response });
    } catch (error) {
      setState({ ...state, status: REQ_STATUS.ERROR });
      toast.error("Erro ao carregar agentes");
    }
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <Content>
        <Spinner />
      </Content>
    );
  }

  return (
    <div style={{ overflow: "auto" }}>
      <TitleContainer bottom={true}>
        Agentes, Gerentes e Administradores
      </TitleContainer>
      <Content>
        {/* <Description>
          Nesta seção você pode configurar as permissões e configurações dos
          agentes, gerentes e administradores.
        </Description> */}
        <UsersList>
          {/* <Description>
            Você tem 1 licenças contratadas e 0 disponíveis
          </Description> */}

          <Table>
            <thead>
              <tr>
                <td colSpan={4}>
                  {" "}
                  <Button style={{ padding: 5 }} onClick={toggle}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      style={{ marginRight: 5 }}
                    />
                    Adicionar novo agente
                  </Button>
                </td>
              </tr>
              <tr>
                <th>Status</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Função</th>
                <th>Grupos</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {agents.map((agent) => {
                return (
                  <Agent
                    data={agent}
                    deleteUser={() => deleteUser(agent.id)}
                    editAgent={() => editAgent(agent.id)}
                  />
                );
              })}
            </tbody>
          </Table>
        </UsersList>
      </Content>
      {newVisible && (
        <Modal isOpen={newVisible} toggle={toggle}>
          <NovoAgente toggle={toggle} id={selectedAgent} />
        </Modal>
      )}
    </div>
  );
}

function Agent({ data, deleteUser, editAgent }) {
  const { user } = useSelector((state) => state.authReducer);
  console.log("user", data.groups?.join(", "));

  async function sendAgain() {
    // sends activation link again
    try {
      confirmAlert({
        title: "Enviar link de ativação",
        message: "Tem certeza que deseja re-enviar o link de ativação?",
        buttons: [
          {
            label: "Sim",
            onClick: async () => {
              await USERS.set.sendActivation(data.id);
              toast.success("Link de ativação enviado com sucesso");
            },
          },
          {
            label: "Não",
            onClick: () => {},
          },
        ],
      });
     
    } catch (error) {
      toast.error("Erro ao enviar link de ativação");
    }
  }

  return (
    <tr style={{ cursor: "pointer" }}>
      <td onClick={editAgent}>
        <UserStatus status={data.status} />
      </td>
      <td onClick={editAgent}>
        <img
          src={data.thumbnail}
          alt={data.name}
          style={{ width: 30, height: 30, borderRadius: 15, marginRight: 5 }}
        />
        {data.name}
      </td>
      <td onClick={editAgent}>{data.email}</td>
      <td onClick={editAgent}>{data.role}</td>
      <td onClick={editAgent}>{data.groups?.join(", ")}</td>
      <td
        style={{
          textAlign: "right",
        }}
      >
        {/* <FontAwesomeIcon
          icon={faEye}
          style={{ marginRight: 10, color: "#7e7e7e" }}
        /> */}
        {data.status === USER_STATUS.PREACTIVE && (
          <FontAwesomeIcon
            icon={faPaperPlane}
            style={{ marginRight: 10 }}
            onClick={sendAgain}
          />
        )}
        <FontAwesomeIcon
          icon={faPencil}
          style={{ marginRight: 10, color: "#007bff", cursor: "pointer" }}
          onClick={editAgent}
        />

        {user.id !== data.id ? (
          <FontAwesomeIcon
            icon={faTrash}
            style={{ marginRight: 10, color: "#ff0000", cursor: "pointer" }}
            onClick={deleteUser}
          />
        ) : (
          <FontAwesomeIcon
            icon={faTrashSlash}
            style={{ marginRight: 10, color: "#ccc" }}
            onClick={() => {
              toast.error("Você não pode deletar seu próprio usuário");
            }}
          />
        )}
      </td>
    </tr>
  );
}

const UsersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default AgentsSettings;
