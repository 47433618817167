import {
  faCheck,
  faClock,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faMailboxFlagUp,
  faNewspaper,
  faQuestion,
  faTicketPerforated,
  faTimer,
  faTimes,
  faTrafficCone,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { USER_STATUS } from "../../util/constants";

function UserStatus({ status }) {
  let color, fontColor, text;
  switch (status) {
    case USER_STATUS.PREACTIVE:
      color = "#7e7e7e";
      fontColor = "#fff";
      text = "Aguardando ativação";
      break;

    case USER_STATUS.ACTIVE:
      color = "#00ac00ff";

      fontColor = "#fff";
      text = "Ativo";
      break;

    case USER_STATUS.DELETED:
      color = "#f54323";

      fontColor = "#fff";
      text = "Excluído";
      break;

    case USER_STATUS.INACTIVE:
      color = "#f54323";

      fontColor = "#fff";
      text = "Inativo";

      break;

    case USER_STATUS.BLOCKED:
      color = "#f54323";

      fontColor = "#fff";
      text = "Bloqueado";
      break;

    default:
      color = "gray";

      fontColor = "#000";
      text = status;
      break;
  }

  return (
    <Container color={color} fontColor={fontColor}>
      {text}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.color};
  color: ${(props) => props.fontColor};
  font-size: 0.7rem;
  font-weight: 600;
  gap: 0.5rem;
  // resize font size to fit container
  white-space: nowrap;
`;

export default UserStatus;
