// components/reports/TemporalTrends.jsx
import { Chart, registerables } from "chart.js";
import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import api from "../../../../api/axios";
import { REPORTS } from "../../../../api/REPORTS";
import moment from "moment-timezone";
import ReportLayout from "../../../../components/ReportLayout";
import { Col, Container, Row, Table } from "reactstrap";
import { GraphArea } from "../styled";
Chart.register(...registerables);

export default function TemporalTrends({ tenantId }) {
  const [data, setData] = useState([]);
  const [allDates, setAllDates] = useState([]);
  const [periodo, setPeriodo] = useState({
    start: moment().subtract(7, "days").toDate(),
    end: moment().endOf("day").toDate(),
  });
  const [carregando, setCarregando] = useState(false);

  function setStartDate(date) {
    setPeriodo({ ...periodo, start: date });
  }

  function setEndDate(date) {
    setPeriodo({ ...periodo, end: date });
  }

  const fetchData = async () => {
    try {
      setCarregando(true);
      const response = await REPORTS.get.agents.temporalTrends(
        tenantId,
        periodo.start,
        periodo.end
      );
      console.log("response", response);
      const allDates = computeAllDates(response);
      setAllDates(allDates);
      setData(response);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setCarregando(false);
    }
  };

  function computeAllDates(data) {
    const endDateObj = periodo.end;
    let currentDate = periodo.start;
    let allDates = [];
    while (currentDate <= endDateObj) {
      const dateStr = currentDate.toISOString().split("T")[0];
      const found = data.find((item) => item.date === dateStr);
      if (!found) {
        allDates.push({ date: dateStr, total: 0, resolved: 0 });
      } else {
        allDates.push(found);
      }
      currentDate = moment(currentDate).add(1, "days").toDate();
    }
    return allDates;
  }

  useEffect(() => {
    fetchData();
  }, []);

  const configGrafico = {
    labels: data.map((item) => moment(item.date).format("DD/MM/YYYY")),
    datasets: [
      {
        label: "Tickets Abertos",
        data: data.map((item) => item.total),
        backgroundColor: "#5459f3",
        tension: 0.1,
      },
      {
        label: "Tickets Resolvidos",
        data: data.map((item) => item.resolved),
        backgroundColor: "#4CAF50",
        tension: 0.1,
      },
    ],
  };
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Permite definir uma altura personalizada
    // increments 1
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <ReportLayout
      title="Relatório de Tickets"
      startDate={periodo.start}
      setStartDate={setStartDate}
      endDate={periodo.end}
      setEndDate={setEndDate}
      fetchData={fetchData}
    >
      <Container style={{ overflow: "auto" }}>
        <Row>
          <Col md={12} style={{ paddingTop: 50 }}>
            <GraphArea>
              
              <Bar
                data={configGrafico}
                options={chartOptions}
                style={{ maxHeight: "400px" }} // Limita a altura do gráfico
              />
            </GraphArea>
            <hr />
            <h2>Detalhes</h2>

            <Table striped>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Abertos</th>
                  <th>Resolvidos</th>
                  <th>Taxa Resolução</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.date}>
                    <td>{new Date(item.date).toLocaleDateString()}</td>
                    <td>{item.total}</td>
                    <td>{item.resolved}</td>
                    <td>
                      {((item.resolved / item.total) * 100 || 0).toFixed(1)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </ReportLayout>
  );
}
