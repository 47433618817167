import React, { useEffect, useState } from "react";

import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { USERS } from "../../../../api/USERS";
import WYSEditor from "../../../../components/WYSEditor";
import { updateUser } from "../../../../redux/reducers/auth";
import { REQ_STATUS } from "../../../../util/constants";
import { uploadImage } from "../../../../util/functions";
import {
  Button,
  Description,
  Input,
  Label,
  Text,
  TwoColumns,
} from "../../../main-styles";
import { TitleContainer } from "../../styles";
import { Content } from "../styled";
import Preview from "./Preview";
import ColorPicker from "../../../../components/ColorPicker";
import styled from "styled-components";
import ImageSelector from "../../../../components/ImageSelector";
import { APP } from "../../../../api/APP";
import { updateApp } from "../../../../redux/reducers/app";

function Layout() {
  const dispatch = useDispatch();
  const { app } = useSelector((state) => state.appReducer);
  console.log("app", app);

  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    public_name: app.name,
    page_title: app.title,
    logo: app.logo,
    darklogo: app.darklogo,
    favicon: app.favicon,
    main_color: app.colors.main,
    secondary_color: app.colors.secondary,
    font_color: app.colors.text,
    action_color: app.colors.accent,
    background_color: app.background_color || "#fff",
    page_title_color: app.colors.headerText,
    newLogoFile: null,
    newDarkLogoFile: null,
    newFaviconFile: null,

    preview: {},
  });

  const {
    status,
    public_name,
    page_title,
    logo,
    darklogo,
    favicon,
    main_color,
    secondary_color,
    font_color,
    action_color,
    background_color,
    page_title_color,
    newLogoFile,
    newDarkLogoFile,
    newFaviconFile,
  } = state;

  async function save() {
    if (
      !public_name ||
      !page_title ||
      !logo ||
      !main_color ||
      !secondary_color ||
      !font_color ||
      !action_color ||
      !background_color ||
      !page_title_color 
    ) {
      console.log("missing fields", {
        public_name,
        page_title,
        logo,
        main_color,
        secondary_color,
        font_color,
        action_color,
        background_color,
        page_title_color
      });
      return toast.error("Preencha todos os campos");
    }

    let logoUrl = logo;
    let darklogoUrl = darklogo;
    let faviconUrl = favicon;

    if (newLogoFile) {
      logoUrl = await uploadImage(newLogoFile);
    }
    if (newDarkLogoFile) {
      darklogoUrl = await uploadImage(newDarkLogoFile);
    }
    if (newFaviconFile) {
      faviconUrl = await uploadImage(newFaviconFile);
    }

    const payload = {
      name: public_name,
      title: page_title,
      logo: logoUrl,
      darklogo: darklogoUrl,
      favicon: faviconUrl,
      colors: {
        main: main_color,
        secondary: secondary_color,
        text: font_color,
        accent: action_color,
        headerText: page_title_color,
      },
      background_color,
    };

    try {
      const data = await APP.set.updateLayout(app.tid, payload);
      console.log("updated app:", data);
      dispatch(updateApp(payload));
      toast.success("Configurações atualizadas com sucesso");
    } catch (e) {
      console.log("error updating app", e);
      toast.error("Erro ao atualizar configurações");
    }
  }

  useEffect(() => {
    load();
  }, []);

  async function load() {
    const response = await APP.get.public_workspace(app.workspace);

    let info = response.data;

    console.log("App info:", info);
    document.title = info.page_title;

    let payload = {
      status: REQ_STATUS.IDLE,
      page_title: info.page_title,
      public_name: info.public_name,
      logo: info.logo,
      darklogo: info.darklogo,
      favicon: info.favicon,

      main_color: info.main_color,
      secondary_color: info.secondary_color,
      action_color: info.action_color,
      font_color: info.font_color,
      page_title_color: info.page_title_color,
      background_color: info.background_color || "#fff",
    };

    setState({
      status: REQ_STATUS.SUCCESS,
      ...payload,
    });
  }

  function updateField(field, value) {
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <Content>
        <Spinner />
      </Content>
    );
  }

  return (
    <div style={{ overflow: "auto" }}>
      <TitleContainer bottom={true}>
        Configurações de Layout e Aparência
      </TitleContainer>
      <TwoColumns style={{ flex: 1, alignItems: "flex-start" }}>
        <Content>
          <h4>Informações</h4>
          <Label>Nome público*</Label>
          <Description>
            Quando for necessário utilizar o nome de sua empresa. Exemplo: Nome
            fantasia
          </Description>
          <Input
            value={public_name}
            onChange={(e) => updateField("public_name", e.target.value)}
          />
          <Label>Título da página*</Label>
          <Input
            value={page_title}
            onChange={(e) => updateField("page_title", e.target.value)}
          />

          <h4>Imagens</h4>
          <Label>Favicon</Label>
          <ImageSelector
            value={favicon}
            onChange={(e) => setState((s) => ({ ...s, newFaviconFile: e }))}
            imageStyle={{ width: 32, height: 32, objectFit: "fill" }}
            containerStyle={{ width: 40, height: 40 }}
          />

          <Label>Logo*</Label>

          <ImageSelector
            value={logo}
            background={main_color}
            containerStyle={{ width: 200, height: 100 }}
            onChange={(e) => setState((s) => ({ ...s, newLogoFile: e }))}
          />
          <Label>Logo para fundo escuro</Label>
          <ImageSelector
            value={darklogo}
            onChange={(e) => setState((s) => ({ ...s, newDarkLogoFile: e }))}
            containerStyle={{ width: 200, height: 100 }}
          />
          <TwoColumns
            style={{
              borderTop: "1px solid #ccc",
              margin: "20px 0",
              padding: "20px 0",
            }}
          >
            <Content>
              <h4>Cabeçalho</h4>
              <Label>Cor de fundo*</Label>
              <ColorPicker
                color={main_color}
                onChange={(color) => updateField("main_color", color)}
              />
              <Label>Cor do título*</Label>
              <ColorPicker
                color={page_title_color}
                onChange={(color) => updateField("page_title_color", color)}
              />
            </Content>
            <Content>
              <h4>Conteúdo</h4>
              <Label>Cor de fundo*</Label>
              <ColorPicker
                color={background_color}
                onChange={(color) => updateField("background_color", color)}
              />
              <Label>Cor do texto*</Label>
              <ColorPicker
                color={font_color}
                onChange={(color) => updateField("font_color", color)}
              />
             
              <Label>Cor de ação*</Label>
              <ColorPicker
                color={action_color}
                onChange={(color) => updateField("action_color", color)}
              />
            </Content>
          </TwoColumns>
        </Content>
        <Content>
          <Sticker>
            <h4>Pré-visualização</h4>
            <Preview
              public_name={public_name}
              page_title={page_title}
              logo={newLogoFile ? URL.createObjectURL(newLogoFile) : logo}
              darklogo={
                newDarkLogoFile
                  ? URL.createObjectURL(newDarkLogoFile)
                  : darklogo
              }
              favicon={
                newFaviconFile ? URL.createObjectURL(newFaviconFile) : favicon
              }
              main_color={main_color}
              secondary_color={secondary_color}
              font_color={font_color}
              action_color={action_color}
              background_color={background_color}
              page_title_color={page_title_color}
              title_color={main_color}
            />
            <Text style={{ fontSize: 10, marginTop: 30 }}>
              A pré-visualização é uma simulação e pode não refletir exatamente
              o resultado final devido a diferenças de renderização entre
              navegadores e dispositivos.
              <br />
              <br />
              *: Campos obrigatórios
            </Text>
          </Sticker>
        </Content>
        <Content>
          <Button onClick={save}>Salvar</Button>
        </Content>
      </TwoColumns>
    </div>
  );
}

const Sticker = styled.div`
  position: sticky;
  top: 20px;
`;

export default Layout;
