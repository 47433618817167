import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { REQ_STATUS } from "../../../../../util/constants";
import { TitleContainer } from "../../../styles";
import { Content } from "../../styled";
import { Button, Description, FH, Input, Label } from "../../../../main-styles";
import toast from "react-hot-toast";
import { USERS } from "../../../../../api/USERS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/pro-solid-svg-icons";
import { Spinner } from "reactstrap";
const roles = [
  { value: "agent", label: "Agente" },
  { value: "manager", label: "Gerente" },
  { value: "admin", label: "Administrador" },
];
function NovoAgente({ toggle, id }) {
  const { app } = useSelector((state) => state.appReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [changePassword, setChangePassword] = useState(false);
  const [state, setState] = useState({
    status: id ? REQ_STATUS.LOADING : REQ_STATUS.IDLE,
    nome: "",
    email: "",
    role: "agent",
    password: "",
  });

  const { status, nome, email, role, password } = state;

  useEffect(() => {
    if (id) {
      loadAgent();
    }
  }, [id]);

  async function loadAgent() {
    try {
      console.log("id", id);
      const response = await USERS.get.one(id);
      const { name, email, role } = response;
      setState((s) => ({
        ...s,
        nome: name,
        email,
        role,
        status: REQ_STATUS.IDLE,
      }));
    } catch (error) {
      toast.error("Erro ao carregar agente");
    }
  }

  async function save() {
    if (status !== REQ_STATUS.IDLE) return;
    if (!nome) {
      return toast.error("Nome é obrigatório");
    }
    if (!email) {
      console.log(email);
      return toast.error("Email é obrigatório");
    }
    if (!email.includes("@")) {
      return toast.error("Email inválido");
    }
    if (!email.includes(".")) {
      return toast.error("Email inválido");
    }
    if (id) {
      if (changePassword && password.length < 6) {
        return toast.error("A senha deve ter no mínimo 6 caracteres");
      }
      try {
        await USERS.set.updateAgent(
          id,
          nome,
          email,
          role,
          changePassword ? password : undefined
        );
        toast.success("Agente atualizado com sucesso");
        toggle();
      } catch (error) {
        toast.error("Erro ao atualizar agente");
      }
    } else {
      try {
        const response = await USERS.set.newAgent(nome, email, role);
        toast.success("Agente adicionado com sucesso");
        setState({ ...state, nome: "", email: "" });
        toggle();
      } catch (error) {
        console.log(error);

        let erro = error.response.data.error;
        switch (erro) {
          case "User already exists":
            toast.error("Já existe um usuário com este email");
            break;

          default:
            toast.error(erro);
            break;
        }
      }
    }
    setState({ ...state, status: REQ_STATUS.IDLE });
  }

  const selected = useMemo(() => {
    let selecionado = roles.find((r) => r.value === role);
    console.log("selecionado", selecionado);
    return selecionado;
  }, [role]);

  if (status === REQ_STATUS.LOADING) {
    return <Spinner />;
  }

  return (
    <div style={{ overflow: "auto" }}>
      <TitleContainer bottom={true}>
        {id ? "Editar Agente" : "Novo Agente"}
      </TitleContainer>
      <Content>
        <Label>Nome completo</Label>
        <Input
          value={nome}
          onChange={(e) => setState({ ...state, nome: e.target.value })}
        />
        <Label>Email</Label>
        <Input
          value={email}
          onChange={(e) =>
            setState({ ...state, email: e.target.value?.toLowerCase() })
          }
        />
        <Label>Senha</Label>
        {id ? (
          <>
            <FH>
              <FontAwesomeIcon
                icon={changePassword ? faToggleOn : faToggleOff}
                onClick={() => setChangePassword(!changePassword)}
                style={{ cursor: "pointer" }}
              />
              Alterar senha
            </FH>
            {changePassword && (
              <Input
                value={password}
                onChange={(e) =>
                  setState({ ...state, password: e.target.value })
                }
              />
            )}
          </>
        ) : (
          <Description
            style={{
              backgroundColor: "#ededed",
              borderRadius: 5,
              padding: 10,
            }}
          >
            Uma senha será gerada automaticamente
          </Description>
        )}
        <Label>Função</Label>
        <Select
          value={selected}
          options={roles}
          // position top
          menuPlacement="top"
          onChange={(e) => setState({ ...state, role: e.value })}
        />
        <RoleDescription role={role} />
        {id ? (
          <Button onClick={save}>
            {status !== REQ_STATUS.IDLE ? "Salvando..." : "Salvar"}
          </Button>
        ) : (
          <Button onClick={save}>
            {status !== REQ_STATUS.IDLE ? "Adicionando..." : "Adicionar"}
          </Button>
        )}
      </Content>
    </div>
  );
}

function RoleDescription({ role }) {
  switch (role) {
    case "agent":
      return (
        <Description
          style={{
            backgroundColor: "#ededed",
            borderRadius: 5,
            padding: 10,
          }}
        >
          <strong>Agente</strong>
          <br />
          Atende chamados e pode editar a base de conhecimento
        </Description>
      );

      break;
    case "manager":
      return (
        <Description
          style={{
            backgroundColor: "#ededed",
            borderRadius: 5,
            padding: 10,
          }}
        >
          <strong>Gerente</strong>
          <br />
          Atende chamados, pode editar a base de conhecimento e pode acessar
          relatórios
        </Description>
      );

      break;

    case "admin":
      return (
        <Description
          style={{
            backgroundColor: "#ededed",
            borderRadius: 5,
            padding: 10,
          }}
        >
          <strong>Admin</strong>
          <br />
          Acesso total ao sistema
        </Description>
      );

      break;

    default:
      break;
  }
}

export default NovoAgente;
