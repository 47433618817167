import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment-timezone";
import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import OneSignal from "react-onesignal";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import styled from "styled-components";
import "./App.css";
import { persistor, store } from "./redux/configureStore";
import Routes from "./routes";

moment.tz.setDefault("America/Sao_Paulo");
moment.locale("pt-br");

function App() {
  const [initialized, setInitialized] = useState(false);
  const [hasPermissions, setHasPermissions] = useState(false);



  useEffect(() => {
    runOneSignal();
  }, []);

  async function runOneSignal() {
    // if onesignal is initialized, return
    try {
      await OneSignal.init({
        appId: "3adf0b2f-c9e0-42b2-a081-d0d090126773",
        safari_web_id:
          "web.onesignal.auto.5130fec1-dc87-4e71-b719-29a6a70279c4",
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true,
      }).then(() => {
        setInitialized(true);
      });
      // if we have permsisions, hide the button
      await OneSignal.getNotificationPermission().then((response) => {
        if (response === "granted") {
          setHasPermissions(true);
        }
      });
    } catch (error) {}
  }

  return (
    <React.StrictMode>
      <Provider store={store}><div
          class="onesignal-customlink-container"
         
        ></div>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}
const NotificationContainer = styled.div``;

export default App;
