import React from "react";
import * as S from "../styles";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBrainCircuit,
  faChartLine,
  faMicrochipAi,
  faTicket,
} from "@fortawesome/pro-solid-svg-icons";
import COLORS from "../../../util/COLORS";


function WhatWeDo() {
  const { t } = useTranslation();
  return (
    <>
      <S.IconsContainer>
        <Icon
          title="Portal de Atendimento"
          description="Ofereça um portal de atendimento ao cliente com a sua marca e personalize-o de acordo com as suas necessidades."
          icon={faTicket}
        />
        <Icon
          title="Relatórios"
          description="Acompanhe o desempenho da sua equipe de suporte e tome decisões baseadas em dados."
          icon={faChartLine}
        />
        <Icon
          title="Base de Conhecimento"
          description="Crie uma base de conhecimento para os seus clientes e reduza o número de chamados."
          icon={faBrainCircuit}
        />
        <Icon
          title="Inteligência Artificial"
          description="Utilize a inteligência artificial para garantir que seus clientes recebam respostas rápidas e precisas."
          icon={faMicrochipAi}
        />
      </S.IconsContainer>
      <S.WhatWeDoContainer>
        <h1>{t('home.hero.o-que-fazemos')}</h1>

        <p>
          {t('home.hero.somos-um-helpdesk-de-baixo-custo-para-empresas-que-desejam-fornecer-um-excelente-suporte-ao-cliente-sem-gastar-uma-fortuna-em-um-sistema-de-suporte')}
        </p>
      </S.WhatWeDoContainer>
      {/* <S.HeroContainer>
        <S.HeroLeft
          className="animate__animated animate__fadeInLeft"
          style={{
            // use only left column of the 3 column grid
            gridArea: "1 / 1 / 2 / 2",
          }}
        >
          <S.HeroImage
            className="animate__animated animate__fadeInLeft"
            src="https://img.seidecor.com.br/fit-in/500x500/-LIG_iJcJTGJZwPKMJiZ/null/iaintegrada.png"
          />
        </S.HeroLeft>
        <S.HeroRight
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gridArea: "1 / 2 / 2 / 4",
          }}
        >
          <S.HeroTitle>{t('home.hero.integracao-com-chatgpt')}</S.HeroTitle>
          <S.HeroSubtitle>
            {t('home.hero.utilize-a-inteligencia-artificial-para-garantir-que-seus-clientes-recebam-respostas-rapidas-e-precisas')}
          </S.HeroSubtitle>
        </S.HeroRight>
      </S.HeroContainer> */}
      {/* <hr /> */}
    </>
  );
}

function Icon({ icon, title, description }) {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
    }}>
      <FontAwesomeIcon icon={icon} size="5x" style={{color: COLORS.accent}} />
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
}

export default WhatWeDo;
