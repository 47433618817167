import React from "react";
import Select from "react-select";
import styled from "styled-components";

function AgentSelect({
  value,
  options = [],
  onChange,
  placeholder = "Selecione",
  isDisabled = false,
}) {
//   console.log("AGENTS", options);
  const selected = options.find((option) => parseInt(option.value) === value);
  console.log("SELECTED", selected);
  
  return (
    <Select
      // selector width 400px
      styles={{
        control: (styles) => ({ ...styles, minWwidth: 300 }),
      }}
      defaultValue={selected}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      isDisabled={isDisabled}
      noOptionsMessage={() => "Nenhum agente encontrado"}
      formatOptionLabel={(agent) => (
        <AgentOptionLabel>
          <AgentImage src={agent.image} alt={agent.name} />
          <div>
            <AgentName>{agent.label}</AgentName>
            <AgentEmail>{agent.email}</AgentEmail>
          </div>
        </AgentOptionLabel>
      )}
    />
  );
}

const AgentOptionLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
`;
const AgentImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;
const AgentName = styled.div``;
const AgentEmail = styled.div`
  font-size: 10px;
  color: #999;
  font-weight: 300;
`;

export default AgentSelect;
