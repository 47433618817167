import React, { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import styled from "styled-components";

export default function ColorPicker({ color, onChange, options = [] }) {
  const [open, setOpen] = useState(false);
  
  return (
    <>
      <ColorSelector>
        <OpenButton onClick={() => setOpen(!open)}>
          <ColorPreview color={color} />
          <ColorPreviewText>{color}</ColorPreviewText>
        </OpenButton>
        {open && (
          <Floater>
            <HexColorPicker color={color} onChange={onChange} />
            <TextInput
              value={color}
              onChange={(e) => onChange(e.target.value)}
            />
            <CloseButton onClick={() => setOpen(false)}>Fechar</CloseButton>
          </Floater>
        )}
      </ColorSelector>
    </>
  );
}

const ColorSelector = styled.div`
  position: relative;
  // 
  width: 100px;
`;

const OpenButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const CloseButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  padding: 5px;
  background-color: #ddd;
  border-radius: 5px;
`;

const TextInput = styled.input`
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const ColorPreview = styled.div`
  background-color: ${(props) => props.color};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ddd;
`;

const ColorPreviewText = styled.div`
  font-size: 0.8rem;
  font-family: monospace;
`;

const Floater = styled.div`
  position: absolute;
  z-index: 2;
  top: 30;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  gap: 5px;
  display: flex;
  flex-direction: column;
`;
