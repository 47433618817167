import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  height: 100vh;
  gap: 1rem;
`;

const Sidebar = styled.div`
  grid-column: span 1;

  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #eeedee;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ReportArea = styled.div`
  grid-column: span 7;
  display: grid;
  gap: 1rem;
  background-color: #ffffff;
  border-radius: 8px;

  border: 1px solid #eeedee;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
`;

const GraphArea = styled.div`
  padding: 4rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
`;

const FilterArea = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
  align-items: center;
  & > * {
    margin: 0;
  }
`;

const ReportSlot = styled.div`
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ReportLink = styled.div`
  cursor: pointer;
  color: #1d1d1d;
  text-decoration: none;
  font-size: 13px;
  display: flex;
  gap: 5px;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
`;

const Output = styled.div`
  grid-column: span 7;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 1rem;
  margin-top: 0;
`;

export {
  Wrapper,
  Sidebar,
  ReportArea,
  ReportSlot,
  ReportLink,
  Output,
  Links,
  FilterArea,
  GraphArea,
};
