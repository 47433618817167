import React, { useEffect, useState } from "react";
import {
  Button,
  Description,
  Input,
  Label,
  Title,
} from "../../../../main-styles";
import WYSEditor from "../../../../../components/WYSEditor";
import { REQ_STATUS } from "../../../../../util/constants";
import { Bordered } from "../../styled";
import toast from "react-hot-toast";
import { KNOWLEDGEBASE } from "../../../../../api/KNOWLEDGEBASE";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";

import TagsInput from "react-tagsinput";

function EditorDeArtigos() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    status: id ? REQ_STATUS.LOADING : REQ_STATUS.IDLE,
    titulo: "",
    tags: [],
    availableTags: [],
    conteudo: "",
  });
  const { status, titulo, tags, conteudo } = state;

  useEffect(() => {
    init();
  }, []);

  async function init() {
    const promises = [getTags()];
    if (id) {
      promises.push(loadArticle());
    }
    await Promise.all(promises);
    setState((s) => ({ ...s, status: REQ_STATUS.IDLE }));
  }

  async function getTags() {
    let tags = await KNOWLEDGEBASE.get.tags();
    if (tags) {
      setState((s) => ({ ...s, availableTags: tags }));
    }
  }

  async function loadArticle() {
    setState((s) => ({ ...s, status: REQ_STATUS.LOADING }));
    let article = await KNOWLEDGEBASE.get.article(id);
    setState((s) => ({
      ...s,
      status: REQ_STATUS.IDLE,
      titulo: article.title,
      tags: article.tags,
      conteudo: article.content,
    }));
  }

  async function save() {
    if (!titulo || !conteudo) {
      return toast.error("Preencha todos os campos");
    }
    if (id) {
      //update
      let response = await KNOWLEDGEBASE.set.update_article(
        id,
        titulo,
        conteudo,
        tags
      );
      if (response) {
        toast.success("Artigo atualizado com sucesso");
        navigate("/agents/conhecimento/artigos");
      }
    } else {
      //create
      let response = await KNOWLEDGEBASE.set.newArticle(titulo, conteudo, tags);
      if (response) {
        toast.success("Artigo criado com sucesso");
        navigate("/agents/conhecimento/artigos");
      }
    }
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <Bordered>
        <Spinner style={{ margin: 20 }} />
      </Bordered>
    );
  }

  return (
    <Bordered style={{ padding: 30, height: "100%" }}>
      <Title>{id ? "Editar artigo" : "Novo artigo"}</Title>
      <Label>Título</Label>
      <Input
        value={titulo}
        onChange={(e) => setState((s) => ({ ...s, titulo: e.target.value }))}
      />
      <Label>
        Tags
        <br />
        <Description style={{ fontSize: 10 }}>
          (Aperte TAB ou ENTER para adicionar)
        </Description>
      </Label>

      <TagsInput
        value={tags}
        onChange={(value) =>
          setState((s) => ({ ...s, tags: value.map((v) => v.toUpperCase()) }))
        }
        onlyUnique
        inputProps={{ placeholder: "Adicionar tag" }}
      />
      <WYSEditor
        value={conteudo}
        onChange={(value) => setState((s) => ({ ...s, conteudo: value }))}
        style={{ flex: 1, flexGrow: 3 }}
      />
      <Button onClick={save}>Salvar</Button>
    </Bordered>
  );
}

export default EditorDeArtigos;
