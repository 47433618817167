export default {
    translation: {
      home: {
        header: {
          faq: "FAQ",
          "start-free": "Start for free",
          pricing: "Pricing",
        },
        hero: {
          "nao-e-necessario-cartao-de-credito":
            "No credit card required",
          "resolva-chamados-com-rapidez-e-eficiencia":
            "Resolve tickets quickly and efficiently",
          "organize-seu-suporte-melhorando-e-acompanhando-cada-atendimento":
            "Organize your support by improving and tracking each service",
          "comece-agora-gratuitamente": "Start now for free",
          "o-que-fazemos": "What we do",
          "somos-um-helpdesk-de-baixo-custo-para-empresas-que-desejam-fornecer-um-excelente-suporte-ao-cliente-sem-gastar-uma-fortuna-em-um-sistema-de-suporte":
            "We are a low-cost helpdesk for companies that want to provide excellent customer support without spending a fortune on a support system",
          "integracao-com-chatgpt": "Integration with ChatGPT",
          "utilize-a-inteligencia-artificial-para-garantir-que-seus-clientes-recebam-respostas-rapidas-e-precisas":
            "Use artificial intelligence to ensure your customers receive fast and accurate responses",
        },
        faq: {
          faq: "FAQ",
          questions: {
            "o-que-e-um-sistema-de-help-desk": "What is a help desk system?",
            "como-funciona-a-precificacao": "How does pricing work?",
            "o-que-e-um-agente": "What is an agent?",
            "e-se-minha-equipe-precisar-de-mais-agentes-no-futuro":
              "What if my team needs more agents in the future?",
            "posso-testar-o-help-desk-app-antes-de-comprar":
              "Can I test Help-Desk.app before purchasing?",
            "e-possivel-personalizar-o-help-desk-app-com-a-identidade-visual-da-minha-empresa":
              "Is it possible to customize Help-Desk.app with my company's branding?",
            "existe-algum-tipo-de-relatorio-ou-painel-de-analise-disponivel":
              "Is there any type of report or analytics dashboard available?",
            "o-help-desk-app-oferece-alguma-ferramenta-de-base-de-conhecimento-ou-faq":
              "Does Help-Desk.app offer any knowledge base or FAQ tool?",
            "voces-tem-integracao-com-inteligencia-artificial":
              "Do you have integration with artificial intelligence?",
            "outros-sistemas-cobram-por-ticket-voces-tambem":
              "Other systems charge per ticket, do you?",
            "voces-cobram-por-pesquisa-de-satisfacao":
              "Do you charge for satisfaction surveys?",
            "e-possivel-criar-um-ticket-automaticamente-quando-receber-um-email":
              "Is it possible to create a ticket automatically when receiving an email?",
          },
          answers: {
            "um-sistema-de-help-desk-e-uma-ferramenta":
              "A help desk system is a tool that allows companies to manage and respond to customer support requests.",
            "como-funciona-a-precificacao":
              "You will pay a fixed monthly fee based on the number of agents in your support team.",
            "o-que-e-um-agente":
              "An agent is a member of your support team who can respond to customer support requests.",
            "e-se-minha-equipe-precisar-de-mais-agentes-no-futuro":
              "You can add or remove agents as needed. Plans are flexible, and billing will be adjusted according to the number of active agents per month.",
            "posso-testar-o-help-desk-app-antes-de-comprar":
              "Yes, you can test Help-Desk.app for free for 14 days.",
            "e-possivel-personalizar-o-help-desk-app-com-a-identidade-visual-da-minha-empresa":
              "Yes! You can customize colors, insert your company logo, and even create a support portal that matches your brand.",
            "existe-algum-tipo-de-relatorio-ou-painel-de-analise-disponivel":
              "Yes, Help-Desk.app offers productivity reports, response time tracking, customer satisfaction metrics, and other key indicators for support management.",
            "o-help-desk-app-oferece-alguma-ferramenta-de-base-de-conhecimento-ou-faq":
              "Yes. There is an integrated knowledge base feature that allows you to create articles and tutorials, so your customers can find solutions quickly and independently.",
            "voces-tem-integracao-com-inteligencia-artificial":
              "Yes, Help-Desk.app integrates with ChatGPT, and we are always exploring new technologies to improve the user experience.",
            "outros-sistemas-cobram-por-ticket-voces-tambem":
              "No, Help-Desk.app only charges per agent, not per ticket.",
            "voces-cobram-por-pesquisa-de-satisfacao":
              "No, satisfaction surveys are free for all plans.",
            "e-possivel-criar-um-ticket-automaticamente-quando-receber-um-email":
              "Yes, you can configure Help-Desk.app to create a ticket automatically when an email is received at a specific address.",
          },
        },
        cta: {
          "experimente-sem-compromisso-e-veja-como-":
            "Try it risk-free and see how we can transform customer support in your company.",
          "comece-sua-avaliacao-gratuita-de-14-dias":
            "Start your 14-day free trial",
        },
        pricing: {
          precificacao: "Pricing",
          gratuito: "Free",
          price: "Free",
          "ate-4-agentes": "Up to 4 agents",
          escolher: "Choose",
          "pro-plan": "Pro Plan",
          "mais-escolhido": "Best choice",
          "r12-por-agente": "$12 per agent",
          "por-mes": "per month",
          "enterprise-plan": "Enterprise Plan",
          customizado: "Custom",
          "muitos-agentes": "Many agents?",
          "solicitar-contato": "Request contact",
          "recursos": "Features",
          "agentes": "Agents",
          "assistente-de-respostas-por-ia": "AI response assistant",
          "resumo-de-chamados-por-ia": "AI ticket summary",
          "pesquisa-de-satisfacao-csat": "Customer satisfaction survey (CSAT)",
          "tickets-ilimitados": "Unlimited tickets",
          "contatos-ilimitados": "Unlimited contacts",
          "empresas-ilimitadas": "Unlimited companies",
          "grupos-ilimitados": "Unlimited groups",
          "abertura-de-chamado-por-email": "Ticket creation via email",
          "painel-de-suporte-para-abertura-de-chama": "Client Support Panel",
          "base-de-conhecimento": "Knowledge base",
          "relatorios-de-chamados": "Support reports",
          "ate-4": "Up to 4",
          "ilimitados": "Unlimited",
        },
        footer: {
          companyname: "Help-Desk.app - Customer Support Ticketing System",
          copyright: "2025 HDAPP Systems Ltda.",
          cnpj: "CNPJ: 58.420.116/0001-60",
          address: "R XV DE NOVEMBRO, 556 - CURITIBA - PR - BRAZIL",
          email: "contact@help-desk.app",
        },
      },
      "new-workspace": {
        "sua-empresa": "your_company",
      "help-desk-app-novo-workspace": "Help-Desk.app - New workspace",
      "enviamos-um-email-para": "We sent an email to {{email}}",
      "novo-workspace": "New workspace",
      "vamos-configurar-o-workspace-de-sua-empr":
        "Let's set up your company's workspace. Choose a name for it:",
        "ja-existe-um-workspace-com-esse-nome": "A workspace with this name already exists",
        "email": "Email",
        "senha": "Password",
        "confirmacao-de-senha": "Confirm password",
        "concordo-com-os": "I agree with the",
        "termos-de-uso": "terms of use",
        "and": "and",
        "politica-de-privacidade": "privacy policy",
        "create-workspace": "Create workspace",
        "ja-tem-uma-conta": "Already have an account?",
        "clique-aqui": "click here",
        "o-nome-do-workspace-nao-e-valido": "The workspace name is not valid",
        "voce-precisa-concordar-com-os-termos-de-uso-e-politica-de-privacidade": "You need to agree with the terms of use and privacy policy",
        "a-senha-deve-ter-no-minimo-6-caracteres": "The password must have at least 6 characters",
        "as-senhas-nao-conferem": "Passwords do not match",
        "email-invalido": "Invalid email",
        "o-nome-do-workspace-deve-ter-no-minimo-3-caracteres": "The workspace name must have at least 3 characters",
        "verifique-seu-email": "Check your email",
        "enviamos-um-email-para": "We sent an email to {{email}}"
    }
    },
  };
  