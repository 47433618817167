import React, { useContext, useEffect, useState } from "react";

import * as S from "../../styled";
import { REQ_STATUS } from "../../../../../util/constants";
import { FoldersContext } from "..";
import { KNOWLEDGEBASE } from "../../../../../api/KNOWLEDGEBASE";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faArrowRight,
  faEye,
  faEyeSlash,
  faFolder,
  faGlobeAmericas,
  faGripDotsVertical,
  faLock,
  faMinusCircle,
  faPage,
  faPencil,
} from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";
import { Spinner, Table } from "reactstrap";
import { FH } from "../../../../main-styles";
import { useNavigate } from "react-router-dom";

function FolderNavigator({ id }) {
  const navigate = useNavigate();
  const context = useContext(FoldersContext);
  const { state, updateField } = context;
  const { pastas, artigos, status, folderInfo } = state;

  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    loadContent();
  }, [id]);

  async function loadFolderInfo() {
    if (!id) return null;
    let info = await KNOWLEDGEBASE.get.folder(id);
    console.log("info", info);
    return info;
  }

  async function loadContent() {
    setLoading(true);
    let promises = [loadFolders(), loadArticles(), loadFolderInfo()];
    const [folders, articles, folderInfo] = await Promise.all(promises);
    updateField("pastas", folders);
    updateField("artigos", articles);
    updateField("folderInfo", folderInfo);
    setLoading(false);
  }

  function gotoParent() {
    if (!folderInfo?.parent) {
      return navigate(`/agents/conhecimento/pastas`);
    }
    return navigate(`/agents/conhecimento/pastas/${folderInfo?.parent}`);
  }

  async function loadFolders() {
    let newFolders = await KNOWLEDGEBASE.get.folders(id, false, false);
    console.log("newFolders", newFolders);
    return newFolders;
  }
  async function loadArticles() {
    if (!id) return [];
    // let newArticles = await KNOWLEDGEBASE.get.articles(id);
    let newArticles = await KNOWLEDGEBASE.get.articles(id, false, "");
    console.log("newArticles", newArticles);
    return newArticles;
  }

  if (loading) {
    return <Spinner style={{ margin: 20 }} />;
  }

  return (
    <LinesContainer>
      {folderInfo?.id && (
        <FolderLine onClick={gotoParent}>
          <FH style={{ gap: 10 }}>
            <FontAwesomeIcon icon={faFolder} />
            <FolderName>..</FolderName>
          </FH>
        </FolderLine>
      )}
      {pastas.map((item) => {
        return (
          <Linha
            key={item.id}
            id={item.id}
            name={item.name}
            type={"folder"}
            count={item.children_articles}
            isPublic={item.public}
            visible={item.visible}
            parent={item.parent}
          />
        );
      })}
      {artigos.map((item) => {
        return (
          <Linha
            key={item.id}
            id={item.id}
            name={item.title}
            type={"article"}
            isPublic={item.public}
            visible={item.visible}
            parent={item.parent}
          />
        );
      })}
    </LinesContainer>
  );
}

function Linha({
  id,
  name,
  parent,
  type = "folder",
  count,
  isPublic,
  visible,
}) {
  const { removeArticleFromFolder, editFolder } = useContext(FoldersContext);
  const navigate = useNavigate();

  function handleClick() {
    console.log({
      id,
      name,
      parent,
      type,
      count,
      isPublic,
      visible,
    });
    if (type === "folder") {
      return navigate(`/agents/conhecimento/pastas/${id}`);
    }
  }

  if (type === "folder") {
    return (
      <FolderLine onClick={handleClick}>
        <FH style={{ gap: 10 }}>
          <FontAwesomeIcon icon={faFolder} />
          <FontAwesomeIcon icon={visible ? faEye : faEyeSlash} />
          <FontAwesomeIcon icon={isPublic ? faGlobeAmericas : faLock} />
          <FolderName>{name}</FolderName>
        </FH>
        <FH
          style={{ justifyContent: "flex-end" }}
          onClick={(e) =>{
            e.stopPropagation();
            editFolder(id)}}
        >
          <FontAwesomeIcon icon={faPencil} />
          <FolderCount>
            <FontAwesomeIcon icon={faPage} />
            {count}
          </FolderCount>
          <FontAwesomeIcon icon={faAngleRight} />
        </FH>
      </FolderLine>
    );
  }

  if (type === "article") {
    return (
      <FileLine>
        <FH style={{ gap: 10 }}>
          <FontAwesomeIcon icon={faGripDotsVertical} color={"#cccccc"} />
          <FontAwesomeIcon icon={faPage} />
          <FolderName>{name}</FolderName>
        </FH>
        <FH style={{ justifyContent: "flex-end" }}>
          <FolderCount onClick={() => removeArticleFromFolder(id)}>
            <FontAwesomeIcon icon={faMinusCircle} color={"#b40000"} />
          </FolderCount>
        </FH>
      </FileLine>
    );
  }
}

const LinesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const FolderLine = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  color: #b78000;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  background-color: #fffcf7;
  &:hover {
    background-color: #fef5e7;
  }
`;

const FileLine = styled(FolderLine)`
  color: #616161;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const FolderName = styled.div``;

const BigFolderName = styled.div`
  padding: 0 10px;
  font-size: 25px;
  border-bottom: 1px solid #ddd;
  color: #b78000;
`;
const FolderCount = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export default FolderNavigator;
