import React from "react";
import { Col, Container, Row } from "reactstrap";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import BasicContent from "../../components/BasicContent";
import { faBook, faCog, faUser } from "@fortawesome/pro-solid-svg-icons";
import Perfil from "../agents/Settings/Perfil";

function PerfilUsuario() {
  const state = useSelector((state) => state);
  console.log("state:", state);
  return (
    <BasicContent title={"Configurações"} icon={faCog}>
        <Perfil noheader={true} />
    </BasicContent>
  );
}

export default PerfilUsuario;
