import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  Outlet,
  useNavigate,
} from "react-router-dom";
import {
  Wrapper,
  Sidebar,
  ReportArea,
  ReportSlot,
  ReportLink,
  Output,
  Links,
} from "./styled.js";
import { TitleContainer } from "../styles.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { Subtitle } from "../../main-styles.js";

const reports = [
  { id: "/pastas", name: "Pastas" },
  { id: "/artigos", name: "Artigos" },
];

const App = () => {
  const navigate = useNavigate();

  function goto(path) {
    navigate(path);
  }

  return (
    <Wrapper>
      {/* Sidebar com a lista de relatórios */}
      <Sidebar>
        <TitleContainer bottom={true}>Base de Conhecimento</TitleContainer>
        <Links>
          {reports.map((report) => {
            if (report.divider) {
              return (
                <Subtitle
                  style={{
                    color: "#666",
                    fontSize: "1.01rem",
                    fontWeight: "bold",
                  }}
                >
                  {report.name}
                </Subtitle>
              );
            }

            return (
              <ReportLink
                key={report.id}
                onClick={() => goto(`/agents/conhecimento${report.id}`)}
              >
                <FontAwesomeIcon icon={faChevronRight} />
                {report.name}
              </ReportLink>
            );
          })}
        </Links>
      </Sidebar>

      {/* Área de Relatórios */}
      <ReportArea>
        <Outlet />
      </ReportArea>
    </Wrapper>
  );
};

export default App;
