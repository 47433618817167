import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Spinner } from "reactstrap";
import styled from "styled-components";
import { TICKETS } from "../../api/TICKETS";
import { Description, FH, Subtitle, Text } from "../../routes/main-styles";
import {
  REQ_STATUS,
  TICKET_STATUS,
  TICKET_STATUS_COLORS,
  TICKET_STATUS_NAMES,
} from "../../util/constants";
import * as S from "./styles";

import { faBoxOpen, faPaperclip } from "@fortawesome/pro-solid-svg-icons";
import createDOMPurify from "dompurify";
import { USERS } from "../../api/USERS";
import AgentSelect from "../../components/AgentSelect";
import StatusSelect from "../../components/StatusSelect";

const DOMPurify = createDOMPurify(window);

function TicketViewer({ ticket, updateTicket }) {
  const navigate = useNavigate();
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    data: [],
    answer: "",
    files: [],
    noTicket: false,
    newStatus: TICKET_STATUS.WAITING_CUSTOMER,
    agents: [],
    loadingAgents: true,
  });
  const { status, data, noTicket, agents, loadingAgents } = state;

  useEffect(() => {
    loadAgents();
  }, []);

  useEffect(() => {
    load();
  }, [ticket]);

  async function loadAgents() {
    const agents = await USERS.get.agents();
    setState((s) => ({ ...s, agents, loadingAgents: false }));
  }

  async function load() {
    if (!ticket) return;
    setState((s) => ({ ...s, status: REQ_STATUS.LOADING }));
    const data = await TICKETS.get.one(ticket);

    console.log("TICKETS", data);
    setState((s) => ({ ...s, status: REQ_STATUS.IDLE, data }));
  }

  function updateAgent(agent) {
    confirmAlert({
      title: "Atualizar o responsável do ticket",
      message: "Tem certeza que deseja atualizar o responsável do ticket?",
      buttons: [
        {
          label: "Sim, atualizar",
          onClick: () => updateTicketAgent(agent),
          style: {
            backgroundColor: "#039f3c",
          },
        },
        {
          label: "Não",
          onClick: () => {},
          style: {
            backgroundColor: "#7c7c7c",
          },
        },
      ],
    });
  }

  async function updateTicketAgent(agent) {
    const newData = await TICKETS.set.updateAgent(ticket, agent);
    // console.log("newData", newData);
    // console.log("agent", agent);
    // console.log("id", id);
    updateTicket(ticket, "agent", agent);
    toast.success("Responsável atualizado com sucesso");
  }

  function updateStatusConfirm(status) {
    confirmAlert({
      title: "Atualizar o status do ticket",
      message: "Tem certeza que deseja atualizar o status do ticket?",
      buttons: [
        {
          label: "Sim, atualizar",
          onClick: () => updateTicketStatus(status),
          style: {
            backgroundColor: "#039f3c",
          },
        },
        {
          label: "Não",
          onClick: () => {},
          style: {
            backgroundColor: "#7c7c7c",
          },
        },
      ],
    });
  }

  async function updateTicketStatus(status) {
    const newData = await TICKETS.set.updateStatus(ticket, status);
    console.log("newData", newData);
    console.log("status", status);
    updateTicket(ticket, "status", status);
    toast.success("Status atualizado com sucesso");
  }

  if (noTicket && status === REQ_STATUS.IDLE) {
    return (
      <S.Ticket
        title="Nenhum ticket"
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // full height
          height: "100%",
          color: "#afafaf",
          backgroundColor: "#ffffff",
        }}
      >
        <FontAwesomeIcon icon={faBoxOpen} style={{ fontSize: 50 }} />
        <Description style={{ color: "#afafaf" }}>Nenhum ticket</Description>
      </S.Ticket>
    );
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <S.Ticket title="Carregando" style={{ padding: 20 }}>
        <Spinner />
      </S.Ticket>
    );
  }

  return (
    <S.Ticket>
      <S.TitleContainer bottom={true} style={{ width: "100%" }}>
        <FH style={{ justifyContent: "space-between", width: "100%" }}>
          <Subtitle
            style={{ textWrap: "nowrap", flex: 3 }}
          >{`#${data?.id} - ${data.subject}`}</Subtitle>
          <FH
            style={{ justifyContent: "flex-end", gap: 5, alignItems: "center" }}
          >
            {/* <div style={{ fontSize: 12 }}>
              Prioridade:{" "}
              <NewStatusSelect
                value={data?.priority}
                onChange={(e) => console.log(e)}
              >
                <option value="low">Baixa</option>
                <option value="normal">Normal</option>
                <option value="high">Alta</option>
              </NewStatusSelect>
            </div> */}
            <div style={{ fontSize: 12 }}>
              Responsável:{" "}
              {loadingAgents ? (
                "-"
              ) : (
                <AgentSelect
                  value={data?.agent}
                  options={agents?.map((agent) => ({
                    value: agent.id,
                    label: agent.name,
                    image: agent.thumbnail,
                    email: agent.email,
                  }))}
                  onChange={(e) => updateAgent(e.value)}
                />
              )}
            </div>
            <div style={{ fontSize: 12 }}>
              Status:{" "}
              <StatusSelect
                value={data?.status}
                options={Object.keys(TICKET_STATUS_NAMES).map((key) => ({
                  value: key,
                  label: TICKET_STATUS_NAMES[key],
                  color: TICKET_STATUS_COLORS[key],
                }))}
                onChange={(e) => updateStatusConfirm(e.value)}
              />
            </div>
          </FH>
        </FH>
      </S.TitleContainer>

      <S.TicketsContainer>
        <Reply
          description={data?.description}
          files={data?.files}
          created_at={data?.created_at}
          username={data?.user}
          user_id={data?.user_id}
        />
        <hr />
        {data?.thread?.map((item) => {
          if (item.type === "status_update") {
            return (
              <StatusUpdate
                created_at={item.date}
                description={item.content}
                username={item.name}
                user_id={item.created_by}
              />
            );
          }

          return (
            <Reply
              key={item.id}
              description={item.content}
              files={item.files}
              created_at={item.date}
              username={item.name}
              thumbnail={item.thumbnail}
              user_id={item.user_id}
            />
          );
        })}
        {/* INICIO RESPOSTA */}
      </S.TicketsContainer>
    </S.Ticket>
  );
}

function Reply({
  created_at,
  description,
  username,
  thumbnail,
  user_id,
  files,
}) {
  const user = useSelector((state) => state.authReducer.user);
  const mine = parseInt(user.id) === parseInt(user_id);
  const [selected, setSelected] = useState(null);
  return (
    <ThreadItem mine={mine}>
      <FH>
        <ThreadUserImg
          src={
            thumbnail
              ? thumbnail
              : "https://img.seidecor.com.br/fit-in/150x150/-LIG_iJcJTGJZwPKMJiZ/generated-images/generated_zmxo1.png"
          }
        />
        <div>
          <Description style={{ fontWeight: "bold" }}>
            {mine ? "Você" : username}
          </Description>
          <Description
            style={{ color: "#ababab", fontSize: "0.8rem" }}
            title={moment(created_at).format("DD/MM/YYYY HH:mm")}
          >
            {moment(created_at) < moment().add(-3, "days")
              ? moment(created_at).format("DD/MM/YYYY HH:mm")
              : moment(created_at).fromNow()}
          </Description>
        </div>
      </FH>
      <Text
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(description),
        }}
      />
      {files && files.length > 0 && (
        <>
          <hr />
          <Description>
            <FontAwesomeIcon icon={faPaperclip} /> Anexos
          </Description>
          <FH style={{ gap: 25, flexWrap: "wrap" }}>
            {files.map((file, index) => {
              const filename = file.split("/").pop();
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 5,
                    fontSize: "0.8rem",
                    color: "#ababab",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelected(file)}
                >
                  <img
                    src={file}
                    alt={filename}
                    style={{
                      width: 100,
                      height: 100,
                      border: "1px solid #ccc",
                      borderRadius: 5,
                      cursor: "pointer",
                      // cover
                      objectFit: "cover",
                      // center
                      objectPosition: "center",
                      // elipsis text
                    }}
                  />
                  <span
                    style={{
                      maxWidth: 100,
                      // elipsis long text
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      flexWrap: "nowrap",
                      textWrap: "nowrap",
                      fontSize: "0.7rem",
                    }}
                  >
                    {filename}
                  </span>
                </div>
              );
            })}
          </FH>
        </>
      )}
      <Modal
        isOpen={selected !== null}
        toggle={() => setSelected(null)}
        size="lg"
      >
        {selected && (
          <div
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={selected}
              alt="Anexo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
            <span
              style={{
                fontSize: "0.8rem",
                color: "#ababab",
              }}
            >
              {selected?.split("/").pop()}
            </span>
          </div>
        )}
      </Modal>
    </ThreadItem>
  );
}

function StatusUpdate({
  created_at,
  description,
  username,
  thumbnail,
  user_id,
  files,
}) {
  const user = useSelector((state) => state.authReducer.user);
  const me = parseInt(user.id) === parseInt(user_id);
  return (
    <StatusUpdateContainer>
      <strong>{me ? "Você" : username}</strong> marcou o ticket como{" "}
      <strong>"{TICKET_STATUS_NAMES[description]}"</strong> em{" "}
      <strong>{moment(created_at).format("DD/MM/YYYY HH:mm")}</strong>
    </StatusUpdateContainer>
  );
}

const StatusUpdateContainer = styled.div`
  padding: 1rem;
  color: #ababab;
  font-size: 0.9rem;
  background-color: #f9f9f9;
  border-radius: 10px;
`;

const ThreadItem = styled.div`
  border: ${(props) =>
    props.mine ? "2px solid #e6e6e6" : "1px solid #cccccc"};
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  line-height: 1.1;
  gap: 0.5rem;
  background-color: #fff;
`;

const ThreadUserImg = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;
`;

export default TicketViewer;
