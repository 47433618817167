import api from "../axios";
import { store } from "../../redux/configureStore";

export const USERS = {
  get: {
    me: async () => {
      let data = await api.get("/users/me");
      return data.data;
    },
    all: async () => {},
    one: async (id, tid) => {
      if(!tid) tid = store.getState().appReducer.app.tid;
      const response = await api.get(`/users/one/${tid}/${id}`);
      return response.data;
    },
    check: async () => {},
    agents: async () => {
      const tid = store.getState().appReducer.app.tid;
      const response = await api.get(`/users/agents/${tid}`);
      return response.data;
    },
    groups: async (tid) => {
      if (!tid) tid = store.getState().appReducer.app.tid;
      const response = await api.get(`/users/groups/${tid}`);
      return response.data;
    },
    group: async (id, tid) => {
      if (!tid) tid = store.getState().appReducer.app.tid;
      const response = await api.get(`/users/group/${tid}/${id}`);
      return response.data;
    },
    codeIsStillValid: async (code) => {
      const { tid } = store.getState().appReducer.app;  
      const response = await api.get(`/users/reset-password/${tid}/${code}`);
      return response.data;
    }
  },
  set: {
    new: async (data) => {
      const tid = store.getState().appReducer.app.tid;
      const response = await api.post(`/users/user/${tid}`, data);
      return response.data;
    },
    updateProfile: async (name, thumbnail, signature, id, tid) => {
      if (!tid) tid = store.getState().appReducer.app.tid;
      if (!id) id = store.getState().authReducer.user.id;
      const response = await api.post(`/users/${tid}/${id}`, {
        name,
        thumbnail,
        signature,
      });
      return response.data;
    },
    updatePassword: async (password, currentPassword, tid, id) => {
      if (!tid) tid = store.getState().appReducer.app.tid;
      if (!id) id = store.getState().authReducer.user.id;
      const response = await api.post(`/users/${tid}/${id}/password`, {
        password,
        currentPassword,
      });
      return response.data;
    },
    resetPassword: async(code, password) =>{
      const { tid } = store.getState().appReducer.app;  
      const response = await api.post(`/users/set-password/${tid}/${code}`, {
        password
      });
      return response.data;
    }
    ,
    newAgent: async(name, email, role, tid)=>{
      if (!tid) tid = store.getState().appReducer.app.tid;
      const response = await api.post(`/users/agent/${tid}`, {
        name,
        email,
        role,
      });
      return response.data;
    },
    updateAgent: async (id, name, email, role, password, tid) => {
      if (!tid) tid = store.getState().appReducer.app.tid;
      const response = await api.post(`/users/agent/${tid}/${id}`, {
        name,
        email,
        role,
        password,
      });
      return response.data;
    },
    deleteGroup: async (id, tid) => {
      if (!tid) tid = store.getState().appReducer.app.tid;
      const response = await api.delete(`/users/group/${tid}/${id}`);
      return response.data;
    },
    newGroup: async (name, tid) => {
      if (!tid) tid = store.getState().appReducer.app.tid;
      const response = await api.post(`/users/group/${tid}`, {
        name,
      });
      return response.data;
    },
    updateGroup: async (name, id, tid) => {
      if (!tid) tid = store.getState().appReducer.app.tid;
      const response = await api.post(`/users/group/${tid}/${id}`, {
        name,
      });
      return response.data;
    },
    update: async (id, data) => {},
    delete: async (id, tid) => {
      if (!tid) tid = store.getState().appReducer.app.tid;
      const response = await api.delete(`/users/${tid}/${id}`);
      return response.data;
    },
    activate: async (id) => {
      const response = await api.post(`/users/activate/${id}`);
      return response;
    },
    sendActivation: async (id, tid) => {
      if (!tid) tid = store.getState().appReducer.app.tid;
      const response = await api.post(`/users/send-activation/${tid}/${id}`);
      return response;
    }
  },
};
