import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import FullScreenLoader from "../components/FullScreenLoader";
import { loadApp } from "../redux/reducers/app";
import KnowledgeBase from "./KnowledgeBase";
import ChangePassword from "./auth/ChangePassword";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import Validate from "./auth/Validate";
import Comercial from "./comercial";
import Pricing from "./pricing";
import TicketList from "./ticket/List";
import NewWorkspace from "./workspace/NewWorkspace";
import TicketView from "./ticket/TicketView";
import NovoTicket from "./ticket/Novo";
import Agents from "./agents";
import Settings from "./agents/Settings";


import { USERS } from "../api/USERS";
import { logout, setUser } from "../redux/reducers/auth";
import Tickets from "./agents/Tickets";
import CompleteSignup from "./workspace/CompleteSignup";
import RedirectToWorkspace from "./auth/RedirectToWorkspace";
import Reports from "./agents/Reports";

import ReportCSAT from "./agents/Reports/CSAT/CSAT";
import ReportLowCSAT from "./agents/Reports/CSAT/Details";
import ReportTickets from "./agents/Reports/Tickets";
import ReportAgentPerformance from "./agents/Reports/Agents/Performance";
import ReportGroupsPerformance from "./agents/Reports/Groups/Performance";
import ReportGroupTicketsReport from "./agents/Reports/Groups/Tickets";
import ReportTemporalTrends from "./agents/Reports/TemporalTrends";
import useWebSocket, {
  WebSocketProvider,
} from "../components/WebSocketProvider";
import { Toaster } from "react-hot-toast";
import Perfil from "./agents/Settings/Perfil";
import Layout from "./agents/Settings/Aparencia";

import AKB from "./agents/KnowledgeBase";
import Pastas from "./agents/KnowledgeBase/Pastas";
import Artigos from "./agents/KnowledgeBase/Artigos";
import EditorDeArtigos from "./agents/KnowledgeBase/Artigos/Editor";
import VisualizadorArtigos from "./agents/KnowledgeBase/Artigos/View";
import Termos from "./workspace/Termos";
import Politica from "./workspace/Politica";
import AgentsSettings from "./agents/Settings/Agents";
import GroupSettings from "./agents/Settings/Groups";
import PerfilUsuario from "./perfil";
import Article from "./KnowledgeBase/Article";

export default function App() {
  const { authReducer } = useSelector((state) => state);
  const { isAuthenticated, user, locked, lockedReason } = authReducer;
  const role = user?.role || "guest";
  const { appReducer } = useSelector((state) => state);

  const { loading, app } = appReducer;
  const { show_new_tennant, title } = app;
  const dispatch = useDispatch();
  useEffect(() => {
    init();
  }, [isAuthenticated]);

  async function init() {
    dispatch(loadApp());
    if (isAuthenticated) {
      loadUser();
    }
  }

  async function loadUser() {
    const user = await USERS.get.me();
    console.log("updated user:", user);
    if (user.status !== "active") {
      return dispatch(logout());
    }
    dispatch(setUser(user));
  }

  function setFavicon(url) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = url;
  }

  useEffect(() => {
    document.title = `${app?.title}`;
    if (app?.favicon) setFavicon(app?.favicon);
  }, [appReducer]);

  const isTennant = !app?.show_new_tennant;

  if (loading) return <FullScreenLoader />;

  if (locked) return <FullScreenLoader message={lockedReason} />;

  return (
    <Router>
      <Toaster position="top-right" reverseOrder={false} />
      <Routes>
        {!isTennant ? (
          <>
            {/* MAIN PAGE ROUTES */}
            <Route path="/" element={<Comercial />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/:language/termos-de-uso" element={<Termos />} />
            <Route path="/:language/privacidade" element={<Politica />} />
            <Route path="/about"></Route>
            <Route path="/auth/sign-in" element={<RedirectToWorkspace />} />
            <Route path="/:language/workspace/new" element={<NewWorkspace />} />
            <Route
              path="/:language/workspace/new/:ws"
              element={<NewWorkspace />}
            />
            <Route
              path="/:language/workspace/activate/:ws/:code"
              element={<CompleteSignup />}
            />
            {/* <Route path="/knowledge-base" element={<KnowledgeBase />} /> */}
          </>
        ) : (
          <>
            {/* UNAUTHENTICATED ROUTES */}
            {!isAuthenticated && (
              <>
                <Route path="/auth/sign-in" element={<SignIn />} />
                <Route path="/auth/sign-up" element={<SignUp />} />
                <Route path="/auth/validate/:uuid" element={<Validate />} />
                <Route
                  path="/auth/reset-password/:code"
                  element={<ChangePassword />}
                />
              </>
            )}

            {/* AUTHENTICATED ROUTES */}
            {isAuthenticated && (
              <>
                <Route path="/auth/sign-in" element={<Navigate to="/" />} />
                <Route path="/auth/sign-up" element={<Navigate to="/" />} />
                <Route
                  path="/auth/validate/:uuid"
                  element={<Navigate to="/" />}
                />
                <Route path="/tickets" element={<TicketList />} />
                <Route
                  path="/tickets/visualizar/:id"
                  element={<TicketView />}
                />
                <Route path="/tickets/novo" element={<NovoTicket />} />
                <Route path="/configuracoes" element={<PerfilUsuario  />}/>
                <Route path="/users"></Route>
                {role !== "user" && role !== "guest" && (
                  <Route path="/agents" element={<Agents />}>
                    <Route
                      path="/agents"
                      element={<Navigate to="/agents/tickets" />}
                    />
                    <Route path="/agents/tickets" element={<Tickets />} />
                    <Route path="/agents/tickets/:id" element={<Tickets />} />
                    <Route path="/agents/configuracoes" element={<Settings />}>
                      <Route
                        path="/agents/configuracoes/usuario/perfil"
                        element={<Perfil />}
                      />
                      {role !== "agent" && (
                        <>
                          <Route
                            path="/agents/configuracoes/empresa/aparencia"
                            element={<Layout />}
                          />
                          <Route
                            path="/agents/configuracoes/empresa/agents"
                            element={<AgentsSettings />}
                          />
                          <Route
                            path="/agents/configuracoes/empresa/grupos"
                            element={<GroupSettings />}
                          />
                        </>
                      )}
                    </Route>

                    {role !== "agent" && (
                      <Route path="/agents/reports" element={<Reports />}>
                        <Route
                          path="/agents/reports"
                          element={<Navigate to="/agents/reports/csat" />}
                        />
                        <Route
                          path="/agents/reports/tickets"
                          element={<ReportTickets />}
                        />
                        <Route
                          path="/agents/reports/csat"
                          element={<ReportCSAT />}
                        />
                        <Route
                          path="/agents/reports/csat/detalhes"
                          element={<ReportLowCSAT />}
                        />
                        <Route
                          path="/agents/reports/agents/performance"
                          element={<ReportAgentPerformance />}
                        />
                        <Route
                          path="/agents/reports/groups/performance"
                          element={<ReportGroupsPerformance />}
                        />
                        <Route
                          path="/agents/reports/groups/tickets"
                          element={<ReportGroupTicketsReport />}
                        />
                        <Route
                          path="/agents/reports/temporal-trends"
                          element={<ReportTemporalTrends />}
                        />
                        {/* <Route path="/agents/reports/problems" element={<ReportCSAT />} /> */}
                        {/* <Route path="/agents/reports/sla" element={<ReportCSAT />} /> */}
                        {/* <Route path="/agents/reports/feedbacks" element={<ReportCSAT />} /> */}
                      </Route>
                    )}
                    <Route path="/agents/conhecimento" element={<AKB />}>
                      <Route
                        path="/agents/conhecimento"
                        element={<Navigate to="/agents/conhecimento/pastas" />}
                      />
                      <Route
                        path="/agents/conhecimento/pastas"
                        element={<Pastas />}
                      />
                      <Route
                        path="/agents/conhecimento/pastas/:id"
                        element={<Pastas />}
                      />

                      <Route
                        path="/agents/conhecimento/artigos"
                        element={<Artigos />}
                      />
                      <Route
                        path="/agents/conhecimento/artigos/view/:id"
                        element={<VisualizadorArtigos />}
                      />
                      <Route
                        path="/agents/conhecimento/artigos/editar/:id"
                        element={<EditorDeArtigos />}
                      />
                      <Route
                        path="/agents/conhecimento/artigos/novo"
                        element={<EditorDeArtigos />}
                      />
                      {/* <Route
                        path="/agents/conhecimento/artigos"
                        element={<AKB />}
                      /> */}
                    </Route>
                  </Route>
                )}
              </>
            )}

            <Route path="/" element={<Navigate to="/folder" />} />
            <Route path="/folder" element={<KnowledgeBase />} />
            <Route path="/folder/:id" element={<KnowledgeBase />} />
            <Route path="/article/:folder/:id" element={<Article />} />
            <Route path="/knowledge-base" element={<KnowledgeBase />} />
          </>
        )}
      </Routes>
    </Router>
  );
}
