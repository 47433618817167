import React, { useContext, useEffect, useMemo, useState } from "react";

// import * as S from "./styles";
import { FoldersContext } from "..";
import { KNOWLEDGEBASE } from "../../../../../api/KNOWLEDGEBASE";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { Input, Spinner } from "reactstrap";
import { FilterArea } from "../../styled";

function ArticleSelector({}) {
  const [loading, setLoading] = useState(true);
  const [allArticles, setAllArticles] = useState([]);
  const [needle, setNeedle] = useState("");
  const context = useContext(FoldersContext);
  const { state, addArticleToFolder } = context;
  const { pastas, artigos, status, folderInfo } = state;

  useEffect(() => {
    loadContent();
  }, []);

  async function loadContent() {
    // pega todos os artigos
    setLoading(true);
    let promises = [loadArticles()];
    const [articles] = await Promise.all(promises);
    setAllArticles(articles);
    setLoading(false);
  }

  async function loadArticles() {
    // let newArticles = await KNOWLEDGEBASE.get.articles(id);
    let newArticles = await KNOWLEDGEBASE.get.articles(undefined, false, "");
    console.log("newArticles", newArticles);
    return newArticles;
  }

  // remove os que estão na pasta ká
  const filtered = useMemo(()=>{
    return allArticles
    .filter((article) => {
      console.log("ARTUICLE", article, artigos);
      let tem = artigos.some((a) => parseInt(a.id) === parseInt(article.id));
      console.log("TEM", tem);
      return !tem;
    })
    .filter((article) => {
      if (needle === "") return true;
      return article.title.toLowerCase().indexOf(needle.toLowerCase()) !== -1;
    });
  }, [allArticles, artigos, needle]);

  return (
    <ArticlesContainer>
      {loading ? (
        <Spinner style={{ margin: 10 }} />
      ) : (
        <>
          <FilterArea>
            <Input
              placeholder="Buscar artigo"
              value={needle}
              onChange={(e) => setNeedle(e.target.value)}
            />
          </FilterArea>
          {filtered.map((article) => {
            return (
              <Article key={article.id} onClick={()=>{
                addArticleToFolder(article);
              }}>
                <FontAwesomeIcon icon={faPlusCircle} color="#0aa714" />
                {article.title}
              </Article>
            );
          })}
        </>
      )}
    </ArticlesContainer>
  );
}

const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Article = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid #ebebeb;
  &:hover {
    background: #f0f0f0;
  }
`;

export default ArticleSelector;
