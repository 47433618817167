import React, { useEffect } from "react";

import * as S from "../styles";

import { useSelector } from "react-redux";
import mainlogo from "../../../assets/helpdesk/logo-small.png";
import Button from "../../../components/Button";

import PoweredLink from "../../../components/PoweredLink";
import { Input, Label, Logo, Text } from "../../main-styles";
import { REQ_STATUS } from "../../../util/constants";
import { useParams } from "react-router-dom";
import { USERS } from "../../../api/USERS";
import toast from "react-hot-toast";

function ChangePassword() {
  const { code } = useParams();
  const { appReducer } = useSelector((state) => state);
  const { app } = appReducer;
  console.log("app:", app);
  const colors = app?.colors;
  const backgroundColor = `${colors?.main}1c`;

  const [state, setState] = React.useState({
    password: "",
    confirmPassword: "",
    status: REQ_STATUS.LOADING,
  });

  const { password, confirmPassword, status } = state;

  useEffect(() => {
    console.log("code", code);
    verifyCode();
  }, [code]);

  async function verifyCode() {
    const stillValid = await USERS.get.codeIsStillValid(code);
    if (stillValid) {
      console.log("Code is still valid");
      setState((s) => ({ ...s, status: REQ_STATUS.IDLE }));
    } else {
      console.log("Code is not valid");
      setState((s) => ({ ...s, status: REQ_STATUS.ERROR }));
    }
  }

  async function changePassword() {
    if (status !== REQ_STATUS.IDLE) {
      return;
    }
    if (password.length < 6) {
      return toast.error("A senha deve ter no mínimo 6 caracteres.");
    }
    if (password !== confirmPassword) {
      return toast.error("As senhas não conferem.");
    }
    setState((s) => ({ ...s, status: REQ_STATUS.SENDING }));
    try {
      let reponse = await USERS.set.resetPassword(code, password);
      if (reponse) {
        setState((s) => ({ ...s, status: REQ_STATUS.SUCCESS }));
      }
    } catch (error) {
      console.error(error);
      setState((s) => ({ ...s, status: REQ_STATUS.ERROR }));
    }
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <S.Container backgroundColor={backgroundColor}>
        <S.WhiteContainer>
          <Logo src={app?.darklogo || mainlogo} />
          <Text>Verificando código...</Text>
        </S.WhiteContainer>
        <PoweredLink />
      </S.Container>
    );
  }

  if (status === REQ_STATUS.ERROR) {
    return (
      <S.Container backgroundColor={backgroundColor}>
        <S.WhiteContainer>
          <Logo src={app?.darklogo || mainlogo} />
          <Text>Código Inválido.</Text>
          <Label>Este código é inválido ou já foi utilizado.</Label>
        </S.WhiteContainer>
        <PoweredLink />
      </S.Container>
    );
  }

  if (status === REQ_STATUS.SUCCESS) {
    return (
      <S.Container backgroundColor={backgroundColor}>
        <S.WhiteContainer>
          <Logo src={app?.darklogo || mainlogo} />
          <Text>Senha alterada com sucesso.</Text>
          <Label>Sua senha foi alterada com sucesso.</Label>
          <Button onClick={() => window.location.replace("/auth/sign-in")}>
            Acessar
          </Button>
        </S.WhiteContainer>
        <PoweredLink />
      </S.Container>
    );
  }
  return (
    <S.Container backgroundColor={backgroundColor}>
      <S.WhiteContainer>
        <Logo src={app?.darklogo || mainlogo} />
        <Text>Vamos definir uma nova senha para sua conta.</Text>
        <Input
          type="password"
          placeholder="Nova senha"
          autoComplete="new-complete"
          value={password}
          onChange={(e) => setState({ ...state, password: e.target.value })}
        />
        <Input
          type="password"
          placeholder="Confirmação de senha"
          value={confirmPassword}
          onChange={(e) =>
            setState({ ...state, confirmPassword: e.target.value })
          }
        />

        <Button onClick={changePassword}>
          {status === REQ_STATUS.IDLE ? "Salvar" : "Salvando..."}
        </Button>
      </S.WhiteContainer>
      <PoweredLink />
    </S.Container>
  );
}

export default ChangePassword;
