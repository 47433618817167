import React from "react";

import Editor from "react-simple-wysiwyg";
// import styled from "styled-components";

function WYSEditor({ value, disabled = false, onChange, style }) {
  return (
      <Editor
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        containerProps={{
          style: { minHeight: 200,  height: 200, ...style },
        }}
      
      />
      
  );
}

export default WYSEditor;
