import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APP } from "../../../api/APP";
import { API_URL, MAIN_APP_URL } from "../../../util/constants";

const initialState = {
  loading: true,
  error: false,
  app: {
    name: "HelpDesk.app",
    tid: "1",
    colors: {
      main: "#0f5295",
      secondary: "#555",
      accent: "#777",
      text: "#fff",
      title: "#ffffff",
    },
    logo: "/assets/logo.png",
    favicon: "/assets/logo.png",
    website: "https://help-desk.app",
    title: "Painel de Suporte",
    background_color: "#333",
    workspace: "www",
  },
};

// async load app by id
export const loadApp = createAsyncThunk("app/loadApp", async () => {
  // gets id from subdomain
  console.log("Vai carregar o app");

  const id = window.location.hostname.split(".")[0];
  console.log("ID:", id);

  const response = await APP.get.public_workspace(id);
  console.log("App loaded:", response);
  if(response?.data?.notFound){
    window.location.href = `${MAIN_APP_URL}/pt-BR/workspace/new/${response?.data?.workspace}`
  }
  let isdefault = false;
  if (id === "www" || id === "localhost") {
     isdefault = true;
  }

  let info = response.data;

  document.title = info.page_title;

  let payload = {
    name: info.public_name,
    show_new_tennant: isdefault,
    colors: {
      main: info.main_color,
      secondary: info.secondary_color,
      accent: info.action_color,
      text: info.font_color,
      title: info.main_color,
      headerText: info.page_title_color,
    },
    title: info.page_title,
    logo: info.logo,
    darklogo: info.darklogo,
    favicon: info.favicon,
    website: info.website,
    remove_logo: info.remove_logo,
    tid: info.tid,
    workspace: info.workspace,
  };

  console.log("PAYLOAD:", payload);

  return payload;
});

const appSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoadingApp: (state) => {
      state.loading = true;
    },

    reloadApp: (state) => {
      state.loading = true;
    },
    updateApp: (state, action) => {
      /* action.payload = payload = {
      name: public_name,
      title: page_title,
      logo: logoUrl,
      darklogo: darklogoUrl,
      favicon: faviconUrl,
      colors: {
        main: main_color,
        secondary: secondary_color,
        text: font_color,
        title: title_color,
        accent: action_color,
        headerText: page_title_color,
      },
      background_color,
    }; */
      state.app.name = action.payload.name;
      state.app.title = action.payload.title;
      state.app.logo = action.payload.logo;
      state.app.darklogo = action.payload.darklogo;
      state.app.favicon = action.payload.favicon;
      state.app.colors.main = action.payload.colors.main;
      state.app.colors.secondary = action.payload.colors.secondary;
      state.app.colors.text = action.payload.colors.text;
      state.app.colors.title = action.payload.colors.title;
      state.app.colors.accent = action.payload.colors.accent;
      state.app.colors.headerText = action.payload.colors.headerText;
      state.app.background_color = action.payload.background_color;

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadApp.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadApp.fulfilled, (state, action) => {
        console.log("FULLFILLED", action);
        state.app = action.payload;
        state.loading = false;
      })
      .addCase(loadApp.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setApp, reloadApp, setLoadingApp, updateApp } = appSlice.actions;

export default appSlice.reducer;
