import React, { useEffect } from "react";

import * as S from "./styles";
import Header from "./Header";
import Hero from "./Hero";
import WhatWeDo from "./WhatWeDo";
import FAQ from "./FAQ";
import Footer from "./Footer";
import CTA from "./CTA";
import Pricing from "../pricing";

import MagicKeyboard from "../../assets/magickeyboard.jpg";

function Comercial() {
  useEffect(() => {
    let windowtitle = "Help-Desk.app - Sistema de tickets de suporte";
    document.title = windowtitle;
    let favicon = "/assets/favicon.png";
    let link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = favicon;
    document.getElementsByTagName("head")[0].appendChild(link);
  }, []);

  return (
    <>
      <S.FullImage image={MagicKeyboard}>
        <Header />
        <Hero />
      </S.FullImage>
      <S.Container>
        <S.CenteredContainer>
          <WhatWeDo />
          <FAQ />
          <Pricing />
          <CTA />
        </S.CenteredContainer>
      </S.Container>

      <Footer />
    </>
  );
}

export default Comercial;
