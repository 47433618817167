import React from "react";

import * as S from "../styles";
import { Button, Description } from "../../main-styles";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Container, Row } from "reactstrap";


function Hero() {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  return (

    <S.HeroContainer>
      <S.HeroLeft className="animate__animated animate__fadeInLeft">
        <S.HeroTitle>
          {t("home.hero.resolva-chamados-com-rapidez-e-eficiencia")}
        </S.HeroTitle>
        <S.HeroSubtitle>
          {t('home.hero.organize-seu-suporte-melhorando-e-acompanhando-cada-atendimento')}
        </S.HeroSubtitle>
        <Link to={`/${language}/workspace/new`}>
          <Button
            style={{ maxWidth: 200 }}
            className="animate__animated animate__pulse animate__repeat-2"
          >
            {t('home.hero.comece-agora-gratuitamente')}
          </Button>
        </Link>
        <Description style={{ marginTop: 5 }}>
          {t('home.hero.nao-e-necessario-cartao-de-credito')}
        </Description>
      </S.HeroLeft>
      <S.HeroRight>
        <S.HeroImage
          className="animate__animated animate__fadeInRight"
          src="https://img.seidecor.com.br/fit-in/800x800/-LIG_iJcJTGJZwPKMJiZ/generated-images/generated_x426j.png"
        />
      </S.HeroRight>
    </S.HeroContainer>

  );
}

export default Hero;
