import api from "../axios";
import axios from "axios";
import { store } from "../../redux/configureStore";
export const KNOWLEDGEBASE = {
  get: {
    folder: async (id) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.get(`/knowledge-base/folders/${tid}/${id}`);
      return response.data;
    },
    folders: async (parent, onlyVisible = true) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.get(`/knowledge-base/folders/${tid}`, {
        params: {
          parent,
          onlyVisible,
        },
      });
      return response.data;
    },
    articles: async (parent, needle) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.get(`/knowledge-base/articles/${tid}`, {
        params: {
          parent,
          needle,
        },
      });
      return response.data;
    },
    public_folders: async (parent=null) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.get(`/knowledge-base/public-folders/${tid}/${parent}`);
      return response.data;
    },
    public_folder_info: async (id) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.get(`/knowledge-base/public-folder/${tid}/${id}`);
      return response.data;
    },
    public_articles: async (parent=null) => {
      if(parent === null){
        return []
      }
      const { tid } = store.getState().appReducer.app;
      let response = await api.get(`/knowledge-base/public-articles/${tid}/${parent}`);
      return response.data;
    },
    public_article: async (id) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.get(`/knowledge-base/public-article/${tid}/${id}`);
      return response.data;
    },
    article_folders: async (id) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.get(
        `/knowledge-base/article-folders/${tid}/${id}`
      );
      return response.data;
    },
    article: async (id) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.get(`/knowledge-base/articles/${tid}/${id}`);
      return response.data;
    },
    tags: async () => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.get(`/knowledge-base/tags/${tid}`);
      return response.data;
    },
  },
  set: {
   
    deleteFolder: async (id) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.delete(`/knowledge-base/folders/${tid}/${id}`);
      return response.data;
    },
    new_folder: async (
      parent,
      name,
      description,
      visible = false,
      isPublic = false
    ) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.post(`/knowledge-base/folders/${tid}`, {
        parent,
        name,
        description,
        visible,
        public: isPublic,
      });
      return response.data;
    },
    addArticleToFolder: async (folderId, articleId) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.post(
        `/knowledge-base/folders/${tid}/${folderId}/articles/${articleId}`
      );
      return response.data;
    },
    removeArticleFromFolder: async (folderId, articleId) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.delete(
        `/knowledge-base/folders/${tid}/${folderId}/articles/${articleId}`
      );
      return response.data;
    },
    update_article: async (id, title, content, tags=[]) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.put(
        `/knowledge-base/articles/${tid}/${id}`,
        {
          title,
          content,
          tags,
        }
      );
      return response.data;
    },
    newArticle: async (title, content, tags) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.post(`/knowledge-base/articles/${tid}`, {
        title,
        content,
        tags,
      });
      return response.data;
    },
    updateFolder: async (id, nome, description, visible, isPublic) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.put(`/knowledge-base/folders/${tid}/${id}`, {
        nome,
        description,
        visible,
        public: isPublic,
      });
      return response.data;
    },
    delete_article: async (id) => {
      const { tid } = store.getState().appReducer.app;
      let response = await api.delete(`/knowledge-base/articles/${tid}/${id}`);
      return response.data;
    }
  },
};
