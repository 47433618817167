import React from "react";
import { useTranslation } from "react-i18next";

import { Col, Container, Row } from "reactstrap";

function Footer() {
  const { t } = useTranslation();
  return (
    <Container
      fluid={true}
      style={{ backgroundColor: "#464646", padding: "3rem" }}
    >
      <Row>
        <Col md={1} style={{ color: "#fff" }} />
        <Col md={10} style={{ color: "#fff" }}>
          <strong>{t('home.footer.companyname')}</strong>
          <br />
          {t('home.footer.copyright')}
          <br />
          {t('home.footer.cnpj')}
          <br />
          {t('home.footer.address')}  
          <br />
          {t('home.footer.email')}
        </Col>
        <Col md={1} style={{ color: "#fff" }} />
      </Row>
    </Container>
  );
}

export default Footer;
