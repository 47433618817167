import React, { useEffect, useState } from "react";

// import * as S from "./styles";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const TERMS = [
  {
    "language": "pt-BR",
    "content": `<h1>CONTRATO DE PRESTAÇÃO DE SERVIÇOS E ASSINATURA DE SOFTWARE</h1>
    <p>Por este instrumento particular de contrato, de um lado, Hdapp Sistemas LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob o número 58.420.116/0001-60, com sede à Rua Xv de Novembro, 556, conjunto 1406, Centro em Curitiba, PR, CEP 80020-310, abaixo denominada simplesmente \"HELP-DESK.app\" e, de outro lado, o LICENCIADO, qualificado conforme cadastro eletrônico realizado em <a href=\"https://www.help-desk.app/\">https://www.help-desk.app/</a>, que fazem parte integrante deste instrumento, abaixo simplesmente denominado como LICENCIADO, têm entre si justo e acertado o que segue:</p>

    <h2>1. Objeto do contrato</h2>
    <p>Por meio deste CONTRATO, o LICENCIADO está sujeito aos termos e condições estabelecidos nos termos de uso do SOFTWARE, obtendo uma licença revogável, não exclusiva e intransferível para uso do SOFTWARE. O LICENCIADO não poderá utilizar e nem permitir que o SOFTWARE seja utilizado para outra finalidade que não seja o processamento de suas informações ou de pessoas jurídicas indicadas por ele no ato do cadastramento. Esta licença não implica a capacidade de acessar outros softwares além daqueles originalmente localizados no SOFTWARE. Em nenhuma hipótese o LICENCIADO terá acesso ao código fonte do SOFTWARE ora licenciado, por este se tratar de propriedade intelectual da HELP-DESK.app.</p>

    <h2>2. Definições</h2>
    <p>Os termos iniciados em letra maiúscula indicados a seguir serão utilizados neste instrumento com o significado abaixo:</p>
    <ul>
      <li><strong>\"A filiada\"</strong>: significa toda entidade que controla, é controlada por ou encontra-se sob controle comum de uma das entidades LICENCIADOS. \"Controle\" para todos os fins, significa a propriedade direta ou indireta ou controle de mais de 50% do capital com direito a voto.</li>
      <li><strong>\"Licenciado\"</strong>: pessoa física ou jurídica, com plena capacidade de contratar, que acessa o SOFTWARE da HELP-DESK.app por meio do site, realizando seu cadastro, aceitando os termos do presente EULA e usufruindo das funcionalidades oferecidas de acordo com o plano de licenciamento contratado.</li>
      <li><strong>\"Dados do Licenciado\"</strong>: qualquer informação disponibilizada pelo LICENCIADO que o identifique ou torne identificável, tais como nome, endereço, data de nascimento, número de telefone, fax, endereço eletrônico, número de documentos, etc.</li>
      <li><strong>\"Informações\"</strong>: entende-se todas as informações do LICENCIADO relacionadas a INFORMAÇÕES PESSOAIS, INFORMAÇÕES DE CHAMADOS, INFORMAÇÕES DE CADASTROS, INFORMAÇÕES CHAT, e QUAISQUER OUTRA INFORMAÇÃO que venha a inserir no sistema HELP-DESK.app.</li>
      <li><strong>\"Código Malicioso\"</strong>: significa vírus, vírus classificados como \"Worms\", bomba relógio, Cavalo de Tróia e outros códigos, arquivos, scripts, agentes ou programas, que sejam prejudiciais ou mal-intencionados, bem como outros desconhecidos, e que venham a surgir na vigência deste contrato.</li>
      <li><strong>\"Aplicativos Não Fornecidos pela HELP-DESK.app\"</strong>: significa aplicativos online e softwares off-line fornecidos por entidades ou indivíduos que não a HELP-DESK.app.</li>
      <li><strong>\"Formulário de Pedido\"</strong>: significa os documentos formulados periodicamente sob a vigência deste contrato e firmados entre o LICENCIADO e a HELP-DESK.app ou quaisquer de suas Afiliadas, incluindo adendos e aditamentos. Pela assinatura do Formulário de Pedido ora designado, as Afiliadas concordam em vincular-se a este Contrato como se partes originais fossem. Os Formulários de Pedido são considerados parte integrante deste.</li>
      <li><strong>\"Plataforma\", \"Saas\" ou \"Sistema\"</strong>: significam os produtos e a plataforma solicitados pelo LICENCIADO por meio do Formulário de Pedido e disponibilizados pela HELP-DESK.app por meio de acesso do LICENCIADO via link <a href=\"https://www.help-desk.app\">https://www.help-desk.app</a> e/ou outros endereços eletrônicos determinados pela HELP-DESK.app. <em>\"Plataforma\" exclui Aplicativos não fornecidos pela HELP-DESK.app.</em></li>
      <li><strong>\"Usuários\"</strong>: significam os atendentes autorizados pelo LICENCIADO a utilizar a plataforma, para quem tenha solicitado acesso à plataforma por meio do cadastro de conta de usuário e aqueles que receberem identificação de usuário e senha do LICENCIADO (ou da HELP-DESK.app mediante solicitação do LICENCIADO). Usuários poderão ser, sem limitar-se, empregados, consultores, contratados e agentes do LICENCIADO, bem como terceiros com quem o LICENCIADO venha a contratar, ficando a responsabilidade, nestes casos, por conta exclusiva da LICENCIADO.</li>
      <li><strong>\"Violação\"</strong>: significa a violação de segurança que leva à destruição acidental ou ilegal, perda, alteração, divulgação não autorizada ou acesso a Dados Pessoais e/ou Dados Pessoais Sensíveis transmitidos, armazenados ou tratados de outra forma.</li>
      <li><strong>\"API\"</strong>: Application Programming Interface que em português significa Interface de Programação de Aplicativos. É um conjunto de rotinas e padrões de programação para acesso a um aplicativo de software ou plataforma baseado na Web.</li>
    </ul>

    <h2>3. Cadastro de Usuários</h2>
    <p><strong>3.1</strong> Para utilizar as funcionalidades disponibilizadas pela Plataforma, o LICENCIADO deve fornecer informações específicas a respeito de si e/ou da pessoa física ou jurídica que representa. Ao firmar este contrato, o LICENCIADO declara que leu e está ciente acerca dos TERMOS DE USO do SOFTWARE, disponíveis em: <a href=\"https://www.help-desk.app/pt-br/termos-de-uso\">https://www.help-desk.app/pt-br/termos-de-uso</a> e garante que forneceu e sempre fornecerá à HELP-DESK.app informações exatas, atualizadas e verdadeiras. O LICENCIADO declara-se ciente de que a utilização de dados falsos, desatualizados, inválidos, incorretos ou de terceiros poderá acarretar o término antecipado da vigência deste CONTRATO, com o consequente impedimento da utilização da Plataforma, sem prejuízo de responsabilização civil e criminal do LICENCIADO, bem como do pagamento de indenização por eventuais perdas e danos sofridos pela HELP-DESK.app.</p>
    <p><strong>3.1.1</strong> O LICENCIADO obriga-se a atender prontamente quaisquer solicitações referentes à atualização cadastral, sob pena de suspensão ou cancelamento de seu cadastro, sem que tal suspensão ou cancelamento gere ao LICENCIADO qualquer direito de indenização ou reembolso, estando ciente de que esta é uma medida relacionada à segurança da informação.</p>
    <p><strong>3.1.2</strong> O LICENCIADO reconhece e aceita que nas hipóteses de (a) A HELP-DESK.app constatar a existência de informações cadastrais incorretas, inverídicas, desatualizadas ou inexatas, e/ou de (b) o LICENCIADO não disponibilizar informações e/ou documentos eventualmente requeridos pela HELP-DESK.app, esta poderá, a seu critério exclusivo e discricionário, bloquear por tempo indeterminado o cadastro do LICENCIADO, tratando-se de medida relacionada à segurança da informação. Em decorrência do bloqueio cadastral, o LICENCIADO estará temporariamente impedido de acessar e utilizar as funcionalidades da Plataforma, sem que nenhuma responsabilidade possa ser imputada à HELP-DESK.app, até que resolvidas as inconsistências, pelo LICENCIADO.</p>
    <p><strong>3.2</strong> Ao contratar esta prestação de serviços, o LICENCIADO declara não ter nenhum impedimento, inexistindo contrato ou vínculo de qualquer natureza com terceiros ou qualquer decisão ou restrição imposta por uma autoridade governamental ou judiciária que de qualquer forma o impeça de aderir a este instrumento. O LICENCIADO declara possuir capacidade jurídica para contratação da Plataforma. Caso o LICENCIADO venha a realizar contratação em nome de pessoa física ou jurídica para a qual não possua capacidade de representação, o LICENCIADO estará pessoalmente responsável às obrigações deste instrumento, incluindo o pagamento dos serviços, sem prejuízo de sua responsabilização por eventuais perdas e danos, bem como no caso de eventual apuração criminal.</p>
    <p><strong>3.3</strong> O LICENCIADO é responsável por manter em sigilo as credenciais de acesso à Plataforma (e-mail de usuário, senha, entre outros). Caso o acesso à Plataforma seja efetuado por terceiros em razão de conduta imputável ao LICENCIADO em relação aos seus dados de acesso à Plataforma, a HELP-DESK.app fica isenta de qualquer responsabilidade relacionada à privacidade e segurança das informações e arquivos do LICENCIADO.</p>
    <p><strong>3.3.1</strong> O LICENCIADO poderá criar diferentes perfis na Plataforma para que seus colaboradores possam usar a Plataforma, tendo os perfis acesso a diferentes funcionalidades a depender das configurações escolhidas pelo LICENCIADO. O LICENCIADO será o único e exclusivo responsável por toda a atividade realizada na Plataforma por meio da utilização dos perfis que criou, devendo informar, orientar e fazer com que seus colaboradores observem todas as regras previstas neste contrato e respectivos Termos de Uso. O LICENCIADO, ainda, fica responsável em caso de desligamento de seus funcionários, realizar a imediata exclusão ou inativação do cadastro do mesmo, sob pena de sua responsabilidade no caso de eventual incidente de dados.</p>
    <p><strong>3.4</strong> O LICENCIADO declara que o e-mail, telefone e endereço físico informados à HELP-DESK.app por meio da Plataforma são meios igualmente eficazes, válidos e suficientes para recebimento de comunicados relacionados à sua conta, bem como para informações de cancelamento ou suspensão de uso da Plataforma. O LICENCIADO reconhece e autoriza também a utilização da página inicial de acesso à Plataforma como meio eficaz, válido e suficiente para a divulgação de assuntos relacionados à contratação, inclusive em relação a eventual reajuste de preços ou alteração de funcionalidades e dos limites de armazenamento.</p>

    <h2>4. Plataforma e Responsabilidades</h2>
    <p><strong>4.1</strong> O LICENCIADO se obriga a utilizar a Plataforma de boa-fé e sem a intenção de praticar atos ilícitos ou causar danos a terceiros. Sem prejuízo ou limitação às demais obrigações e restrições previstas neste Termos de Uso e na legislação pertinente, o LICENCIADO obriga-se a não praticar e a fazer com que quaisquer pessoas autorizadas que tenham acesso à Plataforma não pratiquem nenhuma das seguintes condutas:</p>
    <ul>
      <li>a) distribuir, reproduzir, modificar, vender, alugar ou de qualquer forma explorar economicamente a Plataforma, seus dados, informações, código-fonte, marcas, direitos autorais relacionados, ou qualquer outro conteúdo veiculado pela HELP-DESK.app, exceto se com a autorização prévia e por escrito da HELP-DESK.app;</li>
      <li>b) realizar quaisquer obras derivadas ou traduções da Plataforma;</li>
      <li>c) decifrar, decodificar, desmontar, realizar engenharia reversa, ou tentar deduzir qualquer parte do código-fonte, ideia subjacente ou algoritmos relacionados à Plataforma;</li>
      <li>d) investigar ou testar a vulnerabilidade de qualquer sistema ou rede relacionada à Plataforma;</li>
      <li>e) acessar, adulterar ou usar áreas não públicas da Plataforma ou quaisquer áreas compartilhadas para as quais o LICENCIADO não possui autorização de acesso;</li>
      <li>f) causar danos, interferir ou perturbar outros LICENCIADOS e/ou servidores de hospedagem ou rede;</li>
      <li>g) corromper, danificar, limitar ou interferir na função apropriada e normal de softwares, hardwares, ferramentas ou equipamentos relacionados à Plataforma;</li>
      <li>h) obter acesso não autorizado a sistemas, informações, senhas, ou outros dados da HELP-DESK.app e/ou de quaisquer Usuários ou terceiros;</li>
      <li>i) interferir no funcionamento normal da Plataforma, incluindo por meio de envio ou transmissão de qualquer arquivo malicioso que contenha vírus, worms, cavalos de Tróia ou qualquer outra espécie de programação ou comando que possa contaminar, destruir ou interferir no bom funcionamento da Plataforma;</li>
      <li>j) violar ou contornar medidas de segurança e/ou autenticação da HELP-DESK.app para restringir ou prevenir o acesso a ferramentas, contas, sistemas e redes relacionadas à Plataforma; e</li>
      <li>k) utilizar os serviços contratados para fins de infração à legislação brasileira, para fins de causar danos a terceiros ou para fins de armazenar documentos que estejam relacionados a ilícitos cíveis, administrativos, fiscais ou penais.</li>
    </ul>
    <p><strong>4.2</strong> A Plataforma é disponibilizada através da rede mundial de computadores, sendo de inteira responsabilidade do LICENCIADO o dispositivo pelo qual realiza o acesso, bem como sua conexão própria com a internet, englobando, provedor de acesso, modem, e demais intermediários envolvidos na conexão particular do LICENCIADO. A HELP-DESK.app não possui qualquer obrigação sobre os dispositivos e conexões particulares do LICENCIADO. O LICENCIADO assume a obrigação de não acessar a Plataforma através de conexão indireta aos servidores da HELP-DESK.app, não podendo fazer uso de tecnologias de proxy ou virtual private network (VPN) ou qualquer outra tecnologia que impeça o completo conhecimento por parte da HELP-DESK.app do número de IP (Internet Protocol) utilizado na conexão, ou que mostre número de IP diferente do computador de origem do acesso, sob pena de cancelamento do Plano contratado por violação às regras de segurança dos servidores da HELP-DESK.app.</p>
    <p><strong>4.2.1</strong> O LICENCIADO é responsável por realizar o acesso à Plataforma apenas em computadores seguros e privados, que estejam livres de vírus e outros softwares maliciosos, eximindo a HELP-DESK.app de qualquer dano ou indenização.</p>
    <p><strong>4.3</strong> A HELP-DESK.app deverá disponibilizar a plataforma nos termos do presente Contrato e dos Formulários de Pedido aplicáveis, durante cada período de contratação. O LICENCIADO declara concordar que a contratação da plataforma HELP-DESK.app não contém ou garante futuras funcionalidades ou características da plataforma, independentemente de notas orais ou escritas emitidas por ela.</p>
    <p><strong>4.4</strong> Salvo se de outro modo disposto no respectivo Formulário de Pedido, (i) os Serviços SaaS adquiridos como \"conta/perfil de usuário adicional\" não poderão ser acessados por mais usuários do que o especificado; (ii) Contas/Perfis de usuários adicionais poderão ser contratados durante a vigência deste CONTRATO DE LICENCIAMENTO E PRESTAÇÃO DE SERVIÇO de Licenciamento de uso de Software, calculado proporcionalmente ao período restante da vigência; (iii) o cadastro de Contas/Perfis de usuários adicionais vigorará até a data de término da validade do cadastro principal pré-existente; (iv) o LICENCIADO poderá adquirir a conta/perfil de usuário adicional diretamente através do Sistema ou via solicitação à HELP-DESK.app, estando ela autorizada a cobrar pela adição desta nova Conta/Perfil de Usuário a partir de sua liberação; e (v) em qualquer hipótese da contratação de contas/perfis de usuários adicionais, fica dispensada a formalização por aditivo a este contrato. O cadastro da Conta/perfil de usuário adicional \"X\" será destinado exclusivamente ao usuário \"X\" designado e não poderá ser utilizado por mais de um Usuário; entretanto, poderá ser designada para novo Usuário distinto, substituindo o anterior \"X\" que não mais necessite utilizar a plataforma.</p>
    <p><strong>4.5</strong> A HELP-DESK.app deverá: (i) fornecer ao LICENCIADO suporte da plataforma (leia-se suporte a falhas) sem qualquer custo adicional, conforme Termos de Uso, item 9; (ii) fornecer os Serviços SaaS em consonância com as leis e regulamentos aplicáveis.</p>
    <p><strong>4.6</strong> As Partes declaram que irão tratar dados pessoais em conformidade com a Lei Geral de Proteção de Dados (Lei 13.709/18) e outras leis aplicáveis às atividades das Partes relacionadas à proteção de dados e privacidade e garantir que seus empregados, agentes e subcontratados também o façam. As Partes garantem que os dados pessoais eventualmente compartilhados no âmbito deste Contrato foram obtidos legalmente, com fundamento no artigo 7º, V, da LGPD e conforme os requisitos da referida legislação (Lei 13.709/18), possuindo o direito de tratá-los e de compartilhá-los com a outra Parte. Para mais informações, acesse nossa <a href=\"https://www.help-desk.app/pt-br/privacidade\">Política de Privacidade</a> parte integrante deste instrumento.</p>
    <p><strong>4.7</strong> O LICENCIADO deverá: (i) responsabilizar-se pelo cumprimento do presente instrumento por parte dos Usuários; (ii) responsabilizar-se pela precisão, qualidade e legalidade dos seus dados, bem como pela legalidade da forma como tais dados foram adquiridos e tratados, inclusive quando da transferência para a HELP-DESK.app, a qual, conforme sua política de privacidade não procede ao tratamento de dados pessoais sensíveis; (iii) utilizar esforço comercial razoável para prevenir acessos/utilização não autorizados da plataforma, e notificar a HELP-DESK.app imediatamente na hipótese de qualquer uso não autorizado;</p>
    <p><strong>4.8</strong> O LICENCIADO não deverá: (i) disponibilizar a plataforma para terceiros que não os Usuários; (ii) vender, revender, alugar ou arrendar a plataforma; (iii) utilizar a plataforma para armazenar ou transmitir materiais de conteúdo prejudicial, difamatório ou de qualquer modo ilegal ou desonesto, ou ainda armazenar ou transmitir materiais que violem os direitos de privacidade de qualquer terceiro; (iv) utilizar a plataforma para transmitir códigos mal-intencionados; (v) interferir ou romper a integridade ou performance da plataforma ou dados de terceiros nestes contidos; ou (vi) empenhar-se em obter acesso não autorizado à plataforma ou ainda aos seus respectivos sistemas e redes; (vii) praticar quaisquer dos atos vedados na cláusula 4.1 supra.</p>
    <p><strong>4.9</strong> O LICENCIADO, além dos serviços SaaS previstos em cláusulas anteriores, poderá contratar serviços de Treinamento, com escopo apresentado em proposta específica. (i) Para contratar os serviços de Treinamento previstos nesta cláusula, o LICENCIADO deverá aceitar os termos e condições previstos no Formulário de Pedido; e (ii) os serviços de Treinamento serão cobrados em conformidade com o respectivo Formulário de Pedido mencionado nos itens anteriores e de acordo com o pacote escolhido no momento da contratação. Na hipótese de o LICENCIADO optar por contratar os serviços aqui previstos com base na previsão de número de horas técnicas, estas, quando não utilizadas em sua totalidade, não serão restituídas, considerando o investimento feito para a prestação do serviço, e não poderão ser utilizadas em contratações futuras desta natureza ou mesmo para outros serviços Saas previstos em cláusulas anteriores.</p>

    <h2>5. Disponibilidade e Manutenção da Plataforma</h2>
    <p><strong>5.1</strong> A HELP-DESK.app envidará os seus melhores esforços para que a Plataforma opere continuamente e para que o sistema esteja disponível no regime de 24x7x365 (24 horas por dia, 7 dias por semana, 365 dias por ano) por no mínimo 90% (noventa por cento) do tempo de operação de cada mês.</p>
    <p><strong>5.1.1</strong> Os períodos de funcionamento afetados por manutenções programadas, manutenções preventivas, falta de energia elétrica, interrupção ou suspensão da prestação dos serviços de telecomunicações, ocorrências de falhas no sistema de transmissão, atualizações do sistema, ou casos fortuitos e de força maior, estarão excluídos do tempo de operação e não configurarão qualquer tipo de responsabilidade ou obrigação de indenizar. Em caso de manutenções urgentes, a HELP-DESK.app envidará seus melhores esforços para comunicar os LICENCIADOS a respeito da possível suspensão ou interrupção dos serviços com antecedência razoável para não prejudicar as atividades do LICENCIADO.</p>
    <p><strong>5.2</strong> Assistência técnica. A HELP-DESK.app oferecerá aos Usuários assistência técnica relacionada a falhas e defeitos do funcionamento da Plataforma, mas não será responsável pela operação individual dos dispositivos do LICENCIADO.</p>
    <p><strong>5.3</strong> A HELP-DESK.app se reserva o direito de suspender o acesso do LICENCIADO à Plataforma sem notificação prévia caso seja verificado mau uso, causado por volume muito acima de médias ou operações consideradas inapropriadas para o bom uso e segurança da Plataforma.</p>
    <p><strong>5.4</strong> A HELP-DESK.app poderá suspender, interromper ou descontinuar a Plataforma, a qualquer tempo, a seu critério e conforme lhe convier, ainda que fora das hipóteses previstas neste contrato, mediante aviso prévio com prazo razoável aos LICENCIADOS, sem que isso configure nenhum tipo de responsabilidade para ela.</p>

    <h2>6. Propriedade Intelectual</h2>
    <p><strong>6.1</strong> O LICENCIADO reconhece e aceita que este CONTRATO e a utilização da Plataforma não lhe conferem nenhum direito, incluindo, mas não se limitando a direitos autorais e/ou direitos de propriedade industrial sobre a Plataforma e/ou qualquer funcionalidade ou serviço disponibilizado por meio da Plataforma, bem como sobre todos e quaisquer materiais e conteúdo de qualquer natureza que digam respeito e/ou estejam relacionados à Plataforma ou à HELP-DESK.app, seus parceiros comerciais e subcontratados, incluindo marcas, sinais distintivos, logotipos, imagens, sons, fotografias, documentos, textos ou quaisquer outros elementos disponibilizados na Plataforma. O LICENCIADO reconhece e aceita ainda que está proibido de fazer uso ou de permitir que terceiros façam uso de tais materiais, conteúdos e de quaisquer direitos autorais e/ou direitos de propriedade industrial de titularidade da HELP-DESK.app ou de qualquer terceiro que tenha licenciado tais direitos à HELP-DESK.app, sem prévia e expressa autorização do respectivo titular. Por consequência, o LICENCIADO declara-se ciente de que o uso indevido e a reprodução total ou parcial dos referidos conteúdos e ferramentas são proibidos, salvo mediante autorização prévia e expressa do respectivo titular.</p>
    <p><strong>6.2</strong> O LICENCIADO entende e aceita que a autorização de uso da Plataforma e de suas funcionalidades e ferramentas não poderá ser compreendida como cessão ou atribuição de direitos da HELP-DESK.app ao LICENCIADO, mas apenas como autorização de uso precário da Plataforma, bem como de suas funcionalidades e ferramentas.</p>
    <p><strong>6.3</strong> É vedado ao LICENCIADO utilizar quaisquer dispositivos, softwares, e/ou quaisquer outros recursos que possam interferir nas atividades e/ou operações da Plataforma. Qualquer interferência, tentativa de interferência, ou qualquer outra conduta que viole ou contrarie as normas vigentes de direitos autorais e/ou propriedade industrial e/ou as proibições existentes neste contrato, poderá acarretar a rescisão deste, suspensão do cadastro do LICENCIADO e do uso, por tempo indeterminado ou ainda seu cancelamento, sem prejuízo de responsabilização cível e criminal do LICENCIADO, bem como do pagamento de indenização por perdas e danos eventualmente incorridos, além da não devolução dos valores eventualmente já pagos em virtude do inadimplemento contratual pelo LICENCIADO.</p>
    <p><strong>6.4</strong> Responsabilidade sobre informações e conteúdos inseridos na Plataforma. O LICENCIADO é responsável por todas as informações enviadas por si ou por meio de seus colaboradores e representantes aos servidores da HELP-DESK.app através do acesso e uso da Plataforma, assim como por qualquer conteúdo inserido nela, responsabilizando-se integralmente e ilimitadamente por quaisquer violações legais a direitos de terceiros, incluindo direitos de propriedade intelectual.</p>

    <h2>7. Indenidade e Limitação de Responsabilidade</h2>
    <p><strong>7.1</strong> Os LICENCIADOS obrigam-se a indenizar e a manter a HELP-DESK.app, seus sócios, administradores, colaboradores e contratados livres e totalmente indenes por quaisquer gastos, perdas, despesas, danos, reclamações e/ou reivindicações incorridos ou sofridos com relação ou em decorrência de qualquer violação deste contrato por parte do LICENCIADO, de seus sócios, administradores e/ou colaboradores. Quaisquer valores devidos aos LICENCIADOS pela HELP-DESK.app poderão ser retidos e compensados para o pagamento de débitos decorrentes de condenações, custas e despesas processuais, além de honorários advocatícios.</p>
    <p><strong>7.2</strong> A Parte Receptora (HELP-DESK.app) deverá utilizar-se do mesmo nível de cuidado que utiliza para proteger suas próprias informações confidenciais, tal como (que em nenhuma hipótese deverá ser menos do que um cuidado razoável) (i) não usar qualquer informação confidencial da Parte Reveladora (LICENCIADO) para qualquer propósito fora do escopo deste contrato, (ii) salvo se expressamente autorizado pela Parte Reveladora por escrito, limitar o acesso das Informações Confidenciais da Parte Reveladora para seus empregados e de suas afiliadas, contratados e agentes, que necessitem de tal acesso para executar o objeto deste Contrato, e que tenham firmado os respectivos termos de confidencialidade com a Parte Receptora que contenha previsões não menos rígidas do que as contidas no presente instrumento. Nenhuma das partes deverá revelar os termos deste contrato ou de qualquer Formulário de Pedido a qualquer terceiro além de suas afiliadas, conselheiros legais e contadores, sem o prévio e expresso consentimento da outra.</p>
    <p><strong>7.3</strong> Os Usuários reconhecem e aceitam que a HELP-DESK.app não é e não será responsável:</p>
    <ul>
      <li>a) por qualquer acesso indevido, atividade suspeita na conta do LICENCIADO ou, ainda, pelas eventuais consequências e danos decorrentes da falha do LICENCIADO em manter a confidencialidade da senha e/ou da falta ou atraso na comunicação à HELP-DESK.app referente a eventual acesso indevido ou uso não autorizado da senha do LICENCIADO por terceiros;</li>
      <li>b) por quaisquer atos ou fatos decorrentes de força maior ou caso fortuito, incluindo, mas não se limitando, àqueles casos em que o cumprimento de obrigações aqui previstas se tornar inviável por consequência direta ou indireta de suspensão de serviços de terceiros (como eletricidade, telefonia ou internet), incêndios, tempestade, lei, regulamento, greves, pandemias, insurreição ou revolta, guerra, ou qualquer outra causa que for inevitável ou além do controle razoável da HELP-DESK.app;</li>
      <li>c) por qualquer dano sofrido pelo LICENCIADO em virtude da remoção unilateral de quaisquer dados e informações que inseriu na Plataforma, do cancelamento do seu cadastro, bem como da preservação ou fornecimento de informações a seu respeito, incluindo aquelas relativas à sua identificação, localização e registros de conexão e/ou de acesso à Plataforma, desde que em cumprimento deste Contrato, de seus Termos de Uso, da Política de Privacidade e/ou de ordens judiciais ou administrativas;</li>
      <li>d) por eventual modificação, suspensão, interrupção ou descontinuação da Plataforma;</li>
      <li>e) por qualquer declaração ou garantia, expressa ou implícita ou realizada através de negociações com os LICENCIADOS, incluindo, mas sem limitação: (i) à garantia implícita de adequação a um determinado fim ou à não-violação de direitos de terceiros; e (ii) a qualquer garantia com relação à qualidade, precisão, eficácia, tempestividade ou integralidade dos serviços oferecidos a partir da Plataforma, ou de que o acesso do LICENCIADO ou o uso pelo LICENCIADO dos serviços da Plataforma serão livres de erros, de vírus, de falhas, ininterruptos ou atenderão a todos os requisitos do LICENCIADO;</li>
      <li>f) por pagamentos e/ou recebimentos errôneos e/ou em atraso realizados ou recebidos pelo LICENCIADO em relação a fornecedores, LICENCIADOS, funcionários, agentes, ou qualquer outra pessoa, cabendo ao LICENCIADO conferir os valores apontados pela Plataforma, sendo de sua responsabilidade apurar e confirmar os valores corretos e realizar verificações periódicas nos cálculos e lançamentos informados pelo sistema para fins de verificar a sua correção. Os pagamentos e recebimentos realizados pelo LICENCIADO, sem a devida conferência, não serão de responsabilidade da HELP-DESK.app;</li>
      <li>g) por eventuais prejuízos decorrentes de lucros cessantes, dano moral, danos à imagem, perda de oportunidade comercial e quebra de expectativa;</li>
      <li>h) por qualquer ato, fato ou evento que não acarrete ao LICENCIADO o dever de realizar um desembolso efetivo de recursos.</li>
    </ul>
    <p><strong>7.4</strong> É de responsabilidade única e exclusiva do LICENCIADO verificar, atualizar e adaptar o sistema, através das parametrizações necessárias, para seu ramo de atividade e porte de companhia.</p>
    <p><strong>7.5</strong> Nas hipóteses em que a HELP-DESK.app seja considerada responsável por danos comprovadamente causados pela Plataforma, a indenização devida ao LICENCIADO estará limitada à quantia correspondente ao valor de 3 (três) mensalidades do Plano contratado pelo LICENCIADO na época da violação. Caso o LICENCIADO não esteja de acordo com esta limitação, deverá interromper a utilização da Plataforma imediatamente.</p>

    <h2>8. Preço e pagamento</h2>
    <p><strong>8.1</strong> O LICENCIADO deverá pagar à HELP-DESK.app o valor do plano de licenciamento escolhido e de acordo com a periodicidade definida entre as opções de pagamento disponibilizadas ao LICENCIADO no ato da contratação. O LICENCIADO assina um plano pré-pago, este terá validade estipulada pelo tipo de plano assinado, devendo ser renovado e pago para continuar com acesso às ferramentas do plano assinado (Premium / Enterprise) da HELP-DESK.app. Caso o LICENCIADO, no decorrer da vigência do presente instrumento, opte por outro plano de licenciamento superior (upgrade), os valores serão alterados de acordo com o respectivo plano escolhido. Se o LICENCIADO, no decorrer da vigência do presente instrumento, optar por um plano de licenciamento inferior (downgrade), a alteração de valores será efetuada na próxima renovação, sendo vedada a devolução de valores já pagos.</p>
    <p><strong>8.2</strong> Caso o LICENCIADO não renove a assinatura após 4 (quatro) dias corridos do vencimento do último plano contratado, seus atendentes ativos e departamentos ativos serão temporariamente desativados, ficando ativo apenas o departamento ativo mais antigo e o atendente Proprietário da conta com a HELP-DESK.app, se tornando um plano Basic grátis.</p>
    <p><strong>8.3</strong> O LICENCIADO fica ciente que se vencido suas licenças Premium/Enterprise, sua conta é convertida ao plano Basic, e as funções do sistema são limitadas a apenas ao atendente proprietário da conta e a apenas os recursos do plano Basic, que podem ser visualizados pelo endereço <a href=\"https://www.help-desk.app\">https://www.help-desk.app</a>.</p>
    <p><strong>8.4</strong> A falta de renovação de quaisquer planos Premium/Enterprise nas respectivas datas de vencimento não acarretará na rescisão automática do EULA, mas causará a suspensão do acesso do LICENCIADO ao SOFTWARE com todas os recursos que uma vez haviam em seu plano assinado (Premium/Enterprise), permanecendo até então apenas com o plano Basic. O acesso ao SOFTWARE com todas as permissões do plano Premium/Enterprise somente será reestabelecido após a identificação pela HELP-DESK.app do pagamento de novo plano correspondente (Premium/Enterprise). A identificação poderá ocorrer em até cinco dias úteis após a data de pagamento pelo LICENCIADO.</p>
    <p><strong>8.5</strong> Caso o LICENCIADO não realize a renovação no prazo de 180 (cento e oitenta) dias corridos e também não realize mais o acesso na plataforma, contados do vencimento do valor não pago, a HELP-DESK.app se reserva ao direito de apagar de forma definitiva e irrecuperável todas as informações do LICENCIADO que por ventura estejam armazenadas no SOFTWARE, nos termos da Cláusula 9.</p>
    <p><strong>8.6</strong> Os valores dos planos de licenciamento estabelecidos no ato do licenciamento do SOFTWARE serão atualizados todo o mês de abril de cada ano, de acordo com a IPCA acumulado no período, ou no caso de extinção deste, de outro índice oficial que venha a substituí-lo, ficando desde já ciente o LICENCIADO. Caso isto ocorra o LICENCIADO será informado com no mínimo 7 dias de antecedência da renovação em que ocorrerá o reajuste de preços.</p>
    <p><strong>8.7</strong> A remuneração será faturada antecipadamente ou conforme disposto no respectivo Formulário de Pedido. Salvo se de outro modo dispuser o Formulário de Pedido, o vencimento líquido será no prazo de no máximo 03 dias contados da data da fatura. O LICENCIADO é responsável por prover informações de faturamento e de contato precisas e completas e notificar a HELP-DESK.app acerca de qualquer alteração das mesmas.</p>
    <p><strong>8.8</strong> O atraso no pagamento de qualquer obrigação prevista neste Termos de Uso sujeitará os valores devidos e não pagos à incidência de juros no valor de 1% ao mês, multa de 2% e correção monetária positiva segundo IPCA/IBGE ou outro que o venha substituir, aplicado pro rata dies desde a data do inadimplemento até o efetivo pagamento dos referidos valores. Adicionalmente, a HELP-DESK.app se reserva o direito de cancelar ou interromper o acesso à Plataforma do LICENCIADO inadimplente, sem a necessidade de prévio aviso. Eventual tolerância da HELP-DESK.app em relação ao LICENCIADO inadimplente não retira ou limita o direito da HELP-DESK.app de suspender e cancelar o acesso do LICENCIADO à Plataforma a qualquer momento.</p>
    <p><strong>8.9</strong> Caso sejam criados novos tributos ou alteradas as condições de cálculo e/ou cobrança de tributos já existentes, vindo a impactar os valores da remuneração vigente, este impacto será repassado ao LICENCIADO, somando-se aos encargos, preços, planos ou serviços previamente contratados, o que será precedido de comunicação prévia de reajuste.</p>
    <p><strong>8.10</strong> Em se tratando de pacotes avulsos baseados em dólar e importação, não há garantia de manutenção do preço em contratações de pacotes adicionais.</p>
    <p><strong>8.11</strong> O LICENCIADO declara-se ciente que a HELP-DESK.app é autorizada pela Meta a disponibilizar os seus produtos através das funcionalidades da Plataforma, sendo certo que esta estabelece, a seu exclusivo critério, as políticas de uso dos produtos, preços praticados, continuidade e descontinuidade de produtos, entre outros. Dessa forma, em caso de quaisquer alterações, incluindo, mas não se limitando ao preço praticado, a HELP-DESK.app realizará o imediato repasse das alterações ao LICENCIADO, independente da periodicidade contratada.</p>

    <h2>9. Restituição das Informações</h2>
    <p><strong>9.1</strong> Conforme descrito na cláusula 8 acima, passados 180 (cento e oitenta) dias da suspensão do acesso do LICENCIADO ao SOFTWARE, todas as INFORMAÇÕES do LICENCIADO, incluindo as INFORMAÇÕES PESSOAIS e INFORMAÇÕES DE CONTA, em poder da LICENCIANTE poderão ser excluídas permanentemente do banco de dados da LICENCIANTE, independentemente de terem sido extraídas ou não pelo LICENCIADO.</p>
    <p><strong>9.2</strong> Não obstante o disposto acima, as informações referentes à data e hora de acesso e ao endereço de protocolo de internet utilizado pelo LICENCIADO para acessar o Site e o SOFTWARE permanecerão armazenadas pela LICENCIANTE por 6 (seis) meses a contar da data de cada acesso realizado, independentemente do término da relação jurídica e comercial entre a LICENCIANTE e o LICENCIADO, em cumprimento ao disposto no Artigo 15 da Lei nº 12.965/2014, podendo ser armazenadas por um período maior de tempo mediante ordem judicial.</p>

    <h2>10. Vigência e término</h2>
    <p><strong>10.1</strong> O presente Contrato entra em vigor na data de seu aceite pelo LICENCIADO e vigorará pelo prazo do Plano contratado pelo LICENCIADO. Este Contrato será automaticamente renovado por iguais períodos caso o LICENCIADO não se manifeste expressamente em contrário, nos mesmos termos e condições, inclusive no que se refere à eventuais penalidades. Este Contrato poderá ser rescindido conforme estabelecido abaixo neste instrumento.</p>
    <p><strong>10.2</strong> O LICENCIADO poderá solicitar devolução total dos valores pagos em até 7 (sete) dias após a data de pagamento de assinaturas, data de pagamento de renovação de assinatura, data de pagamento de mudança de plano e data de pagamento de aquisição extra de licença de atendente. Após este prazo de 7 dias, não haverá devolução dos valores pagos.</p>
    <p><strong>10.3</strong> Para os planos de licenciamento com pagamento antecipado, caso o LICENCIADO decida rescindir este Contrato antes do término do prazo contratado, o HELP-DESK.app NÃO RESTITUIRÁ ao LICENCIADO o saldo restante do plano de licenciamento contratado, ficando este entendido como multa contratual.</p>
    <p><strong>10.4</strong> A HELP-DESK.app poderá rescindir este Contrato a qualquer tempo, desde que comunique ao LICENCIADO, com antecedência mínima de 15 (quinze) dias, devendo neste caso restituir ao LICENCIADO o saldo devedor do plano de licenciamento contratado, se existente. Este prazo de antecedência mínima e a obrigação de restituição acima não se aplicarão nos casos previstos na cláusula 4 acima e nos casos de violação do presente instrumento.</p>
    <p><strong>10.5</strong> A HELP-DESK.app poderá rescindir o contrato a qualquer momento em caso de violação pelo LICENCIADO dos termos e condições ora acordados, ou em caso de atraso de pagamento não sanado no prazo de 90 (noventa) dias, conforme cláusula 9 acima.</p>
    <p><strong>10.6</strong> No caso de rescisão do presente contrato, os dados pessoais e demais informações do LICENCIADO ficarão disponíveis conforme cláusula 9 deste termo, podendo ser excluídos permanentemente após 180 (cento e oitenta) dias da data da rescisão.</p>
    <p><strong>10.7</strong> Em qualquer hipótese de término deste contrato, continuarão a ser aplicáveis as obrigações de proteção de propriedade intelectual do HELP-DESK.app, indenidade e limitação de responsabilidade da HELP-DESK.app, sigilo e obrigação de indenizar eventuais perdas e danos decorrentes do descumprimento deste instrumento ou da legislação aplicável, que sobreviverão pelos prazos prescricionais e/ou decadenciais aplicáveis, ou caso ocorra por último, até que a pretensão em questão seja satisfeita.</p>
    <p><strong>10.8</strong> O disposto no presente capítulo se aplica a contratos de fidelidade apenas quanto ao que não divergir do disposto no capítulo 12 (Especificidades para contratos de Fidelidade).</p>

    <h2>11. Base legal para tratamento de dados: execução do Contrato e proteção de crédito</h2>
    <p><strong>11.1</strong> Diante das características do SOFTWARE, o LICENCIADO declara ter ciência, de modo expresso e informado, de que a coleta e o tratamento de dados pessoais, quando ocorrer, se dará com fundamento na execução deste Contrato, bem como para a proteção do crédito, no que for aplicável.</p>
    <p><strong>11.2</strong> O LICENCIADO, ao utilizar o SOFTWARE, além de ter ciência e estar em conformidade integralmente com este Contrato, fica ciente, de forma livre e expressa, que a HELP-DESK.app colete, use, armazene e faça o tratamento de suas INFORMAÇÕES, incluindo seus dados pessoais, de conta, os quais serão necessários para que o serviço ofertado seja prestado em sua integralidade, sabendo que a base legal para tanto é a execução do contrato de SOFTWARE.</p>
    <p><strong>11.3</strong> Para tanto, o LICENCIADO concorda com o fornecimento dos dados que permitam o acesso às INFORMAÇÕES necessárias para que o SOFTWARE execute todas as funções para as quais foi projetado.</p>
    <p><strong>11.4</strong> O LICENCIADO tem ciência prévia, livre e informada de que, ao acessar o site da HELP-DESK.app, esta poderá coletar informações técnicas de navegação, tais como tipo de navegador do computador utilizado para acesso ao site, endereço de protocolo de Internet, páginas visitadas e tempo médio gasto no site. Tais informações poderão ser usadas para orientar o próprio LICENCIADO e melhorar os serviços ofertados.</p>
    <p><strong>11.5</strong> O LICENCIADO fica ciente de que suas INFORMAÇÕES poderão ser transferidas a terceiros em decorrência da venda, aquisição, fusão, reorganização societária ou qualquer outra mudança no controle da HELP-DESK.app. A HELP-DESK.app, contudo, compromete-se, nestes casos, a informar o LICENCIADO.</p>
    <p><strong>11.6</strong> O LICENCIADO tem ciência acerca da utilização de cookies pela HELP-DESK.app apenas para controlar a audiência e a navegação em seu site e possibilitar a identificação de serviços segmentados e personalizados ao perfil do LICENCIADO. A HELP-DESK.app garante que estas informações coletadas por meio de cookies são estatísticas e não pessoais, bem como que não serão utilizadas para propósitos diversos dos expressamente previstos neste Contrato, comprometendo-se a adotar todas as medidas necessárias a fim de evitar o acesso e o uso de tais informações por quaisquer terceiros, sem a devida autorização.</p>

    <h2>12. Cessão de imagem</h2>
    <p><strong>12.1</strong> Através deste documento, a LICENCIADA autorizará, mediante análise da solicitação detalhada, de forma inteiramente gratuita, sem exclusividade, que a HELP-DESK.app utilize sua imagem para demonstração de cases, trabalhos, etc, já realizados desde que previamente autorizados pela LICENCIADA.</p>
    <p><strong>12.2</strong> Esta cessão permite a divulgação, utilização e disposição, na íntegra ou em partes, para fins informativos e técnicos a imagem, que poderá ser publicada em forma editada ou bruta, com visibilidade nacional e internacionalmente através da internet e outros meios de comunicação, desde que previamente autorizados pela LICENCIADA.</p>
    <p><strong>12.3</strong> A cessão englobará:</p>
    <ul>
      <li>Logo;</li>
      <li>Logotipo;</li>
      <li>Nome;</li>
      <li>Indicação de site;</li>
      <li>Detalhamento do case de trabalho realizado; e/ou</li>
      <li>demais informações públicas e essenciais à boa divulgação do procedimento realizado.</li>
    </ul>
    <p><strong>12.3.1</strong> A HELP-DESK.app não está autorizada a divulgar quaisquer informações sigilosas compartilhadas entre as partes.</p>
    <p><strong>12.3.2</strong> A HELP-DESK.app reserva seu direito de utilizar ou não as imagens cedidas, ao seu próprio critério e necessidade.</p>
    <p><strong>12.3.3</strong> A HELP-DESK.app não se responsabiliza pela apropriação indevida das imagens por parte de terceiros, que venham a fazer uso delas em outros veículos sem o consentimento dos autores da imagem.</p>

    <h2>13. Fornecedores diversos da HELP-DESK.app</h2>
    <p><strong>13.1</strong> A HELP-DESK.app ou terceiros poderão, periodicamente, disponibilizar ao LICENCIADO (p.ex. por meio do <a href=\"http://www.HELP-DESK.app.com\">http://www.HELP-DESK.app.com</a>) produtos e plataforma fornecidos por terceiros, incluindo, mas não se limitando a aplicativos, implementação, customização ou outras plataformas de consultoria. Quaisquer aquisições pelo LICENCIADO de produtos ou plataforma não fornecidos pela HELP-DESK.app, bem como a troca de informações entre o LICENCIADO e os respectivos fornecedores destes produtos e plataforma, dar-se-á exclusivamente entre estes. A HELP-DESK.app não concede garantia ou suporte de produtos ou plataforma fornecidos por terceiros, sejam ou não designados como certificados pela HELP-DESK.app, exceto se especificado no Formulário de Pedido. A HELP-DESK.app ressalta que não se faz necessária a aquisição de qualquer aplicativo fornecido por terceiros para a utilização da plataforma, salvo: dispositivo de informática compatível, sistema operacional, navegador e conexão à internet.</p>
    <p><strong>13.2</strong> Caso o LICENCIADO instale ou habilite aplicativos não fornecidos pela HELP-DESK.app, declara-se ciente de que a HELP-DESK.app poderá permitir aos fornecedores dos referidos aplicativos, acesso aos dados do LICENCIADO, conforme necessário para a operação conjunta e suporte destes em relação à plataforma. A HELP-DESK.app não será responsabilizada por qualquer divulgação, modificação ou exclusão dos dados do LICENCIADO resultantes da concessão de acesso à terceiros fornecedores dos referidos aplicativos. A plataforma deverá permitir ao LICENCIADO que restrinja o acesso aos seus dados, por meio da restrição aos Usuários da instalação ou habilitação de aplicativos não fornecidos pela HELP-DESK.app para uso conjunto com a plataforma.</p>
    <p><strong>13.3</strong> A plataforma poderá conter aspectos desenvolvidos para operar conjuntamente com aplicativos não fornecidos pela HELP-DESK.app (p.ex. aplicativos Instagram, Facebook ou Twilio). Para utilizá-los, poderá ser exigido que o LICENCIADO obtenha acesso aos mesmos por meio de seus respectivos fornecedores. Caso o fornecedor de quaisquer destes aplicativos interrompa a disponibilização do mesmo para operar em conjunto com as referidas particularidades da plataforma em condições razoáveis, a HELP-DESK.app poderá interromper o fornecimento destas características da plataforma, sem conceder ao LICENCIADO qualquer reembolso, crédito ou compensação.</p>

    <h2>14. Especificidades para contratos de Fidelidade</h2>
    <p><strong>14.1</strong> O LICENCIADO declara ter ciência de que em função do recebimento dos benefícios, descritos no REGULAMENTO/FORMULÁRIO DE PEDIDO da oferta HELP-DESK.app, deverá permanecer vinculado ao PLANO DE SERVIÇO contratado durante o prazo de 6 (seis) ou 12 (doze) meses (ou outro período especificado em formulário de pedido) de \"PERMANÊNCIA MÍNIMA\" (conforme selecionado no momento da contratação), contados da ATIVAÇÃO do serviço.</p>
    <p><strong>14.2</strong> Na hipótese de cancelamento do contrato pelo LICENCIADO durante o prazo de PERMANÊNCIA MÍNIMA, deverá ser dirigida à HELP-DESK.app notificação prévia e escrita com 30 (trinta) dias de antecedência, ficando o LICENCIADO obrigado ao pagamento à HELP-DESK.app, de todos os valores devidos pelo período residual deste instrumento, e o não pagamento ensejará o registro do LICENCIADO nos CADASTROS DE PROTEÇÃO AO CRÉDITO, desde já ficando o LICENCIADO ciente e autorizada a sobredita inscrição.</p>
    <p><strong>14.3</strong> Na hipótese de redução ou alteração para plano inferior ao inicialmente contratado durante o prazo de PERMANÊNCIA MÍNIMA, será considerada quebra do vínculo de permanência e o LICENCIADO estará sujeito ao pagamento de multa, conforme CLÁUSULA 14.2 acima.</p>
    <p><strong>14.4</strong> Em caso de transferência de titularidade do Contrato, o futuro LICENCIADO deverá obrigar-se a cumprir todas as estipulações referentes à presente contratação, incluindo o período de PERMANÊNCIA MÍNIMA restante.</p>
    <p><strong>14.5</strong> O LICENCIADO reconhece que lhe foi dada a oportunidade de contratar a plataforma disponibilizada pela HELP-DESK.app sem o prazo de permanência e sem os benefícios vinculados, tendo o LICENCIADO optado por contratar o plano com fidelidade.</p>
    <p><strong>14.6</strong> Aplica-se o disposto nos outros capítulos e cláusulas deste instrumento para contratos de fidelidade, apenas quando não conflitar com o disposto neste capítulo de número 14.</p>

    <h2>15. Administração do Serviço e da Declaração de Responsabilidade</h2>
    <p><strong>15.1</strong> A senha que possibilita o acesso para o conteúdo e para a administração (programação e alterações) do serviço contratado será escolhida e cadastrada pelo LICENCIADO, sem qualquer participação da HELP-DESK.app, sendo de exclusiva responsabilidade do LICENCIADO a definição da política de segurança.</p>
    <p><strong>15.1.1</strong> À opção do LICENCIADO, poderá ele deixar cadastrado, desde a contratação inicial, um endereço secundário de e-mail para recebimento da senha de administração, nos termos acima e que poderá ser utilizado em caso de algum problema ou impedimento do e-mail principal. Em razão dessa possibilidade, entende-se, sempre, para efeito de aplicação das disposições do capítulo 14 do presente contrato que \"endereço eletrônico de e-mail para envio da senha de administração do serviço de e-mail\" será, tanto o endereço principal, quanto o secundário, se este for cadastrado pelo LICENCIADO.</p>
    <p><strong>15.1.2</strong> Apenas o endereço eletrônico de \"e-mail\" cadastrado pelo LICENCIADO receberá a senha de administração e suas eventuais substituições e alterações, sendo ela de sua inteira e total responsabilidade.</p>
    <p><strong>15.1.3</strong> A posse da senha dará a quem a detiver não só amplos poderes de administração do serviço, mas também amplos poderes de alterar eletronicamente a própria senha, ficando o LICENCIADO, assim, ciente de sua responsabilidade pela guarda e conservação da senha.</p>
    <p><strong>15.1.4</strong> A responsabilidade por permitir o acesso à senha a quem quer que seja, corre por conta única e exclusiva da LICENCIADO, uma vez que a HELP-DESK.app não possui qualquer ingerência sobre a disponibilização da utilização da senha inicialmente fornecida.</p>
    <p><strong>15.2</strong> Em caso de disputa pela posse da senha de administração do serviço, o acesso à mesma ficará bloqueado até que os interessados cheguem a um acordo escrito e deem conhecimento do mesmo à HELP-DESK.app.</p>

    <h2>16. Confidencialidade</h2>
    <p><strong>16.1</strong> Conforme utilizada no presente \"Informação Confidencial\", significa todas as informações confidenciais fornecidas por uma parte (\"Parte Reveladora\") à outra parte (\"Parte Receptora\"), oralmente ou por escrito, que esteja assinalada como confidencial, ou que razoavelmente possa ser compreendida como confidencial em razão de sua natureza e das circunstâncias de sua revelação. As Informações Confidenciais do LICENCIADO deverão abarcar os dados do LICENCIADO; as Informações Confidenciais da HELP-DESK.app deverão abarcar a Plataforma de SaaS; e as Informações Confidenciais de cada parte deverão incluir os termos e condições deste contrato e de todos os Formulários de Pedido, informações técnicas e de tecnologia, projetos de produtos e design, bem como procedimentos comerciais, revelados pela Parte Reveladora. Contudo, as Informações Confidenciais (que não os dados do LICENCIADO) não deverão incluir qualquer informação que (i) for ou tornar-se de domínio público sem o descumprimento de qualquer obrigação para com a Parte Reveladora, (ii) for de conhecimento da Parte Receptora anteriormente à sua divulgação pela Parte Reveladora, sem o descumprimento de qualquer obrigação devida à esta, (iii) for recebida de um terceiro sem o descumprimento de qualquer dever para com a Parte Reveladora, ou (iv) for desenvolvida de forma independente pela Parte Receptora.</p>
    <p><strong>16.2</strong> A Parte Receptora deverá utilizar-se do mesmo nível de cuidado que utiliza para proteger suas próprias informações confidenciais, tal como, salvo se expressamente autorizado pela Parte Reveladora por escrito, limitar o acesso das Informações Confidenciais da Parte Reveladora para seus empregados e de suas afiliadas, contratados e agentes, que necessitem de tal acesso para executar o objeto deste Contrato e que tenham firmado os respectivos termos de confidencialidade com a Parte Receptora que contenha previsões não menos rígidas do que as contidas no presente instrumento. Nenhuma das partes deverá revelar os termos de qualquer Formulário de Pedido a qualquer terceiro, além de suas afiliadas, conselheiros legais e contadores, sem o prévio e expresso consentimento da outra.</p>
    <p><strong>16.3</strong> A Parte Receptora poderá revelar as Informações Confidenciais da Parte Reveladora se for legalmente compelida a fazê-lo. Se a Parte Receptora for compelida por lei a revelar as Informações Confidenciais da Parte Reveladora em razão de processo judicial do qual a Parte Reveladora seja parte, e esta não contestar a divulgação, reembolsará a Parte Receptora pelos custos razoáveis despendidos para compilar e fornecer acesso seguro a tais informações em observância à ordem judicial.</p>

    <h2>17. Disposições Gerais</h2>
    <p><strong>17.1</strong> Caso o LICENCIADO venha a desenvolver um novo módulo ou produto que caracterize cópia, de todo ou em parte, quer seja do dicionário de dados quer seja do programa, será considerado como parte do SOFTWARE fornecido pela HELP-DESK.app, ficando, portanto, sua propriedade incorporada pela HELP-DESK.app e seu uso condicionado a estas cláusulas contratuais;</p>
    <p><strong>17.2</strong> A tolerância de uma parte para com a outra quanto ao descumprimento de qualquer uma das obrigações assumidas neste instrumento não implicará em novação ou renúncia de direito. A parte tolerante poderá, a qualquer tempo, exigir da outra parte o fiel e cabal cumprimento deste instrumento;</p>
    <p><strong>17.3</strong> Não constitui causa de rescisão contratual o não cumprimento das obrigações aqui assumidas em decorrência de fatos que independam da vontade das partes, tais como os que configuram o caso fortuito ou força maior, conforme previsto no artigo 393 do Código Civil Brasileiro;</p>
    <p><strong>17.4</strong> O LICENCIADO concorda que a HELP-DESK.app possa divulgar a celebração deste instrumento para fins comerciais, fazendo menção ao nome e à marca do LICENCIADO em campanhas comerciais, podendo, inclusive, divulgar mensagens enviadas de forma escrita ou oral, por telefone, para uso em sites, jornais, revistas e outras campanhas, enquanto vigorar o presente EULA. O LICENCIADO aceita, ainda, receber comunicações via correio eletrônico sobre treinamentos, parcerias e campanhas relacionadas ao SOFTWARE;</p>
    <p><strong>17.5</strong> Neste ato, o LICENCIADO expressamente autoriza a HELP-DESK.app a colher e utilizar seus dados técnicos e operacionais presentes no SOFTWARE, para fins de estudos e melhorias no SOFTWARE;</p>
    <p><strong>17.6</strong> A HELP-DESK.app poderá, a seu exclusivo critério, a qualquer tempo e sem a necessidade de comunicação prévia ao LICENCIADO:
      <ul>
        <li>a) Encerrar, modificar ou suspender, total ou parcialmente, o acesso do LICENCIADO ao SOFTWARE, quando referido acesso ou cadastro estiver em violação das condições estabelecidas neste Contrato;</li>
        <li>b) Excluir, total ou parcialmente, as informações cadastradas pelo LICENCIADO que não estejam em consonância com as disposições deste Contrato;</li>
        <li>c) Acrescentar, excluir ou modificar o Conteúdo oferecido no site;</li>
        <li>d) Alterar quaisquer termos e condições deste EULA mediante simples comunicação ao LICENCIADO.</li>
      </ul>
    </p>
    <p><strong>17.7</strong> A HELP-DESK.app ainda poderá, a seu exclusivo critério, suspender, modificar ou encerrar as atividades do SOFTWARE, mediante comunicação prévia ao LICENCIADO, com antecedência mínima de 30 (trinta) dias, disponibilizando formas e alternativas de extrair do Site as informações, salvo nas hipóteses de caso fortuito ou força maior.</p>
    <p><strong>17.8</strong> A HELP-DESK.app poderá, por meio de comunicação ao endereço eletrônico indicado pelo LICENCIADO em seu cadastro ou por meio de aviso no Site, definir preços para a oferta de determinados conteúdos e/ou serviços, ainda que inicialmente tais serviços tenham sido ofertados de forma gratuita, sendo a utilização destes, após o referido aviso, considerada como concordância do LICENCIADO com a cobrança de tais preços.</p>
    <p><strong>17.9</strong> Fica certo e entendido pelo LICENCIADO que somente a pessoa cadastrada pelo próprio LICENCIADO como administradora de conta poderá solicitar que as informações do LICENCIADO inseridas no SOFTWARE sejam alteradas ou apagadas. Ainda, o LICENCIADO declara sua ciência de que uma vez alteradas ou apagadas, estas não poderão mais ser recuperadas, ficando a HELP-DESK.app isenta de qualquer responsabilidade por quaisquer perdas ou danos decorrentes deste procedimento solicitado pelo LICENCIADO.</p>
    <p><strong>17.10</strong> No caso de descumprimento, pelo LICENCIADO, de qualquer disposição deste Termos de Uso ou da legislação vigente, a HELP-DESK.app poderá, independentemente de qualquer aviso, notificação ou de qualquer outra formalidade, interromper, suspender ou cancelar, de imediato, temporária ou definitivamente, a conta e o acesso do LICENCIADO à Plataforma, sem prejuízo de quaisquer outros direitos assegurados à HELP-DESK.app por lei, pelo Termos de Uso e/ou pela Política de Privacidade, nada podendo ser reclamado ou cobrado da HELP-DESK.app a esse respeito.</p>
    <p><strong>17.11</strong> Este Termos de Uso não estabelece qualquer forma de associação, franquia, parceria, consórcio, joint-venture, mandato, vínculo empregatício, societário ou solidariedade, tampouco confere às partes autorização para praticar quaisquer atos, representar, contratar ou assumir obrigações em nome da outra parte.</p>
    <p><strong>17.12</strong> O LICENCIADO não poderá ceder ou transferir a terceiros, a qualquer título, no todo ou em parte, os direitos e obrigações decorrentes ou relacionados a este Contrato, salvo se com a expressa e prévia autorização por escrito da HELP-DESK.app. O LICENCIADO poderá ceder este Contrato em sua integralidade (incluindo todos os Formulários de Pedido), em virtude de fusão, aquisição, reorganização societária ou venda de todos ou parte substancial de seus ativos, desde que não envolva concorrente direto da HELP-DESK.app e desde que mediante o envio de documentação comprobatória à HELP-DESK.app e posterior aceite expresso da HELP-DESK.app. Esta, por sua vez, está autorizada a ceder ou transferir a terceiros direitos e obrigações decorrentes ou relacionados a este Termos de Uso sem a necessidade de anuência prévia do LICENCIADO. A medida cabível para a hipótese de violação por uma das partes da presente cláusula deverá ser a critério da parte inocente, a rescisão do presente contrato, mediante notificação prévia à parte infratora.</p>
    <p><strong>17.13</strong> A HELP-DESK.app poderá alterar, a qualquer tempo, este Termos de Uso. Fica estabelecido que as novas versões dos Termos de Uso entrarão em vigor na data de sua publicação, sendo que o LICENCIADO somente poderá continuar a utilizar a Plataforma caso concorde ou não discorde expressamente com as referidas alterações. O LICENCIADO poderá, caso não concorde com a referida nova versão, extinguir o seu cadastro da Plataforma. Nessa hipótese, o vínculo contratual deixará de existir, a critério exclusivo do LICENCIADO. O uso da Plataforma, após ter sido comunicado a respeito das alterações aos Termos de Uso, via e-mail, será considerado como aceite, pelo LICENCIADO, da nova versão dos Termos de Uso.</p>
    <p><strong>17.14</strong> Este Contrato supera, substitui e prevalece sobre qualquer acordo ou contrato prévio, escrito ou verbal, que tenha sido levado a efeito pelas partes com relação aos assuntos aqui contemplados.</p>
    <p><strong>17.15</strong> Este Contrato vincula o LICENCIADO e a HELP-DESK.app ao seu fiel cumprimento, bem como seus sucessores a qualquer título, em caráter irrevogável e irretratável.</p>
    <p><strong>17.16</strong> A HELP-DESK.app e os LICENCIADOS declaram e acordam expressamente que: (a) o não exercício ou o atraso no exercício de qualquer direito que lhes seja assegurado por este instrumento ou por lei não constituirá novação ou renúncia a tal direito, nem prejudicará o eventual exercício do mesmo; (b) a renúncia a qualquer desses direitos somente será válida se formalizada por escrito (incluindo eletronicamente), se e quando tal renúncia não for vedada por lei; e (c) a nulidade ou invalidade de qualquer das cláusulas deste instrumento não prejudicará a validade e eficácia das demais cláusulas. Caso seja decretada a nulidade, anulabilidade ou ineficácia de qualquer das cláusulas deste instrumento.</p>
    <p><strong>17.17</strong> Exceto se previsto expressamente de forma diversa, os tributos devidos direta ou indiretamente em decorrência da execução deste Termos de Uso e do uso da Plataforma, existentes e/ou que venham a ser criados, bem como as respectivas majorações, mudanças de base de cálculo e/ou do período de apuração, reajustes, encargos moratórios e/ou obrigações tributárias acessórias, constituem ônus de responsabilidade do respectivo sujeito passivo da obrigação tributária, conforme definido na legislação vigente.</p>
    <p><strong>17.18</strong> O LICENCIADO declara que não lhe foi dado ou oferecido qualquer propina, suborno, pagamento, presente ou bens de valor por empregados da HELP-DESK.app, ou agentes relacionados a este Contrato. Caso o LICENCIADO tome conhecimento de qualquer violação à presente restrição, deverá utilizar esforços razoáveis para imediatamente notificar a HELP-DESK.app.</p>
    <p><strong>17.19</strong> Em caso de disputas ou desentendimentos a respeito deste Termos de Uso, fica eleito o Foro da Comarca de Curitiba, Estado do Paraná, para dirimir quaisquer dúvidas ou controvérsias, com a exclusão de qualquer outro, por mais privilegiado que seja ou venha a ser.</p>
    <p>Este Contrato considerar-se-á celebrado, vinculando as partes contratantes no momento em que o LICENCIADO concluir o seu cadastro e o procedimento previsto no website <a href=\"https://www.help-desk.app\">https://www.help-desk.app</a>, ou na assinatura física ou eletrônica do primeiro Formulário de Pedido, o que ocorrer primeiro, sendo certo que, assim procedendo, o LICENCIADO declara ter lido e compreendido todos os termos e condições deste Contrato, razão pela qual é recomendável que o LICENCIADO imprima uma cópia deste documento para futura referência.</p>
`
    
  },
  {
    "language": "en-US",
    "content": `<h1>SOFTWARE SERVICE PROVISION AND SUBSCRIPTION AGREEMENT</h1>
    <p>Through this private contract instrument, on one side, Hdapp Sistemas LTDA, a private legal entity registered under the CNPJ number 58.420.116/0001-60, with its headquarters at Rua XV de Novembro, 556, Suite 1406, Centro in Curitiba, PR, ZIP 80020-310, hereinafter simply referred to as \"HELP-DESK.app\", and on the other side, the LICENSEE, as qualified in the electronic registration performed at <a href=\"https://www.help-desk.app/\">https://www.help-desk.app/</a>—which form an integral part of this instrument—hereinafter simply referred to as the LICENSEE, hereby agree as follows:</p>

    <h2>1. Purpose of the Agreement</h2>
    <p>By means of this AGREEMENT, the LICENSEE is subject to the terms and conditions set forth in the SOFTWARE’s Terms of Use, obtaining a revocable, non-exclusive, and non-transferable license to use the SOFTWARE. The LICENSEE shall not use, nor permit the use of, the SOFTWARE for any purpose other than processing its own information or that of legal entities it designates during registration. This license does not grant access to any software other than that originally provided within the SOFTWARE. Under no circumstances shall the LICENSEE have access to the source code of the licensed SOFTWARE, as it is the intellectual property of HELP-DESK.app.</p>

    <h2>2. Definitions</h2>
    <p>The capitalized terms below shall have the following meanings in this instrument:</p>
    <ul>
      <li><strong>\"Affiliate\"</strong>: means any entity that controls, is controlled by, or is under common control with any of the LICENSEE entities. For all purposes, \"control\" means direct or indirect ownership or control of more than 50% of the voting capital.</li>
      <li><strong>\"Licensee\"</strong>: refers to a natural or legal person with full capacity to contract who accesses the HELP-DESK.app SOFTWARE via the website by registering, accepting the terms of this EULA, and using the features offered in accordance with the contracted licensing plan.</li>
      <li><strong>\"Licensee Data\"</strong>: any information provided by the LICENSEE that identifies or can be used to identify them, such as name, address, date of birth, telephone number, fax, email address, identification document numbers, etc.</li>
      <li><strong>\"Information\"</strong>: all information provided by the LICENSEE concerning PERSONAL INFORMATION, TICKET INFORMATION, REGISTRATION INFORMATION, CHAT INFORMATION, and ANY OTHER INFORMATION entered into the HELP-DESK.app system.</li>
      <li><strong>\"Malicious Code\"</strong>: means viruses, viruses classified as \"Worms\", logic bombs, Trojan horses, and other codes, files, scripts, agents, or programs that are harmful or malicious, as well as any other unknown codes that may emerge during the term of this Agreement.</li>
      <li><strong>\"Third-Party Applications\"</strong>: means online applications and offline software provided by entities or individuals other than HELP-DESK.app.</li>
      <li><strong>\"Order Form\"</strong>: refers to the documents periodically executed during the term of this Agreement and signed between the LICENSEE and HELP-DESK.app or any of its Affiliates, including any amendments or addenda. By signing the designated Order Form, the Affiliates agree to be bound by this Agreement as if they were original parties. The Order Forms are considered an integral part of this instrument.</li>
      <li><strong>\"Platform\", \"SaaS\", or \"System\"</strong>: means the products and the platform requested by the LICENSEE via the Order Form and provided by HELP-DESK.app through the LICENSEE’s access via the link <a href=\"https://www.help-desk.app\">https://www.help-desk.app</a> and/or other web addresses determined by HELP-DESK.app. <em>\"Platform\" excludes Applications not provided by HELP-DESK.app.</em></li>
      <li><strong>\"Users\"</strong>: means the agents authorized by the LICENSEE to use the platform—those for whom access has been requested via user account registration and those who are provided with a user ID and password by the LICENSEE (or by HELP-DESK.app upon the LICENSEE’s request). Users may include, without limitation, employees, consultants, contractors, and agents of the LICENSEE, as well as any third parties contracted by the LICENSEE, with all responsibility resting solely on the LICENSEE.</li>
      <li><strong>\"Breach\"</strong>: means any security violation that results in accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access to Personal Data and/or Sensitive Personal Data transmitted, stored, or otherwise processed.</li>
      <li><strong>\"API\"</strong>: stands for Application Programming Interface, which is a set of routines and programming standards for accessing a web-based software application or platform.</li>
    </ul>

    <h2>3. User Registration</h2>
    <p><strong>3.1</strong> To use the features provided by the Platform, the LICENSEE must supply specific information about themselves and/or the natural or legal person they represent. By entering into this Agreement, the LICENSEE represents that they have read and are aware of the SOFTWARE’S Terms of Use, available at <a href=\"https://www.help-desk.app/en/termos-de-uso\">https://www.help-desk.app/en/termos-de-uso</a>, and guarantees that they have provided—and will continue to provide—HELP-DESK.app with accurate, up-to-date, and truthful information. The LICENSEE acknowledges that the use of false, outdated, invalid, or third-party data may result in the premature termination of this Agreement and the consequent prohibition on using the Platform, without prejudice to the LICENSEE’s civil or criminal liability and the obligation to compensate for any losses and damages incurred by HELP-DESK.app.</p>
    <p><strong>3.1.1</strong> The LICENSEE shall promptly comply with any requests for updating registration data, under penalty of suspension or cancellation of their registration, without entitling the LICENSEE to any compensation or refund, being aware that this measure is related to information security.</p>
    <p><strong>3.1.2</strong> The LICENSEE acknowledges and agrees that if (a) HELP-DESK.app detects that the registration information is incorrect, false, outdated, or inaccurate, and/or (b) the LICENSEE fails to provide any information or documents that HELP-DESK.app may request, HELP-DESK.app may, at its sole discretion, indefinitely block the LICENSEE’s registration. In the event of such a block, the LICENSEE will be temporarily prevented from accessing and using the Platform until the inconsistencies are resolved.</p>
    <p><strong>3.2</strong> By contracting these services, the LICENSEE represents that there is no impediment, contractual relationship, or restriction imposed by any governmental or judicial authority that would prevent them from entering into this Agreement. The LICENSEE declares that they have the legal capacity to contract the Platform. Should the LICENSEE contract on behalf of a person or legal entity for which they lack representation authority, the LICENSEE shall be personally liable for all obligations under this instrument, including payment for the services, without prejudice to liability for any losses and damages, as well as any potential criminal consequences.</p>
    <p><strong>3.3</strong> The LICENSEE is responsible for maintaining the confidentiality of the access credentials to the Platform (user email, password, etc.). Should third parties gain access to the Platform due to actions attributable to the LICENSEE regarding their credentials, HELP-DESK.app shall not be held responsible for any issues related to the privacy and security of the LICENSEE’s information and files.</p>
    <p><strong>3.3.1</strong> The LICENSEE may create different profiles on the Platform for their employees, with each profile having access to different features depending on the settings chosen by the LICENSEE. The LICENSEE will be solely responsible for all activity on the Platform carried out through these profiles and must ensure that their employees adhere to all rules set forth in this Agreement and the corresponding Terms of Use. Additionally, the LICENSEE is responsible for promptly deleting or deactivating an employee’s registration upon termination, under penalty of liability for any data incidents.</p>
    <p><strong>3.4</strong> The LICENSEE declares that the email, telephone, and physical address provided to HELP-DESK.app via the Platform are equally effective, valid, and sufficient for receiving communications related to their account, as well as notifications regarding cancellation or suspension of Platform use. The LICENSEE also acknowledges and authorizes the use of the Platform’s home page as an effective means for disseminating information related to the contract, including any price adjustments or changes in functionalities and storage limits.</p>

    <h2>4. Platform and Responsibilities</h2>
    <p><strong>4.1</strong> The LICENSEE agrees to use the Platform in good faith and without the intention of engaging in unlawful activities or causing harm to third parties. Without prejudice to the other obligations and restrictions set forth in these Terms of Use and applicable law, the LICENSEE undertakes not to engage in, nor allow any authorized person to engage in, any of the following actions:</p>
    <ul>
      <li>a) Distribute, reproduce, modify, sell, rent, or otherwise commercially exploit the Platform, its data, information, source code, trademarks, related copyrights, or any other content provided by HELP-DESK.app, except with the prior written consent of HELP-DESK.app;</li>
      <li>b) Create derivative works or translations of the Platform;</li>
      <li>c) Decipher, decode, disassemble, reverse engineer, or attempt to deduce any part of the source code, underlying ideas, or algorithms related to the Platform;</li>
      <li>d) Investigate or test the vulnerability of any system or network related to the Platform;</li>
      <li>e) Access, alter, or use non-public areas of the Platform or any shared areas for which the LICENSEE does not have authorized access;</li>
      <li>f) Cause damage, interfere with, or disrupt other LICENSEEs and/or hosting servers or networks;</li>
      <li>g) Corrupt, damage, limit, or interfere with the proper and normal functioning of software, hardware, tools, or equipment related to the Platform;</li>
      <li>h) Obtain unauthorized access to systems, information, passwords, or other data belonging to HELP-DESK.app and/or any Users or third parties;</li>
      <li>i) Interfere with the normal operation of the Platform, including by sending or transmitting any malicious file containing viruses, worms, Trojan horses, or any other type of code or command that could contaminate, destroy, or disrupt the proper functioning of the Platform;</li>
      <li>j) Circumvent or violate the security and/or authentication measures of HELP-DESK.app designed to restrict or prevent access to tools, accounts, systems, and networks related to the Platform; and</li>
      <li>k) Use the contracted services for the purpose of violating Brazilian law, causing harm to third parties, or storing documents related to civil, administrative, tax, or criminal offenses.</li>
    </ul>
    <p><strong>4.2</strong> The Platform is provided via the Internet, and the LICENSEE is solely responsible for the device used to access it, as well as their own internet connection—including the service provider, modem, and any other intermediaries involved. HELP-DESK.app assumes no responsibility for the LICENSEE’s personal devices or connections. The LICENSEE agrees not to access the Platform via indirect connections to HELP-DESK.app’s servers, nor to use proxy, VPN, or any other technology that would prevent HELP-DESK.app from fully identifying the IP (Internet Protocol) number used in the connection or that would display an IP number different from the originating device, under penalty of cancellation of the contracted plan for violating HELP-DESK.app’s security rules.</p>
    <p><strong>4.2.1</strong> The LICENSEE is responsible for accessing the Platform only from secure, private computers free of viruses and other malicious software, thereby releasing HELP-DESK.app from any liability or compensation claims.</p>
    <p><strong>4.3</strong> HELP-DESK.app shall make the Platform available under the terms of this Agreement and the applicable Order Forms during each contracted period. The LICENSEE agrees that the contracting of the HELP-DESK.app platform does not include or guarantee future features or functionalities, regardless of any oral or written representations.</p>
    <p><strong>4.4</strong> Unless otherwise provided in the applicable Order Form, (i) SaaS services acquired as an \"additional user account/profile\" may not be accessed by more users than specified; (ii) additional user accounts/profiles may be contracted during the term of this SOFTWARE LICENSE AND SERVICE AGREEMENT, calculated proportionally to the remaining term; (iii) the registration of additional user accounts/profiles will remain valid until the expiration date of the pre-existing main account; (iv) the LICENSEE may purchase an additional user account/profile directly through the System or by requesting it from HELP-DESK.app, which is then authorized to charge for adding this new account/profile from the time it is enabled; and (v) in any case of contracting additional user accounts/profiles, no formal amendment to this Agreement is required. The registration of an additional account/profile designated as \"X\" is intended exclusively for the user \"X” and may not be used by more than one person; however, it may later be reassigned to a different user if the original \"X\" no longer requires access to the Platform.</p>
    <p><strong>4.5</strong> HELP-DESK.app shall: (i) provide the LICENSEE with support for the Platform (i.e., failure support) at no additional cost, as set forth in the Terms of Use, item 9; and (ii) provide the SaaS Services in accordance with applicable laws and regulations.</p>
    <p><strong>4.6</strong> The Parties agree to process personal data in compliance with the General Data Protection Law (Law 13.709/18) and other applicable laws regarding data protection and privacy, and to ensure that their employees, agents, and subcontractors do the same. The Parties warrant that any personal data shared under this Agreement has been lawfully obtained, based on Article 7, V, of the LGPD and in accordance with its requirements, and that they have the right to process and share such data with the other Party. For more information, please refer to our <a href=\"https://www.help-desk.app/en/privacidade\">Privacy Policy</a>, which is an integral part of this instrument.</p>
    <p><strong>4.7</strong> The LICENSEE shall: (i) be responsible for ensuring that its Users comply with this Agreement; (ii) be responsible for the accuracy, quality, and legality of its data, as well as for the legality of the manner in which such data was acquired and processed (including when transferred to HELP-DESK.app, which, according to its privacy policy, does not process sensitive personal data); and (iii) use reasonable commercial efforts to prevent unauthorized access to or use of the Platform and immediately notify HELP-DESK.app of any such unauthorized access.</p>
    <p><strong>4.8</strong> The LICENSEE shall not: (i) make the Platform available to third parties who are not Users; (ii) sell, resell, rent, or lease the Platform; (iii) use the Platform to store or transmit material that is harmful, defamatory, or otherwise illegal or dishonest, or to store or transmit material that violates any third party's privacy rights; (iv) use the Platform to transmit malicious code; (v) interfere with or disrupt the integrity or performance of the Platform or third-party data contained therein; or (vi) attempt to gain unauthorized access to the Platform or its associated systems and networks; (vii) engage in any of the actions prohibited in clause 4.1 above.</p>
    <p><strong>4.9</strong> In addition to the SaaS services provided in the preceding clauses, the LICENSEE may contract Training services, with a scope presented in a specific proposal. (i) To contract the Training services set forth in this clause, the LICENSEE must accept the terms and conditions specified in the Order Form; and (ii) Training services will be charged in accordance with the respective Order Form and based on the package chosen at the time of contracting. If the LICENSEE opts to contract these services on an hourly basis, any unused hours will not be refunded, considering the investment made in providing the service, nor may they be applied to future contracts of a similar nature or to other SaaS services covered in previous clauses.</p>

    <h2>5. Platform Availability and Maintenance</h2>
    <p><strong>5.1</strong> HELP-DESK.app will use its best efforts to ensure that the Platform operates continuously and that the system is available 24x7x365 (24 hours a day, 7 days a week, 365 days a year) for at least 90% of the operating time each month.</p>
    <p><strong>5.1.1</strong> Periods of operation affected by scheduled maintenance, preventive maintenance, power outages, interruptions or suspensions of telecommunications services, transmission system failures, system updates, or events of force majeure shall be excluded from the operating time and shall not constitute any liability or obligation to compensate. In the event of urgent maintenance, HELP-DESK.app will use its best efforts to notify LICENSEEs in advance of any potential service suspension or interruption so as not to adversely affect the LICENSEE’s activities.</p>
    <p><strong>5.2</strong> Technical Assistance. HELP-DESK.app will provide Users with technical assistance related to failures or defects in the operation of the Platform, but shall not be responsible for the individual operation of the LICENSEE’s devices.</p>
    <p><strong>5.3</strong> HELP-DESK.app reserves the right to suspend the LICENSEE’s access to the Platform without prior notice if misuse is detected, such as usage volumes significantly above average or operations deemed inappropriate for the proper use and security of the Platform.</p>
    <p><strong>5.4</strong> HELP-DESK.app may suspend, interrupt, or discontinue the Platform at any time, at its sole discretion and as it deems appropriate, even outside the cases provided for in this Agreement, by providing LICENSEEs with reasonable advance notice, without incurring any liability.</p>

    <h2>6. Intellectual Property</h2>
    <p><strong>6.1</strong> The LICENSEE acknowledges and agrees that this AGREEMENT and the use of the Platform do not grant any rights to the LICENSEE, including but not limited to copyrights and/or industrial property rights, over the Platform and/or any functionality or service provided through the Platform, nor over any materials or content of any kind related to or associated with the Platform or HELP-DESK.app, its business partners, or subcontractors. The LICENSEE further acknowledges that it is prohibited from using or permitting third parties to use such materials, content, and any related copyrights and/or industrial property rights owned by HELP-DESK.app or any third party that has licensed such rights to HELP-DESK.app without the prior express consent of the respective owner. Consequently, the LICENSEE acknowledges that any unauthorized or partial reproduction of such content and tools is strictly prohibited unless prior express authorization is obtained.</p>
    <p><strong>6.2</strong> The LICENSEE understands and agrees that the authorization to use the Platform and its functionalities and tools does not constitute a transfer or assignment of rights from HELP-DESK.app to the LICENSEE, but merely a limited license to use the Platform and its features.</p>
    <p><strong>6.3</strong> The LICENSEE is prohibited from using any devices, software, or any other resources that may interfere with the activities or operations of the Platform. Any interference, attempt to interfere, or any other conduct that violates or contravenes applicable copyright and/or industrial property laws and/or the prohibitions set forth in this Agreement may result in termination of this Agreement, suspension of the LICENSEE’s registration and use—either indefinitely or permanently—without prejudice to any civil or criminal liability of the LICENSEE and the obligation to compensate for any losses and damages incurred, in addition to the non-refund of any amounts already paid due to the LICENSEE’s breach.</p>
    <p><strong>6.4</strong> Responsibility for information and content entered into the Platform: The LICENSEE is solely responsible for all information submitted by itself or through its employees and representatives to HELP-DESK.app’s servers via the Platform, as well as for any content entered therein, and shall be fully and indefinitely liable for any legal violations of third-party rights, including intellectual property rights.</p>

    <h2>7. Indemnification and Limitation of Liability</h2>
    <p><strong>7.1</strong> The LICENSEEs agree to indemnify and hold HELP-DESK.app, its partners, directors, employees, and contractors completely harmless from any expenses, losses, damages, claims, and/or demands incurred or suffered as a result of any breach of this Agreement by the LICENSEE, its partners, directors, and/or employees. Any amounts owed to the LICENSEEs by HELP-DESK.app may be withheld and set off against any debts arising from judgments, legal costs, and attorney fees.</p>
    <p><strong>7.2</strong> The Receiving Party (HELP-DESK.app) shall use the same degree of care to protect its own confidential information (which in no case shall be less than reasonable care) as it does for its own and (i) shall not use any confidential information of the Disclosing Party (LICENSEE) for any purpose beyond the scope of this Agreement, (ii) except as expressly authorized in writing by the Disclosing Party, shall limit access to the Disclosing Party’s confidential information to its employees, affiliates, contractors, and agents who need such access to perform the purposes of this Agreement and who have signed confidentiality agreements with terms no less strict than those set forth herein. Neither party shall disclose the terms of this Agreement or any Order Form to any third party other than its affiliates, legal advisors, and accountants without the prior express consent of the other party.</p>
    <p><strong>7.3</strong> The Users acknowledge and agree that HELP-DESK.app shall not be liable for:</p>
    <ul>
      <li>a) Any unauthorized access, suspicious activity on the LICENSEE’s account, or any consequences and damages arising from the LICENSEE’s failure to maintain the confidentiality of its password and/or from any delay or failure to notify HELP-DESK.app of any unauthorized access or use of the LICENSEE’s password by third parties;</li>
      <li>b) Any acts or events arising from force majeure or unforeseen circumstances, including but not limited to instances where the performance of obligations under this Agreement becomes unfeasible directly or indirectly due to the suspension of third-party services (such as electricity, telephone, or internet), fires, storms, laws, regulations, strikes, pandemics, insurrections or revolts, wars, or any other cause beyond the reasonable control of HELP-DESK.app;</li>
      <li>c) Any damage suffered by the LICENSEE as a result of the unilateral removal of any data and information entered into the Platform, cancellation of its registration, or the preservation or provision of information regarding the LICENSEE, including data related to identification, location, and connection/access logs to the Platform, provided that such actions comply with this Agreement, the Terms of Use, the Privacy Policy, and/or court or administrative orders;</li>
      <li>d) Any modification, suspension, interruption, or discontinuation of the Platform;</li>
      <li>e) Any express or implied warranty or representation made during negotiations with the LICENSEEs, including, without limitation: (i) the implied warranty of fitness for a particular purpose or non-infringement of third-party rights; and (ii) any warranty regarding the quality, accuracy, effectiveness, timeliness, or completeness of the services offered through the Platform, or that the LICENSEE’s access to or use of the Platform’s services will be error-free, virus-free, uninterrupted, or will meet all of the LICENSEE’s requirements;</li>
      <li>f) Any erroneous or delayed payments or receipts made by the LICENSEE in relation to suppliers, other LICENSEEs, employees, agents, or any other person, with the LICENSEE being responsible for verifying the amounts indicated by the Platform and for periodically checking the calculations and postings provided by the system to ensure their accuracy. Payments and receipts made by the LICENSEE without proper verification shall not be the responsibility of HELP-DESK.app;</li>
      <li>g) Any losses resulting from lost profits, moral damages, harm to reputation, loss of business opportunity, or failure to meet expectations;</li>
      <li>h) Any act, event, or circumstance that does not require the LICENSEE to make an actual disbursement of funds.</li>
    </ul>
    <p><strong>7.4</strong> It is the sole and exclusive responsibility of the LICENSEE to verify, update, and adapt the system through the necessary configurations according to its industry and company size.</p>
    <p><strong>7.5</strong> In cases where HELP-DESK.app is found liable for damages proven to have been caused by the Platform, the compensation owed to the LICENSEE shall be limited to an amount corresponding to three (3) monthly fees of the plan contracted by the LICENSEE at the time of the breach. If the LICENSEE does not agree with this limitation, they must immediately cease using the Platform.</p>

    <h2>8. Price and Payment</h2>
    <p><strong>8.1</strong> The LICENSEE shall pay HELP-DESK.app the amount corresponding to the chosen licensing plan, in accordance with the payment frequency options provided at the time of contracting. The LICENSEE subscribes to a prepaid plan which is valid for the period stipulated by the type of plan and must be renewed and paid for continued access to the features of the subscribed (Premium / Enterprise) plan. If, during the term of this Agreement, the LICENSEE opts for an upgrade to a higher licensing plan, the amounts will be adjusted accordingly. If the LICENSEE opts for a downgrade to a lower licensing plan during the term, the price adjustment will take effect at the next renewal, and no refunds will be provided for amounts already paid.</p>
    <p><strong>8.2</strong> If the LICENSEE does not renew the subscription within 4 (four) calendar days after the expiration of the last contracted plan, its active agents and departments will be temporarily deactivated, leaving only the oldest active department and the account owner active with HELP-DESK.app, thereby converting the plan to a free Basic plan.</p>
    <p><strong>8.3</strong> The LICENSEE acknowledges that if its Premium/Enterprise licenses expire, its account will be converted to the Basic plan, and system functions will be limited to the account owner and only the features of the Basic plan, which can be viewed at <a href=\"https://www.help-desk.app\">https://www.help-desk.app</a>.</p>
    <p><strong>8.4</strong> Failure to renew any Premium/Enterprise plans on their respective due dates will not automatically terminate the EULA, but will result in the suspension of the LICENSEE’s access to the SOFTWARE with all the features that were available under the subscribed Premium/Enterprise plan, leaving only the Basic plan active. Full access with Premium/Enterprise permissions will only be restored after HELP-DESK.app confirms payment for a new corresponding plan (Premium/Enterprise), which may take up to five (5) business days from the date of payment.</p>
    <p><strong>8.5</strong> If the LICENSEE does not renew within 180 (one hundred and eighty) calendar days and ceases to access the Platform, counted from the due date of the unpaid amount, HELP-DESK.app reserves the right to permanently and irretrievably delete all information stored in the SOFTWARE pertaining to the LICENSEE, in accordance with Clause 9.</p>
    <p><strong>8.6</strong> The licensing plan amounts established at the time of the SOFTWARE licensing will be updated every April in accordance with the accumulated IPCA for the period, or, in the event of its extinction, another official index that may replace it, with the LICENSEE being duly informed at least 7 days prior to the renewal in which the adjustment will occur.</p>
    <p><strong>8.7</strong> Payment will be invoiced in advance or as provided in the applicable Order Form. Unless otherwise specified, the net due date will be within 3 days from the invoice date. The LICENSEE is responsible for providing accurate and complete billing and contact information and for notifying HELP-DESK.app of any changes.</p>
    <p><strong>8.8</strong> Late payment of any obligation under these Terms of Use will subject the unpaid amounts to interest at 1% per month, a 2% penalty, and positive monetary correction according to IPCA/IBGE or any successor index, calculated pro rata die from the date of default until full payment is made. Additionally, HELP-DESK.app reserves the right to cancel or suspend the access of a defaulting LICENSEE to the Platform without prior notice. Any leniency shown by HELP-DESK.app regarding a defaulting LICENSEE does not waive its right to suspend or cancel access at any time.</p>
    <p><strong>8.9</strong> In the event that new taxes are created or the calculation and/or collection conditions for existing taxes change—thereby impacting the current amounts—the impact will be passed on to the LICENSEE, adding to any charges, prices, plans, or previously contracted services, after prior notice of the adjustment.</p>
    <p><strong>8.10</strong> For standalone packages priced in dollars and involving importation, there is no guarantee that the price will remain the same when contracting additional packages.</p>
    <p><strong>8.11</strong> The LICENSEE acknowledges that HELP-DESK.app is authorized by Meta to offer its products through the Platform’s features and that Meta establishes, at its sole discretion, the usage policies, prices, continuity, and discontinuation of products, among other matters. Therefore, in the event of any changes—including but not limited to price changes—HELP-DESK.app will immediately pass on such changes to the LICENSEE, regardless of the contracted payment frequency.</p>

    <h2>9. Return of Information</h2>
    <p><strong>9.1</strong> As described in Clause 8 above, 180 (one hundred and eighty) days after the LICENSEE’s access to the SOFTWARE is suspended, all INFORMATION of the LICENSEE, including PERSONAL INFORMATION and ACCOUNT INFORMATION, held by the Licensor may be permanently deleted from the Licensor’s database, regardless of whether it was extracted by the LICENSEE.</p>
    <p><strong>9.2</strong> Notwithstanding the foregoing, information regarding the date and time of access and the IP address used by the LICENSEE to access the Site and the SOFTWARE will be retained by the Licensor for 6 (six) months from each access date, regardless of the termination of the legal and commercial relationship between the Licensor and the LICENSEE, in accordance with Article 15 of Law No. 12.965/2014, and may be stored for a longer period if ordered by a court.</p>

    <h2>10. Term and Termination</h2>
    <p><strong>10.1</strong> This Agreement shall become effective on the date of the LICENSEE’s acceptance and remain in force for the term of the plan contracted by the LICENSEE. This Agreement will automatically renew for equal periods unless the LICENSEE expressly objects, under the same terms and conditions, including any penalties. This Agreement may be terminated as set forth below.</p>
    <p><strong>10.2</strong> The LICENSEE may request a full refund of payments made within 7 (seven) days after the payment date for subscriptions, subscription renewals, plan changes, or extra purchases of attendant licenses. After this 7-day period, no refunds will be provided.</p>
    <p><strong>10.3</strong> For prepaid licensing plans, if the LICENSEE terminates this Agreement before the end of the contracted term, HELP-DESK.app WILL NOT REFUND the remaining balance of the contracted licensing plan, which will be considered a contractual penalty.</p>
    <p><strong>10.4</strong> HELP-DESK.app may terminate this Agreement at any time provided it notifies the LICENSEE at least 15 (fifteen) days in advance, in which case any outstanding balance for the contracted licensing plan, if applicable, shall be refunded. This minimum notice period and refund obligation shall not apply in cases provided in clause 4 above or in the event of a breach of this Agreement.</p>
    <p><strong>10.5</strong> HELP-DESK.app may terminate this Agreement at any time in the event of a breach by the LICENSEE of the terms and conditions set forth herein, or in the event of overdue payments not remedied within 90 (ninety) days, as provided in clause 9.</p>
    <p><strong>10.6</strong> In the event of termination of this Agreement, the LICENSEE’s personal data and other information will remain available in accordance with Clause 9 and may be permanently deleted 180 (one hundred and eighty) days after the termination date.</p>
    <p><strong>10.7</strong> In any event of termination, the obligations regarding the protection of HELP-DESK.app’s intellectual property, indemnification and limitation of liability, confidentiality, and the obligation to compensate for any losses and damages resulting from non-compliance with this Agreement or applicable law shall survive for the period prescribed by law or, if longer, until the claim is satisfied.</p>
    <p><strong>10.8</strong> The provisions of this chapter apply to loyalty contracts only insofar as they do not conflict with the provisions of Chapter 12 (Specifics for Loyalty Contracts).</p>

    <h2>11. Legal Basis for Data Processing: Contract Execution and Credit Protection</h2>
    <p><strong>11.1</strong> In light of the nature of the SOFTWARE, the LICENSEE expressly acknowledges that the collection and processing of personal data, when it occurs, will be based on the execution of this Agreement, as well as for credit protection, as applicable.</p>
    <p><strong>11.2</strong> By using the SOFTWARE, in addition to being fully aware of and in complete compliance with this Agreement, the LICENSEE expressly acknowledges that HELP-DESK.app may collect, use, store, and process its INFORMATION, including account personal data, which are necessary for the full provision of the offered service, with the legal basis being the execution of the SOFTWARE contract.</p>
    <p><strong>11.3</strong> To that end, the LICENSEE agrees to provide the data necessary to access the INFORMATION required for the SOFTWARE to perform all the functions for which it was designed.</p>
    <p><strong>11.4</strong> The LICENSEE is fully aware that when accessing the HELP-DESK.app website, technical browsing information—such as the type of browser used, IP address, pages visited, and average time spent on the site—may be collected. Such information may be used to guide the LICENSEE and improve the services offered.</p>
    <p><strong>11.5</strong> The LICENSEE acknowledges that its INFORMATION may be transferred to third parties as a result of a sale, acquisition, merger, corporate reorganization, or any other change in the control of HELP-DESK.app. However, HELP-DESK.app undertakes to inform the LICENSEE in such cases.</p>
    <p><strong>11.6</strong> The LICENSEE understands that HELP-DESK.app uses cookies solely to control site traffic and navigation and to enable the identification of services tailored to the LICENSEE’s profile. HELP-DESK.app guarantees that the information collected via cookies is statistical and non-personal and will not be used for purposes other than those expressly provided in this Agreement, and it undertakes to take all necessary measures to prevent unauthorized access and use by third parties.</p>

    <h2>12. Image Release</h2>
    <p><strong>12.1</strong> By this document, the LICENSEE shall, upon detailed request review and free of charge and without exclusivity, authorize HELP-DESK.app to use its image for demonstrating case studies, projects, etc., provided that such use has been previously authorized by the LICENSEE.</p>
    <p><strong>12.2</strong> This release permits the dissemination, use, and display, in whole or in part, for informational and technical purposes, of the image, which may be published in edited or raw form, with national and international visibility via the internet and other media, provided that prior authorization is obtained from the LICENSEE.</p>
    <p><strong>12.3</strong> The release shall include:</p>
    <ul>
      <li>Logo;</li>
      <li>Brand mark;</li>
      <li>Name;</li>
      <li>Website indication;</li>
      <li>Details of the project or case study;</li>
      <li>Other public and essential information for the proper dissemination of the work performed.</li>
    </ul>
    <p><strong>12.3.1</strong> HELP-DESK.app is not authorized to disclose any confidential information shared between the parties.</p>
    <p><strong>12.3.2</strong> HELP-DESK.app reserves the right to use or not use the released images at its sole discretion.</p>
    <p><strong>12.3.3</strong> HELP-DESK.app shall not be liable for any unauthorized use of the images by third parties who may use them in other media without the consent of the image owner.</p>

    <h2>13. Third-Party Suppliers Not Affiliated with HELP-DESK.app</h2>
    <p><strong>13.1</strong> HELP-DESK.app or third parties may periodically provide the LICENSEE with products and platforms supplied by third parties (e.g., via <a href=\"http://www.HELP-DESK.app.com\">http://www.HELP-DESK.app.com</a>), including but not limited to applications, implementations, customizations, or other consulting platforms. Any acquisitions by the LICENSEE of products or platforms not provided by HELP-DESK.app, as well as any information exchange between the LICENSEE and the respective suppliers of such products and platforms, shall be solely between the LICENSEE and those suppliers. HELP-DESK.app does not grant any warranty or support for products or platforms provided by third parties, whether or not they are designated as certified by HELP-DESK.app, except as specified in the Order Form. HELP-DESK.app emphasizes that acquiring any application provided by third parties is not necessary to use the Platform, except for a compatible computer, operating system, browser, and internet connection.</p>
    <p><strong>13.2</strong> If the LICENSEE installs or enables applications not provided by HELP-DESK.app, the LICENSEE acknowledges that HELP-DESK.app may permit the suppliers of such applications to access the LICENSEE’s data as necessary for joint operation and support related to the Platform. HELP-DESK.app shall not be liable for any disclosure, modification, or deletion of the LICENSEE’s data resulting from such access being granted to third-party suppliers. The Platform shall allow the LICENSEE to restrict access to its data by limiting the installation or activation of applications not provided by HELP-DESK.app for joint use with the Platform.</p>
    <p><strong>13.3</strong> The Platform may include features developed to work in conjunction with applications not provided by HELP-DESK.app (e.g., Instagram, Facebook, or Twilio applications). To use such applications, the LICENSEE may be required to obtain access from their respective suppliers. If any such supplier ceases to provide its application for use in conjunction with the Platform under reasonable conditions, HELP-DESK.app may discontinue providing these features without granting the LICENSEE any refund, credit, or compensation.</p>

    <h2>14. Specific Provisions for Loyalty Contracts</h2>
    <p><strong>14.1</strong> The LICENSEE acknowledges that, by receiving the benefits described in the HELP-DESK.app OFFER REGULATION/ORDER FORM, it must remain bound to the contracted SERVICE PLAN for a period of 6 (six) or 12 (twelve) months (or another period specified in the Order Form) of \"MINIMUM COMMITMENT\", counted from the activation of the service.</p>
    <p><strong>14.2</strong> In the event that the LICENSEE cancels the contract during the MINIMUM COMMITMENT period, a written notice must be sent to HELP-DESK.app at least 30 (thirty) days in advance, and the LICENSEE shall be liable for all amounts due for the remaining period of this Agreement. Failure to pay will result in the LICENSEE being registered in credit protection databases, which the LICENSEE hereby acknowledges and authorizes.</p>
    <p><strong>14.3</strong> If the LICENSEE reduces or changes to a plan lower than originally contracted during the MINIMUM COMMITMENT period, it will be considered a breach of the commitment, and the LICENSEE shall be subject to a penalty as provided in Clause 14.2 above.</p>
    <p><strong>14.4</strong> In the event of a transfer of contract ownership, the new LICENSEE must agree to comply with all provisions of this Agreement, including the remaining MINIMUM COMMITMENT period.</p>
    <p><strong>14.5</strong> The LICENSEE acknowledges that it was given the option to contract the HELP-DESK.app Platform without a commitment period and without the associated benefits, but elected to subscribe to the loyalty plan.</p>
    <p><strong>14.6</strong> The provisions of the other chapters and clauses of this instrument shall apply to loyalty contracts only insofar as they do not conflict with the provisions of this Chapter.</p>

    <h2>15. Service Administration and Declaration of Responsibility</h2>
    <p><strong>15.1</strong> The password that grants access to the content and administration (configuration and modifications) of the contracted service shall be chosen and registered by the LICENSEE, without any participation by HELP-DESK.app, and it is solely the LICENSEE’s responsibility to establish the security policy.</p>
    <p><strong>15.1.1</strong> At the LICENSEE’s option, they may register a secondary email address at the time of contracting to receive the administration password, which may be used in case of any issues or problems with the primary email. For the purposes of applying the provisions of this Agreement, the \"email address for sending the administration password\" shall refer to either the primary or the secondary email if the latter is registered by the LICENSEE.</p>
    <p><strong>15.1.2</strong> Only the email address registered by the LICENSEE will receive the administration password and any subsequent changes or replacements, and it is entirely the LICENSEE’s responsibility.</p>
    <p><strong>15.1.3</strong> Possession of the password grants the holder broad administrative powers over the service, including the ability to electronically change the password, and the LICENSEE acknowledges its responsibility for safeguarding the password.</p>
    <p><strong>15.1.4</strong> The responsibility for granting access to the password to any third party rests solely with the LICENSEE, as HELP-DESK.app has no influence over the initial distribution of the password.</p>
    <p><strong>15.2</strong> In the event of a dispute regarding the administration password, access will be blocked until the parties reach a written agreement and notify HELP-DESK.app.</p>

    <h2>16. Confidentiality</h2>
    <p><strong>16.1</strong> For the purposes of this Agreement, \"Confidential Information\" means all confidential information provided by one party (the \"Disclosing Party\") to the other (the \"Receiving Party\"), whether orally or in writing, that is marked as confidential or that reasonably should be understood to be confidential given its nature and the circumstances of disclosure. The LICENSEE’s Confidential Information includes its own data; HELP-DESK.app’s Confidential Information includes the SaaS Platform; and the Confidential Information of each party includes the terms and conditions of this Agreement and all Order Forms, as well as any technical, technological, product design, and commercial information disclosed by the Disclosing Party. However, Confidential Information (excluding the LICENSEE’s data) does not include any information that (i) becomes public without breach of this Agreement, (ii) was known to the Receiving Party prior to disclosure by the Disclosing Party without any obligation of confidentiality, (iii) is received from a third party without breach of any duty of confidentiality, or (iv) is independently developed by the Receiving Party.</p>
    <p><strong>16.2</strong> The Receiving Party shall exercise the same degree of care to protect the Disclosing Party’s Confidential Information as it does for its own, and unless expressly authorized in writing by the Disclosing Party, shall restrict access to such information only to those employees, affiliates, contractors, and agents who need it to perform the purposes of this Agreement and who have signed confidentiality agreements with terms no less strict than those herein. Neither party shall disclose the terms of any Order Form to any third party other than its affiliates, legal advisors, and accountants without the prior express consent of the other.</p>
    <p><strong>16.3</strong> The Receiving Party may disclose the Disclosing Party’s Confidential Information if legally compelled to do so. If the Receiving Party is required by law to disclose the Disclosing Party’s Confidential Information in connection with judicial proceedings in which the Disclosing Party is a party, and the Disclosing Party does not contest such disclosure, the Receiving Party shall be reimbursed for the reasonable costs incurred in compiling and providing secure access to such information in compliance with the court order.</p>

    <h2>17. General Provisions</h2>
    <p><strong>17.1</strong> Should the LICENSEE develop a new module or product that constitutes a copy, in whole or in part, of the data dictionary or program, it shall be considered part of the SOFTWARE provided by HELP-DESK.app, and its ownership shall become part of HELP-DESK.app’s intellectual property, subject to the terms of this Agreement.</p>
    <p><strong>17.2</strong> Tolerance by one party with respect to any breach of this Agreement by the other shall not constitute a waiver or novation of any right. The tolerant party may at any time require full compliance with this Agreement by the other party.</p>
    <p><strong>17.3</strong> Non-performance of the obligations under this Agreement due to events beyond the control of the parties (e.g., force majeure) shall not be considered grounds for termination, in accordance with Article 393 of the Brazilian Civil Code.</p>
    <p><strong>17.4</strong> The LICENSEE agrees that HELP-DESK.app may disclose the execution of this Agreement for commercial purposes, including the use of the LICENSEE’s name and brand in marketing campaigns, as well as the disclosure of any written or oral messages for use on websites, newspapers, magazines, or other media during the term of this EULA. The LICENSEE also agrees to receive email communications regarding training, partnerships, and campaigns related to the SOFTWARE.</p>
    <p><strong>17.5</strong> By this Agreement, the LICENSEE expressly authorizes HELP-DESK.app to collect and use its technical and operational data available in the SOFTWARE for studies and improvements to the SOFTWARE.</p>
    <p><strong>17.6</strong> HELP-DESK.app may, at its sole discretion and at any time without prior notice to the LICENSEE:
      <ul>
        <li>a) Terminate, modify, or suspend, in whole or in part, the LICENSEE’s access to the SOFTWARE if such access or registration violates the terms of this Agreement;</li>
        <li>b) Delete, in whole or in part, any information provided by the LICENSEE that does not comply with this Agreement;</li>
        <li>c) Add, delete, or modify content offered on the website;</li>
        <li>d) Amend any of the terms and conditions of this EULA by simply notifying the LICENSEE.</li>
      </ul>
    </p>
    <p><strong>17.7</strong> HELP-DESK.app may, at its sole discretion, suspend, modify, or discontinue the operations of the SOFTWARE, provided that it notifies the LICENSEE at least 30 (thirty) days in advance and offers alternative means to extract information from the Site, except in cases of force majeure.</p>
    <p><strong>17.8</strong> HELP-DESK.app may, by notifying the email address provided by the LICENSEE or by a notice on the Site, set prices for certain content and/or services—even if those services were initially offered free of charge—with the use of such services after the notice being deemed acceptance of the charges by the LICENSEE.</p>
    <p><strong>17.9</strong> It is agreed that only the person registered by the LICENSEE as the account administrator may request that the LICENSEE’s information in the SOFTWARE be modified or deleted. The LICENSEE further acknowledges that once such information is altered or deleted, it cannot be recovered, and HELP-DESK.app shall not be liable for any losses or damages arising from such action.</p>
    <p><strong>17.10</strong> In the event of any breach by the LICENSEE of these Terms of Use or applicable law, HELP-DESK.app may, without any notice or formalities, immediately suspend, block, or cancel the LICENSEE’s account and access to the Platform, without prejudice to any other rights granted to HELP-DESK.app by law, these Terms of Use, or the Privacy Policy.</p>
    <p><strong>17.11</strong> These Terms of Use do not establish any form of association, franchise, partnership, consortium, joint venture, agency, employment, corporate, or fiduciary relationship, nor do they authorize the parties to act, represent, contract, or assume obligations on behalf of the other.</p>
    <p><strong>17.12</strong> The LICENSEE may not assign or transfer, in whole or in part, the rights and obligations arising from this Agreement to any third party without the prior written consent of HELP-DESK.app. The LICENSEE may assign this Agreement in its entirety (including all Order Forms) in connection with a merger, acquisition, corporate reorganization, or sale of all or a substantial part of its assets, provided that the assignee is not a direct competitor of HELP-DESK.app and that HELP-DESK.app has provided prior written consent upon receipt of appropriate documentation. HELP-DESK.app may assign or transfer its rights and obligations under this Agreement to any third party without the LICENSEE’s prior consent. In the event of a breach of this clause, the non-breaching party may terminate this Agreement upon prior notice to the breaching party.</p>
    <p><strong>17.13</strong> HELP-DESK.app may modify these Terms of Use at any time. New versions will become effective upon publication, and the LICENSEE may continue to use the Platform only if it agrees—or does not expressly object—to the changes. Should the LICENSEE disagree with the new version, it may terminate its registration on the Platform, thereby ending the contractual relationship at its sole discretion. Continued use after notification via email will be deemed acceptance of the new Terms of Use.</p>
    <p><strong>17.14</strong> This Agreement supersedes and prevails over any previous agreements or contracts, whether written or oral, entered into by the parties regarding the subjects covered herein.</p>
    <p><strong>17.15</strong> This Agreement is binding upon the LICENSEE and HELP-DESK.app and their successors in an irrevocable and inalterable manner.</p>
    <p><strong>17.16</strong> HELP-DESK.app and the LICENSEE expressly agree that: (a) the failure or delay in exercising any right under this Agreement or by law shall not constitute a waiver or novation of that right, nor affect its future exercise; (b) any waiver of such rights must be in writing (including electronically) and is only valid if not prohibited by law; and (c) the nullity or invalidity of any clause of this Agreement shall not affect the validity or enforceability of the remaining clauses.</p>
    <p><strong>17.17</strong> Except as expressly provided otherwise, any taxes due directly or indirectly as a result of the execution of this Agreement and the use of the Platform—whether existing or subsequently created—as well as any increases, changes in the calculation basis, adjustments, late fees, and/or ancillary tax obligations, shall be the responsibility of the respective taxpayer as defined by applicable law.</p>
    <p><strong>17.18</strong> The LICENSEE represents that no bribes, kickbacks, payments, gifts, or valuable items have been given or offered to it by HELP-DESK.app employees or agents related to this Agreement. Should the LICENSEE become aware of any such violation, it shall promptly notify HELP-DESK.app.</p>
    <p><strong>17.19</strong> In the event of any disputes or disagreements regarding these Terms of Use, the parties agree that the forum shall be the Court of Curitiba, State of Paraná, to settle any such issues, excluding any other jurisdiction.</p>
    <p>This Agreement shall be deemed executed and binding upon the parties when the LICENSEE completes its registration and the procedure provided on the website <a href=\"https://www.help-desk.app\">https://www.help-desk.app</a>, or upon the physical or electronic signature of the first Order Form, whichever occurs first. The LICENSEE acknowledges that it has read and understood all the terms and conditions of this Agreement, and it is recommended that the LICENSEE print a copy of this document for future reference.</p>
  `
  }
]

function Termos() {
  const { language } = useParams();
  console.log("language", language);
  const [term, setTerm] = useState(null);
  useEffect(() => {
    let found = TERMS.find((t) => t.language === language);
    if(!found) found = TERMS.find((t) => t.language === "pt-BR");
    
    setTerm(found);
  }, [language]);
  if(!term) return <div>...</div>;

  return <TermHolder
    dangerouslySetInnerHTML={{ __html: term.content }}
  >
    
  </TermHolder>;
}

const TermHolder=  styled.div`
  padding: 30px;
  line-height: 1.6;
  > h1 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #003adb;
  }
  > h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;

    color: #003adb;
  }
  > p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  > ul {
    margin-bottom: 10px;
  }
  > li {
    margin-bottom: 5px;
  }
  > a {
    color: #007bff;
    text-decoration: none;
  }
  > a:hover {
    text-decoration: underline;
  }
  > strong {
    font-weight: bold;
  }

`

export default Termos;
