import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
} from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { REPORTS } from "../../../../../api/REPORTS";
import moment from "moment-timezone";
import { TitleContainer } from "../../../styles";
import { FilterArea, GraphArea } from "../../styled";
import ReportLayout from "../../../../../components/ReportLayout";

// Registrar componentes do Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AgentPerformanceReport = ({ tenantId }) => {
  const [performanceData, setPerformanceData] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("day").toDate());

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // make sure dates exist
    if (!startDate || !endDate) return;
    try {
      const response = await REPORTS.get.agents.performance(
        startDate,
        endDate,
        tenantId
      );
      console.log("Performance data:", response);
      setPerformanceData(response.data);
    } catch (error) {
      console.error("Error fetching performance data:", error);
    }
  };

  const chartData = {
    labels: performanceData.map((item) => item.agent_name || "Sem Dados"),
    datasets: [
      {
        label: "Tickets Resolvidos",
        data: performanceData.map((item) => item.tickets_resolved),
        backgroundColor: "#007bff",
      },
    ],
  };

  return (
    <ReportLayout
      title="Relatório de Desempenho por Agentes"
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
    >
      <Container style={{ overflow: "auto" }}>
        <Row>
          <Col md={12}>
            <GraphArea>
              <Bar
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false, // Permite definir uma altura personalizada
                }}
                style={{ maxHeight: "400px" }} // Limita a altura do gráfico
              />
            </GraphArea>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>

          <hr/>
            <h4>Detalhes de Performance</h4>
            <Table striped>
              <thead>
                <tr>
                  <th>Agente</th>
                  <th>Grupos</th>
                  <th>Tickets Resolvidos</th>
                  <th>Tempo Médio de Resolução</th>
                  <th>CSAT Médio</th>
                </tr>
              </thead>
              <tbody>
                {performanceData.map((item, index) => {
                  console.log("item", item);
                  const avg_csat = parseFloat(item.avg_csat);
                  const avg_resolution_time = parseFloat(
                    item.avg_resolution_time
                  );
                  return (
                    <tr key={index}>
                      <td>{item.agent_name || "Agente Não Identificado"}</td>

                      <td>{item.groups || "Sem Grupos"}</td>
                      <td>
                        {item.tickets_resolved} ({item.total_responses}{" "}
                        respostas)
                      </td>
                      <td>
                        {item.avg_resolution_time
                          ? moment
                              .duration(
                                parseFloat(item.avg_resolution_time),
                                "hours"
                              )
                              .humanize()
                          : "-"}
                      </td>
                      <td>{avg_csat?.toFixed(2) || "-"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </ReportLayout>
  );
};

export default AgentPerformanceReport;
