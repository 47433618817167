import axios from "axios";
import { API_URL } from "../../util/constants";
import api from "../axios";

export const APP = {
  get: {
    all: async () => {},
    public_workspace: async (workspace) => {
      let response = await axios.get(`${API_URL}/tennants/public/${workspace}`);

      return response;
    },
    one: async (id) => {
      return new Promise((resolve, reject) => {
        console.log("BUSCOU APP", id);
        setTimeout(() => {
          if (id === "seidecor") {
            resolve({
              name: "Seidecor",
              colors: {
                main: "#be2581",
                secondary: "#555",
                accent: "#777",
                text: "#fff",
                title: "#be2581",
                headerText: "#ffffff",
              },
              logo: "https://assets-global.website-files.com/62443b8315702250ce9e390d/62443c04718f404f79be83b6_logofullwhitesmall.png",
              darklogo:
                "https://assets-global.website-files.com/62443b8315702250ce9e390d/62443c04718f404f79be83b6_logofullwhitesmall.png",
              favicon: "https://intercom.help/sei-de-cor/assets/favicon",
              website: "https://app.seidecor.com.br/#/v2",
              title: "Painel de Suporte Sei de Cor",
            });
          } else {
            resolve({
              name: "Default App",
              colors: {
                main: "#333",
                secondary: "#555",
                accent: "#777",
                text: "#fff",
                title: "#555",
                headerText: "#ffffff",
              },
              logo: "/assets/logo.png",
              favicon: "https://via.placeholder.com/32",
              website: "https://example.com",
              title: "Help-Desk App",
            });
          }
        }, 1000);
      });
    },
    check: async (workspace) => {
      if (!workspace) return false;
      let response = await axios.get(`${API_URL}/tennants/public/${workspace}`);
      return response?.data?.notFound ? true : false;
    },
    should_validate: async (workspace, code) => {
      let response = await axios.get(
        `${API_URL}/tennants/should-validate/${workspace}/${code}`
      );
      console.log("SHOULD VALIDATE", response);
      return response.data;
    }
  },
  set: {
    cadastro_basico: async (data) => {
      // creates a new workspace
      let response = await axios.post(`${API_URL}/tennants`, data);
      return response;
    },
    cadastro_completo: async (workspace, data) => {
      // creates a new workspace
      let response = await axios.post(
        `${API_URL}/tennants/complete-signup/${workspace}`,
        data
      );
      return response;
    },
    updateLayout: async (tid, data) => {
      let response = await api.post(`/tennants/app/layout/${tid}`, data);
      return response;
    },
    update: async (id, data) => {},
    delete: async (id) => {},
    activate: async (id) => {},
  },
};
