import React, { useEffect, useState } from "react";

// import * as S from "./styles";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const TERMS = [
  {
    "language": "pt-BR",
    "content": `<h1>Política de Privacidade</h1>
    <p>Nós, da Help-Desk.app (a "Help-Desk.app" ou "nós"), CNPJ: 58.420.116/0001-60, Razão Social: Hdapp Sistemas LTDA, estamos comprometidos em resguardar a sua privacidade e proteger seus dados. O intuito deste documento é esclarecer quais informações são coletadas dos usuários a partir dos nossos sites <a href="https://www.help-desk.app">https://www.help-desk.app</a>, <a href="https://*.help-desk.app">https://*.help-desk.app</a> – e respectivos serviços – ("Você") e de que forma esses dados são utilizados.</p>

    <p>Para oferecer nossos serviços, a Help-Desk.app, por meio de seu site, de seus aplicativos e sistemas, do equipamento onde estes estão instalados ou sendo utilizados, e dos dispositivos sincronizados com eles, coleta diversos dados e informações, visando, sobretudo, proporcionar uma experiência cada vez melhor para você. A Help-Desk.app reconhece que a sua privacidade é muito importante, portanto, tomamos todas as medidas possíveis para protegê-la. Nesse sentido, a presente Política de Privacidade ("Política") visa lhe informar como as suas informações e dados pessoais serão coletados, usados, compartilhados e armazenados por meio dos nossos sites e respectivos serviços. Este documento é parte integrante dos nossos Termos de Uso do Site, que contém uma visão geral da nossa plataforma.</p>

    <p>Esta Política de Privacidade se aplica quando você utiliza os nossos Serviços que envolvem coleta de dados online e offline, incluindo os Dados Pessoais coletados por meio dos nossos diversos serviços e canais, tais como sites, aplicativos, redes sociais de terceiros, Serviço de Atendimento ao Consumidor e pontos de venda. Em determinados casos, nossos usuários podem escolher os tipos de dados que coletamos, utilizamos e compartilhamos, conforme descrito nesta Política.</p>

    <p>A aceitação da nossa Política ocorrerá quando você se cadastrar em nossa plataforma para usufruir de um dos serviços mencionados. Essa aceitação se dará no momento em que você, de forma afirmativa, concordar com o uso dos seus dados para cada uma das finalidades aqui descritas. Isso indicará que você está ciente e em total acordo com a forma como utilizaremos as suas informações e os seus dados. Se você optar por não nos fornecer os Dados Pessoais necessários para utilizar nossos produtos ou para a prestação dos nossos serviços (quando for o caso) ou não concordar com esta Política, não poderemos fornecer a você nossos produtos e/ou serviços. Se não concordar com esta Política, por favor, não continue com o seu registro e não utilize nossos serviços. Todavia, nos informe sua discordância para que possamos esclarecê-la ou melhorá-la.</p>

    <p>Este documento foi redigido de forma simples e acessível, contendo vários exemplos de coleta e uso dos dados, para que você possa ler e entender como utilizamos os seus dados para oferecer uma experiência segura e confortável no uso dos nossos serviços.</p>

    <h2>Estrutura da Política</h2>
    <ul>
      <li>Quais são as fontes de dados pessoais?</li>
      <li>Que tipos de dados a Help-Desk.app coleta?</li>
      <li>Com quem a Help-Desk.app compartilha os seus dados?</li>
      <li>Transferência internacional de dados</li>
      <li>Quais são os direitos dos titulares de dados?</li>
      <li>Por quanto tempo os dados serão armazenados?</li>
      <li>Como funciona a Segurança da Informação na Help-Desk.app?</li>
      <li>Como falar com o DPO da Help-Desk.app?</li>
      <li>Mudanças na Política</li>
    </ul>

    <h2>1. Quais são as fontes de dados pessoais?</h2>
    <p>A quantidade e o tipo de informações coletadas pela Help-Desk.app variam conforme o uso que você faz dos nossos serviços. Coletamos diferentes dados caso você esteja visitando nosso site, utilizando nossos softwares ou os serviços dos nossos clientes. Para tornar essas definições mais acessíveis, segmentamos a coleta dos dados dos usuários em quatro partes, para que você possa compreender o que se aplica especificamente a você.</p>
    <p>Esta Política se aplica aos Dados Pessoais que coletamos de ou sobre você por meio das seguintes fontes:</p>
    <ul>
      <li><strong>Sites Help-Desk.app:</strong> Sites voltados para o consumidor, operados por ou para a Help-Desk.app, incluindo sites que operamos sob nossos próprios domínios/URLs e páginas mantidas em redes sociais de terceiros, como o Facebook.</li>
      <li><strong>Sites/aplicativos móveis Help-Desk.app:</strong> Sites ou aplicativos móveis voltados para o consumidor, operados por ou para a Help-Desk.app, como aplicativos para smartphones.</li>
      <li><strong>Interações com anúncios:</strong> Se você interagir com nossos anúncios (por exemplo, clicando em um ou mais de nossos anúncios em um site de terceiros), podemos receber informações sobre essa interação.</li>
      <li><strong>Dados criados por nós:</strong> Durante nossas interações com você, podemos criar Dados Pessoais sobre você (por exemplo, registros de suas compras em nossos sites e de suas interações com nossos conteúdos e emails).</li>
    </ul>
    <p>Também armazenamos informações sobre todos os contatos realizados com nossos usuários, independentemente do tipo de contato, incluindo conteúdos baixados de nossas páginas, interações via email (como aberturas e cliques), contatos por formulário, chat, cadastro na plataforma e solicitações de suporte.</p>
    <p>Ao clicar nos botões de compartilhamento de conteúdo nas redes sociais disponíveis em nossas páginas, você estará publicando o conteúdo por meio do seu perfil na rede selecionada. A Help-Desk.app não tem acesso ao seu login e senha nessas redes, nem publicará conteúdo em seu nome sem que você o faça.</p>
    <p>Vale ressaltar que todos os dados que coletamos são utilizados para a prestação dos nossos serviços. Por isso, todos os dados e informações sobre você são tratados como confidenciais e serão usados apenas para os fins aqui descritos e autorizados por você, principalmente para que você possa utilizar nossos serviços plenamente. Veja na seção 2 o que se aplica a você.</p>

    <h3>1.2. Quais são as Bases Legais para a coleta dos dados?</h3>
    <p>Utilizamos como base legal para a coleta de dados apenas métodos autorizados por lei, como o consentimento, o legítimo interesse e a execução regular dos contratos firmados. Quando a base legal não for o consentimento, sempre observaremos a legislação vigente, bem como respeitaremos os direitos e liberdades individuais dos titulares dos dados.</p>

    <h2>2. Que tipos de dados a Help-Desk.app coleta?</h2>
    <h3>2.1. Visitantes dos sites</h3>
    <p>Se você apenas acessa os nossos sites, coletamos seus dados de navegação. Por exemplo, quando você visita nosso site, um "cookie" é inserido no seu navegador por meio do Google Analytics e do Pixel do Facebook para identificar quantas vezes você retorna.</p>
    <p>"Cookies" são identificadores que transferimos para o seu navegador ou dispositivo, permitindo-nos reconhecer seu dispositivo e informando como e quando as páginas e recursos dos nossos Serviços são acessados, bem como o número de visitantes.</p>
    <p>Utilizamos cookies e dados de navegação, como URLs, para coletar informações sobre a data e a hora da sua visita e as soluções e informações que você pesquisou e visualizou. Assim como a maioria das empresas de tecnologia, coletamos esses dados automaticamente e os armazenamos em arquivos de log sempre que você visita nosso site ou acessa sua conta. Podemos utilizar cookies de sessão (que expiram ao fechar o navegador) e cookies persistentes (que permanecem até serem excluídos) para oferecer uma experiência mais personalizada. Você pode optar por limitar, recusar ou desabilitar os cookies através das configurações do seu navegador, mas isso pode afetar o funcionamento de algumas áreas do nosso site.</p>
    <p>Esta Política de Privacidade não abrange o uso de cookies por terceiros, e não somos responsáveis pelas políticas de privacidade deles. Note que os cookies de terceiros podem continuar monitorando suas atividades online mesmo depois de você sair dos nossos Serviços.</p>

    <h3>2.2. Leads da Help-Desk.app</h3>
    <p>Se você interagir com nossos materiais e conteúdos, coletaremos, além dos dados de navegação mencionados, informações sobre suas visitas às nossas páginas e outros dados que você optar por compartilhar conosco, através de formulários, landing pages, formulários de contato, chat e emails.</p>
    <p>Para oferecer conteúdos e materiais de seu interesse, pode ser necessário cruzar os dados que coletamos de você, de modo que nossas ações de marketing sejam direcionadas ao que você espera receber. Garantimos que esse cruzamento não será feito de forma discriminatória e não violará quaisquer direitos ou liberdades individuais. Além disso, você pode, a qualquer momento, solicitar a alteração ou remoção dessas permissões por meio da nossa Central de Ajuda.</p>
    <p>Alguns exemplos de uso dos seus dados incluem:</p>
    <ul>
      <li>Funcionários da Help-Desk.app podem, eventualmente, entrar em contato via email ou telefone para realizar pesquisas ou apresentar produtos e serviços;</li>
      <li>Envio de mensagens sobre suporte ou serviços, como alertas, notificações e atualizações;</li>
      <li>Comunicação sobre produtos, serviços, promoções, notícias, atualizações, eventos e outros assuntos de seu interesse;</li>
      <li>Realização de publicidade direcionada conforme seus gostos, interesses e outras informações coletadas.</li>
    </ul>

    <h3>2.3. Usuários dos Sites e Serviços da Help-Desk.app</h3>
    <p>Se você utilizar algum dos nossos serviços e/ou produtos, coletaremos as seguintes categorias de dados pessoais:</p>
    <ul>
      <li>Dados de contato (nome e email);</li>
      <li>Dados de perfil profissional (nome da empresa);</li>
      <li>Dados de faturamento (nome/razão social, CPF/CNPJ, endereço completo, inscrição estadual);</li>
      <li>Dados para fins de segurança e auditoria (endereço IP, tipo de navegador, sistema operacional e localização baseada no IP).</li>
    </ul>
    <p>Para o cadastro em serviços e/ou produtos na modalidade de teste gratuito (trial), são coletados:</p>
    <ul>
      <li>Dados de contato (nome e email);</li>
      <li>Dados de perfil profissional (nome da empresa);</li>
      <li>Dados para segurança e auditoria (endereço IP, sistema operacional e navegador).</li>
    </ul>
    <p>Para a contratação dos nossos serviços e/ou produtos, são coletados:</p>
    <ul>
      <li>Dados de contato (nome e email);</li>
      <li>Dados de perfil profissional (nome da empresa);</li>
      <li>Dados locacionais (país, estado e cidade);</li>
      <li>Dados de faturamento, cobrança e emissão de Nota Fiscal para clientes residentes no Brasil (nome/razão social, CNPJ/CPF, país, endereço completo, inscrição estadual);</li>
      <li>Dados de faturamento, cobrança e emissão de Nota Fiscal para clientes residentes fora do Brasil (nome, país e endereço completo).</li>
    </ul>
    <p>Ao enviar uma mensagem de contato por meio do nosso site, coletamos:</p>
    <ul>
      <li>Dados de contato (nome e email);</li>
      <li>Dados para segurança e auditoria (endereço IP).</li>
    </ul>
    <p>Ao iniciar uma conversa via chat com nossa equipe através dos nossos sites, coletamos:</p>
    <ul>
      <li>Dados de contato (nome e email);</li>
      <li>Dados para segurança e auditoria (endereço IP, tipo de navegador, sistema operacional, localização baseada no IP e página de referência).</li>
    </ul>
    <p>Ao acessar o Painel Administrativo, o aplicativo Help-Desk.app: Help Desk, Help-Desk.app: Atendimento e ao manipular dados sensíveis por parte dos atendentes, coletamos:</p>
    <ul>
      <li>Identificador do atendente (nome e email);</li>
      <li>Dados para segurança e auditoria (endereço IP, tipo de navegador, sistema operacional e localização baseada no IP).</li>
    </ul>
    <p>É importante ressaltar que os dados coletados no momento do acesso à ferramenta e da manipulação de dados sensíveis via Painel Administrativo e aplicativos mencionados não são utilizados por nós, mas ficam disponíveis aos administradores de conta para consulta, permitindo identificar quando um atendente acessou a plataforma e quem realizou alterações em dados sensíveis da sua conta.</p>
    <p>Ao acessar o Painel de Atendente, um "cookie" é inserido no seu navegador por meio do Google Analytics para identificar quantas vezes você retorna ao nosso site.</p>

    <h3>2.4. Usuários dos sites dos Clientes Help-Desk.app</h3>
    <p>A Help-Desk.app disponibiliza aos seus clientes, por meio de softwares como Painel do Cliente, Base de Conhecimento, Atendimento Online, e o aplicativo Help-Desk.app: Meu Suporte, ferramentas para criação e acompanhamento de chamados, chats e consulta de artigos, entre outros, que envolvem o gerenciamento das informações pessoais dos usuários com os quais os clientes mantêm relacionamento.</p>
    <p>Entretanto, é importante mencionar que a Help-Desk.app não controla as informações coletadas por terceiros que utilizam seu sistema. Esses dados pertencem aos clientes da Help-Desk.app, que os utilizam, divulgam e protegem de acordo com suas respectivas políticas de privacidade, sendo eles também os responsáveis por obter diretamente o consentimento dos usuários para coletar, gerenciar e processar tais informações confidenciais.</p>
    <p>Como a Help-Desk.app não controla os dados coletados diretamente por seus clientes, e com o intuito de minimizar incidentes envolvendo dados pessoais, adotamos as melhores práticas de segurança da informação (ver item 7) e de desenvolvimento seguro disponíveis no mercado, para que nossos clientes possam utilizar uma ferramenta segura no gerenciamento dos seus dados pessoais e dos dados de seus próprios clientes. Se você tiver dúvidas sobre como seus dados estão sendo gerenciados, recomendamos que entre em contato com a empresa que está oferecendo os serviços.</p>

    <h2>3. Com quem a Help-Desk.app compartilha os seus dados?</h2>
    <p>Por ser uma plataforma online, a Help-Desk.app pode operar em conjunto com outras empresas em uma ampla gama de atividades, incluindo fornecer funcionalidades de localização, trabalhar com anunciantes, patrocinadores e parceiros comerciais – tanto online quanto offline –, além de utilizar ferramentas de divulgação e análise de desempenho. Dessa forma, reservamo-nos o direito de compartilhar suas informações, incluindo dados de localização, cadastro e interesses, com as empresas parceiras abaixo, sempre que possível de forma anônima, visando preservar ao máximo a sua privacidade.</p>
    <p>Nós não alugamos, vendemos ou transferimos seus dados pessoais para ninguém, exceto para as empresas parceiras. Podemos compartilhar seus dados pessoais nas seguintes situações:</p>
    <ul>
      <li><strong>Nossos Parceiros:</strong> Contratamos empresas para realizar trabalhos em nosso nome e precisamos compartilhar seus dados pessoais com elas para fornecer produtos e serviços a você. Por exemplo, usamos serviços de pagamento para processar os dados de faturamento dos produtos adquiridos por você e serviços para envio de emails para notificações. Nossos parceiros estão autorizados a usar seus dados apenas para os fins específicos para os quais foram contratados.</li>
      <li><strong>Estatísticas:</strong> Os dados armazenados podem ser utilizados para fins estatísticos (analytics). Esses dados são agrupados para fornecer uma análise macro e não visam identificar os titulares dos dados, mas apenas compreender como o acesso ocorre nas plataformas da Help-Desk.app, visando melhorar nossos serviços e customizar produtos conforme os interesses dos usuários.</li>
      <li><strong>Proteção de Direitos:</strong> Reservamo-nos o direito de acessar, ler, preservar e divulgar quaisquer dados que consideremos necessários para cumprir obrigações legais ou ordens judiciais; para fazer cumprir nossos Termos de Uso; ou para proteger os direitos, a propriedade ou a segurança da Help-Desk.app, de seus funcionários, usuários ou terceiros.</li>
    </ul>

    <h2>4. Transferência Internacional de Dados</h2>
    <p>A Help-Desk.app coleta e transfere dados pessoais coletados no Brasil para países localizados na União Europeia e para os EUA. Essa transferência ocorre para parceiros da Help-Desk.app que processam dados pessoais e envolve apenas empresas parceiras que demonstraram estar em conformidade – ou em processo de conformidade – com a GDPR e com as leis brasileiras de proteção de dados.</p>
    <p>A Help-Desk.app possui sua sede no Brasil e os dados que coletamos são regidos pela lei brasileira. Ao acessar ou utilizar os Serviços da Help-Desk.app ou fornecer seus dados pessoais, você concorda com o processamento e a transferência desses dados para o Brasil e para os outros países mencionados.</p>

    <h2>5. Quais são os direitos dos titulares de dados?</h2>
    <p>Você pode optar por não divulgar seus dados para nós, mas tenha em mente que alguns desses dados podem ser necessários para se registrar em nossas plataformas ou utilizar determinados recursos e produtos. Independentemente disso, você possui direitos relativos à privacidade e à proteção dos seus dados pessoais. A seguir, resumimos os direitos que você tem sob a LGPD, outras leis brasileiras de proteção de dados e a GDPR:</p>
    <ul>
      <li>Direito de acesso;</li>
      <li>Direito de retificação;</li>
      <li>Direito de exclusão;</li>
      <li>Direito de confirmação sobre a existência de tratamento;</li>
      <li>Direito de solicitar o bloqueio ou eliminação;</li>
      <li>Direito à informação por parte de entidades públicas ou privadas;</li>
      <li>Direito de restringir o processamento;</li>
      <li>Direito à revisão de decisão automatizada;</li>
      <li>Direito de oposição ao tratamento;</li>
      <li>Direito à explicação da lógica por trás da coleta dos seus dados;</li>
      <li>Direito à informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
      <li>Direito de retirar o seu consentimento.</li>
    </ul>
    <p>A seguir, alguns desses direitos são explicados brevemente:</p>
    <ul>
      <li><strong>Requisição de acesso:</strong> Você pode solicitar e receber uma cópia dos seus dados pessoais que possuímos.</li>
      <li><strong>Requisição de retificação:</strong> Você pode solicitar a correção dos seus dados pessoais caso identifique algum erro. Para efetivar essa correção, precisaremos verificar a validade dos dados fornecidos por você.</li>
      <li><strong>Requisição de exclusão:</strong> Você pode nos solicitar a exclusão dos seus dados pessoais da nossa plataforma. Os dados serão excluídos quando não forem mais necessários para a prestação dos nossos serviços, salvo se houver qualquer outra obrigação legal que exija sua manutenção.</li>
      <li><strong>Direito de objeção:</strong> Se estivermos processando seus dados com base em legítimo interesse (nosso ou de terceiros) e você, por alguma razão específica, se opuser a esse processamento, você poderá apresentar sua objeção, especialmente no caso de processamento para marketing direto. Em alguns casos, podemos demonstrar que temos motivos legítimos para o processamento que se sobrepõem aos seus direitos, se esse processamento for essencial para a prestação do produto ou serviço.</li>
      <li><strong>Direito de solicitar bloqueio ou eliminação:</strong> Você pode solicitar que suspendamos o processamento dos seus dados em determinadas situações, como para verificar a precisão dos dados ou se você se opõe ao seu uso, enquanto avaliamos se temos motivos legítimos para utilizá-los.</li>
      <li><strong>Direito de retirar o consentimento:</strong> Você tem o direito de retirar seu consentimento a qualquer momento. Isso não afetará a legalidade do processamento realizado antes da retirada, mas, se o consentimento for retirado, talvez não possamos fornecer determinados produtos ou serviços, e você será informado caso isso ocorra.</li>
      <li><strong>Direito à revisão de decisões automatizadas:</strong> Você pode solicitar a revisão de decisões tomadas unicamente com base em processamento automatizado dos seus dados pessoais que afetem seus interesses.</li>
    </ul>
    <p>Para confirmar sua identidade e garantir o seu direito de acessar ou exercer os demais direitos, pode ser necessário que solicitemos informações específicas. Essa medida de segurança tem o intuito de evitar que seus dados pessoais sejam divulgados a pessoas não autorizadas. Podemos também contatá-lo para obter mais informações a respeito da sua solicitação, a fim de acelerar nossa resposta. Suas solicitações legítimas serão atendidas em até 5 (cinco) dias úteis. Se sua solicitação for particularmente complexa ou se houver múltiplas solicitações, manteremos você informado sobre o andamento.</p>
    <p>Se tiver dúvidas sobre como exercer seus direitos, fique à vontade para entrar em contato conosco por meio do nosso formulário de contato.</p>

    <h2>6. Por quanto tempo a Help-Desk.app irá armazenar os dados?</h2>
    <p>Manteremos seus dados pessoais apenas pelo tempo necessário para cumprir as finalidades para as quais foram coletados, incluindo o cumprimento de obrigações legais, contratuais ou requisições de autoridades competentes.</p>
    <p>Para determinar o período de retenção adequado, consideramos a quantidade, a natureza e a sensibilidade dos dados, o risco potencial de danos decorrentes do uso não autorizado, a finalidade do processamento e os requisitos legais aplicáveis.</p>
    <p>Se você utiliza nossos produtos e serviços, as informações da sua conta serão mantidas enquanto a conta estiver ativa. Se você solicitar o cancelamento, suas informações serão excluídas em até 180 (cento e oitenta) dias após o cancelamento da conta. Da mesma forma, se você solicitar a exclusão dos seus dados e/ou da sua conta, a Help-Desk.app os manterá por 72 (setenta e duas) horas após o pedido de exclusão.</p>

    <h2>7. Como funciona a Segurança da Informação na Help-Desk.app?</h2>
    <p>Sua conta é protegida por uma senha para garantir sua privacidade e segurança. Se você acessar sua conta por meio de um site ou serviço de terceiros, proteções adicionais de login poderão ser aplicadas. Você deve evitar o acesso não autorizado, protegendo sua senha e/ou outros mecanismos de acesso, limitando o acesso ao seu dispositivo e navegador, e realizando logout após o uso.</p>
    <p>Com o objetivo de garantir a sua privacidade e a proteção dos seus dados pessoais, a Help-Desk.app adota as melhores práticas de segurança da informação e de desenvolvimento seguro disponíveis no mercado. Isso inclui o uso de criptografia para senhas e outras informações críticas, TLS para transferência de dados entre aplicações e a contratação de serviços em nuvem com certificações e selos de segurança.</p>
    <p>Embora nos esforcemos para proteger sua conta e seus dados pessoais, não podemos garantir segurança absoluta. Acesso ou uso não autorizado, falhas de hardware ou software e outros fatores podem comprometer a segurança dos seus dados a qualquer momento. Por isso, se identificar algo que comprometa a segurança dos seus dados, entre em contato conosco através dos canais de atendimento disponíveis.</p>

    <h2>8. Como falar com o DPO da Help-Desk.app?</h2>
    <p>Se você acredita que suas informações pessoais foram utilizadas de maneira incompatível com esta Política de Privacidade ou se tiver dúvidas, comentários ou sugestões relacionados a esta Política, você pode entrar em contato com o nosso DPO através do seguinte endereço:</p>
    <p><strong>Email:</strong> <a href="mailto:dpo@help-desk.app">dpo@help-desk.app</a></p>

    <h2>9. Mudanças na Política de Privacidade</h2>
    <p>Como estamos sempre buscando melhorar nossos serviços, esta Política de Privacidade pode ser atualizada periodicamente. Recomendamos que você visite esta página regularmente para se manter informado sobre quaisquer modificações. Caso sejam feitas alterações relevantes que exijam um novo consentimento, publicaremos a atualização e solicitaremos seu novo consentimento.</p>
  `
  },
  {
    "language": "en-US",
    "content": `<h1>Privacy Policy</h1>
    <p>We at Help-Desk.app (referred to as "Help-Desk.app" or "we"), CNPJ: 58.420.116/0001-60, Legal Name: Hdapp Sistemas LTDA, are committed to safeguarding your privacy and protecting your data. The purpose of this document is to clarify what information is collected from users through our websites <a href="https://www.help-desk.app">https://www.help-desk.app</a> and <a href="https://*.help-desk.app">https://*.help-desk.app</a> – and their respective services – ("You") and how such data is used.</p>

    <p>In order to provide our services, Help-Desk.app, through its website, applications, and systems, the device on which they are installed or used, and the devices synchronized with them, collects various data and information primarily to offer you an ever-improving experience. Help-Desk.app recognizes that your privacy is extremely important, and therefore, we take every possible measure to protect it. Accordingly, this Privacy Policy ("Policy") is designed to inform you how your personal information and data will be collected, used, shared, and stored through our websites and services. This document is an integral part of our Website Terms of Use, which provides an overview of our platform.</p>

    <p>This Privacy Policy applies when you use our Services that involve both online and offline data collection, including Personal Data collected through our various services and channels, such as websites, applications, third-party social networks, Customer Service, and points of sale. In certain cases, our users can choose the types of data we collect, use, and share, as described in this Policy.</p>

    <p>Your acceptance of our Policy will occur when you register on our platform to use one of the aforementioned services. Such acceptance will be given when you affirmatively agree to the use of your data for each of the purposes described herein. This indicates that you are fully aware of and agree with the way we will use your information and data. If you choose not to provide us with the necessary Personal Data to use our products or receive our services (as applicable), or if you do not agree with this Policy, we will be unable to provide you with our products and/or services. If you do not agree with this Policy, please do not proceed with your registration and do not use our services. However, please inform us of your disagreement so that we may clarify or improve it.</p>

    <p>This document has been written in a simple and accessible manner, with several examples of data collection and usage, so that you can read and understand how we use your data to provide you with a safe and comfortable experience when using our services.</p>

    <h2>Policy Structure</h2>
    <ul>
      <li>What are the sources of personal data?</li>
      <li>What types of data does Help-Desk.app collect?</li>
      <li>With whom does Help-Desk.app share your data?</li>
      <li>International data transfer</li>
      <li>What rights do data subjects have?</li>
      <li>How long will the data be stored?</li>
      <li>How does Information Security work at Help-Desk.app?</li>
      <li>How to contact Help-Desk.app's DPO?</li>
      <li>Changes to the Policy</li>
    </ul>

    <h2>1. What are the sources of personal data?</h2>
    <p>The quantity and type of information collected by Help-Desk.app vary according to how you use our services. We collect different data depending on whether you are visiting our website, using our software, or using our clients' services. To make these definitions and differences more accessible, we have segmented the collection of user data into four parts, so you can understand what applies specifically to you.</p>
    <p>This Policy applies to the Personal Data we collect from or about you through the following sources:</p>
    <ul>
      <li><strong>Help-Desk.app Websites:</strong> Consumer-oriented websites operated by or for Help-Desk.app, including sites we operate under our own domains/URLs and pages maintained on third-party social networks such as Facebook.</li>
      <li><strong>Help-Desk.app Mobile Websites/Apps:</strong> Consumer-oriented websites or mobile applications operated by or for Help-Desk.app, such as smartphone apps.</li>
      <li><strong>Ad Interactions:</strong> If you interact with our ads (for example, by clicking on one or more of our ads on a third-party website), we may receive information about that interaction.</li>
      <li><strong>Data Created by Us:</strong> During our interactions with you, we may create Personal Data about you (for example, records of your purchases on our websites and records of your interactions with our content and emails).</li>
    </ul>
    <p>We also store information about all contacts made with our users, regardless of the type of contact, including content downloaded from our pages, email interactions (such as opens and clicks), contact form submissions, chat, platform registration, and support requests.</p>
    <p>When you click on the content sharing buttons available on our pages for social networks, you will be publishing the content through your profile on the selected network. Help-Desk.app does not have access to your login credentials on these networks, nor will it post content on your behalf without your action.</p>
    <p>It is important to note that all data we collect is used to provide our services. Therefore, all information about you is treated as confidential and will be used only for the purposes described herein and authorized by you, primarily so that you can fully utilize our services. See section 2 for details on what applies to you.</p>

    <h3>1.2. What are the Legal Bases for Data Collection?</h3>
    <p>We use only legally authorized bases for data collection, such as consent, legitimate interest, and the proper execution of contracts. When the legal basis is not consent, we will always comply with current legislation and respect the individual rights and freedoms of data subjects.</p>

    <h2>2. What types of data does Help-Desk.app collect?</h2>
    <h3>2.1. Website Visitors</h3>
    <p>If you merely visit our websites, we collect your browsing data. For example, when you visit our site, a "cookie" is placed in your browser via Google Analytics and Facebook Pixel to identify how often you return.</p>
    <p>"Cookies" are identifiers that we transfer to your browser or device, allowing us to recognize your device and informing us how and when the pages and resources of our Services are accessed, as well as the number of visitors.</p>
    <p>We also use cookies and browsing data, such as URLs, to collect information about the date and time of your visit and the solutions and information you searched for and viewed. As with most tech companies, we automatically collect this personal data and store it in log files whenever you visit our site or access your account on our network. We may use session cookies (which expire after you close your browser) and persistent cookies (which remain until you delete them) to offer a more personalized and interactive experience. You can choose to limit, refuse, or disable cookies via your browser settings, although doing so may affect the functionality of certain areas of our site.</p>
    <p>This Privacy Policy does not cover the use of cookies by third parties, and we are not responsible for their privacy practices. Please note that cookies placed by third parties may continue tracking your online activities even after you leave our Services.</p>

    <h3>2.2. Help-Desk.app Leads</h3>
    <p>If you interact with our materials and content, we will collect, in addition to the browsing data mentioned above, information about your visits to our pages and other data you choose to share with us through forms, landing pages, contact forms, chats, and emails.</p>
    <p>To provide content and materials of interest to you, it is sometimes necessary to cross-reference the data we collect from you so that our marketing efforts are aligned with what you expect to receive. We guarantee that this cross-referencing is not done in a discriminatory manner and does not violate your individual rights or freedoms. Additionally, you can request changes or removal of such data processing permissions at any time through our Help Center.</p>
    <p>Examples of how your data might be used include:</p>
    <ul>
      <li>Our employees may occasionally contact you via email or phone to conduct surveys or present products and services;</li>
      <li>Sending you messages regarding support or service, such as alerts, notifications, and updates;</li>
      <li>Communicating with you about products, services, promotions, news, updates, events, and other topics of interest;</li>
      <li>Conducting targeted advertising based on your preferences, interests, and other collected information.</li>
    </ul>

    <h3>2.3. Users of Help-Desk.app Websites and Services</h3>
    <p>If you use any of our services and/or products, we will collect the following categories and types of personal data:</p>
    <ul>
      <li>Contact data (name and email);</li>
      <li>Professional profile data (company name);</li>
      <li>Billing data (name/legal entity, CPF/CNPJ, full address, state registration);</li>
      <li>Security and audit data (IP address, browser type, operating system, and location based on IP).</li>
    </ul>
    <p>For registration in free trial services, we collect:</p>
    <ul>
      <li>Contact data (name and email);</li>
      <li>Professional profile data (company name);</li>
      <li>Security and audit data (IP address, operating system, browser).</li>
    </ul>
    <p>For contracting our services/products, we collect:</p>
    <ul>
      <li>Contact data (name and email);</li>
      <li>Professional profile data (company name);</li>
      <li>Location data (country, state, and city);</li>
      <li>Billing, invoicing, and tax invoice issuance data for clients in Brazil (name/legal entity, CNPJ/CPF, country, full address, state registration);</li>
      <li>Billing, invoicing, and tax invoice issuance data for clients outside Brazil (name, country, full address).</li>
    </ul>
    <p>When you send a contact message through our website, we collect:</p>
    <ul>
      <li>Contact data (name and email);</li>
      <li>Security and audit data (IP address).</li>
    </ul>
    <p>When you initiate a chat with our team via our websites, we collect:</p>
    <ul>
      <li>Contact data (name and email);</li>
      <li>Security and audit data (IP address, browser type, operating system, location based on IP, and referrer page).</li>
    </ul>
    <p>When accessing the Administrative Panel, the Help-Desk.app: Help Desk application, Help-Desk.app: Customer Service, and when handling sensitive data by agents, we collect:</p>
    <ul>
      <li>Agent identifier (name and email);</li>
      <li>Security and audit data (IP address, browser type, operating system, and location based on IP).</li>
    </ul>
    <p>It is important to note that the data collected when accessing the tool and handling sensitive data through the Administrative Panel and the aforementioned applications are not used by us, but are made available to account administrators for consultation, allowing them to identify when an agent accessed the platform and who made modifications to sensitive data in your account.</p>
    <p>When accessing the Agent Panel, a "cookie" is placed in your browser via Google Analytics to identify how often you return to our site.</p>

    <h3>2.4. Users of Help-Desk.app Client Websites</h3>
    <p>Help-Desk.app provides its clients, through software such as the Client Panel, Knowledge Base, Online Support, and the Help-Desk.app: My Support application, tools for creating and tracking support tickets, chats, and article consultations, among others, which involve managing the personal information of users with whom your clients have relationships.</p>
    <p>However, it is important to note that Help-Desk.app does not control the information collected by third parties using its system. Such data belong to Help-Desk.app's clients, who use, disclose, and protect them according to their own privacy policies, and are responsible for obtaining user consent to collect, manage, and process such confidential information.</p>
    <p>Since Help-Desk.app does not control the data collected directly by its clients, and in order to minimize incidents involving personal data, Help-Desk.app adopts the best practices in information security (see section 7) and secure development available in the market so that its clients can use a secure tool to manage their own personal data as well as that of their customers. If you have any questions about how your data are managed, please contact the company providing the service to you.</p>

    <h2>3. With whom does Help-Desk.app share your data?</h2>
    <p>As an online platform, Help-Desk.app may operate in conjunction with other companies across a wide range of activities, including providing location functionalities, working with advertisers, sponsors, and business partners – both online and offline – as well as using dissemination and performance analysis tools. Thus, we reserve the right to share your information, including location data, registration details, and interests, with the partner companies listed below, whenever possible, in an anonymous manner to preserve your privacy as much as possible.</p>
    <p>We do not rent, sell, or transfer your personal data to anyone except our partner companies. We may share your personal data in the following cases:</p>
    <ul>
      <li><strong>Our Partners:</strong> We engage companies to work on our behalf and need to share your personal data with them to provide products and services to you. For example, we use payment processing services to handle billing data for products purchased by you, and we also use email services for notifications and communications. Our partners are only authorized to use your personal data for the specific purposes for which they were contracted.</li>
      <li><strong>Statistics:</strong> The data stored by Help-Desk.app may be used for statistical purposes (analytics). These data are aggregated to provide a macro-level analysis and are not intended to identify data subjects, but only to understand how our platforms are accessed, in order to improve our services and tailor products to users' interests.</li>
      <li><strong>Protection of Rights:</strong> We reserve the right to access, read, preserve, and disclose any data that we deem necessary to comply with legal obligations or a court order; to enforce our Software Terms of Use and other agreements; or to protect the rights, property, or security of Help-Desk.app, its employees, users, or others.</li>
    </ul>

    <h2>4. International Data Transfer</h2>
    <p>Help-Desk.app collects and transfers personal data collected in Brazil to countries within the European Union and to the USA. This transfer occurs for Help-Desk.app partners involved in personal data processing and involves only partner companies that have demonstrated, or are in the process of demonstrating, compliance with the GDPR and Brazilian data protection laws.</p>
    <p>Help-Desk.app is headquartered in Brazil, and the data we collect are governed by Brazilian law. By accessing or using Help-Desk.app's Services or providing us with personal data, you agree to the processing and transfer of such data to Brazil and the other aforementioned countries.</p>

    <h2>5. What rights do data subjects have?</h2>
    <p>You may choose not to disclose your data to us, but please note that some of these data may be necessary for you to register on our platforms or use certain features and products we offer. Regardless, you have rights regarding the privacy and protection of your personal data. Below is a summary of the rights you have under the Brazilian General Data Protection Law (LGPD), other Brazilian sector-specific data protection laws, and the General Data Protection Regulation (GDPR):</p>
    <ul>
      <li>Right of access;</li>
      <li>Right to rectification;</li>
      <li>Right to deletion;</li>
      <li>Right to confirmation of data processing;</li>
      <li>Right to request blocking or deletion;</li>
      <li>Right to be informed by public or private entities;</li>
      <li>Right to restrict processing;</li>
      <li>Right to review automated decisions;</li>
      <li>Right to object to processing;</li>
      <li>Right to an explanation of the logic behind the collection of your data;</li>
      <li>Right to be informed about the possibility of not providing consent and the consequences thereof;</li>
      <li>Right to withdraw your consent.</li>
    </ul>
    <p>Below is a brief explanation of some of these rights:</p>
    <ul>
      <li><strong>Right to access:</strong> You may request and receive a copy of the personal data we hold about you.</li>
      <li><strong>Right to rectification:</strong> You may request that your personal data be corrected if you find any inaccuracies. To process such a correction, we will need to verify the validity of the data you provide.</li>
      <li><strong>Right to deletion:</strong> You may request that we delete your personal data from our platform. All collected data will be deleted from our servers when you request it or when they are no longer necessary for providing our services, except if there is any other legal reason to retain them.</li>
      <li><strong>Right to object:</strong> If we are processing your data based on legitimate interest (either ours or a third party’s) and you have specific reasons to object to such processing, you may do so—especially in cases of processing for direct marketing purposes. In some cases, we may demonstrate that we have legitimate grounds for processing your data that override your rights, if such processing is essential for providing the product or service.</li>
      <li><strong>Right to request blocking or deletion:</strong> You may ask us to suspend the processing of your personal data in certain scenarios, such as if you want us to verify the accuracy of the data or if you object to its use, while we verify whether we have legitimate reasons to use it.</li>
      <li><strong>Right to withdraw consent:</strong> You have the right to withdraw your consent at any time, although this will not affect the lawfulness of the processing carried out before the withdrawal. If you withdraw your consent, we may not be able to provide certain products or services, and we will inform you if that is the case.</li>
      <li><strong>Right to review automated decisions:</strong> You also have the right to request a review of decisions made solely based on automated processing of your personal data that affect your interests.</li>
    </ul>
    <p>We may ask for specific information to help confirm your identity and ensure your right to access your personal data (or to exercise your other rights). This is a security measure to ensure that personal data is not disclosed to anyone not entitled to receive it. We may also contact you for further details regarding your request in order to expedite our response. Your legitimate requests will be addressed within up to 5 (five) business days. If your request is particularly complex or if you submit multiple requests, we will notify you and keep you updated on the progress.</p>
    <p>If you have any questions about these matters or how you can exercise your rights, please feel free to contact us through our contact form.</p>

    <h2>6. How long will Help-Desk.app store your data?</h2>
    <p>We will retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, including to comply with any legal, contractual, or regulatory obligations.</p>
    <p>To determine the appropriate retention period, we consider the quantity, nature, and sensitivity of the personal data, the potential risk of damage from unauthorized use or disclosure, the purpose of processing your data, whether such purposes can be achieved by other means, and the applicable legal requirements.</p>
    <p>If you use our products and services, your account information will be retained as long as your account remains active. If you request cancellation, your information will be deleted within up to 180 (one hundred and eighty) days after account cancellation. Similarly, if you request the deletion of your data and/or account, Help-Desk.app will retain the data for 72 (seventy-two) hours after your deletion request.</p>

    <h2>7. How does Information Security work at Help-Desk.app?</h2>
    <p>Your account is protected by a password to ensure your privacy and security. If you access your account through a third-party website or service, additional login protections may apply. You should prevent unauthorized access to your account and personal data by choosing and safeguarding your password and/or other access mechanisms, limiting access to your computer or device and browser, and logging out when finished.</p>
    <p>To protect your privacy and personal data, Help-Desk.app adopts the best practices in information security and secure development available in the market. This includes designing products and services that safeguard your data and allow you to manage your information directly. In line with this, we use encryption for passwords and other critical information, employ TLS for data transfers between applications, and contract cloud services with security certifications and seals.</p>
    <p>While we strive to protect your account and personal data, we cannot guarantee complete security. Unauthorized account access, hardware or software failures, and other factors may compromise the security of your personal data at any time. Therefore, if you notice or become aware of anything that compromises the security of your data, please contact us through our available support channels.</p>

    <h2>8. How to contact Help-Desk.app's DPO?</h2>
    <p>If you believe that your personal data have been used in a manner inconsistent with this Privacy Policy or your preferences, or if you have any other questions, comments, or suggestions related to this Policy, you can contact our DPO at the following address:</p>
    <p><strong>Email:</strong> <a href="mailto:dpo@help-desk.app">dpo@help-desk.app</a></p>

    <h2>9. Changes to the Privacy Policy</h2>
    <p>As we are continuously striving to improve our services, this Privacy Policy may be updated periodically. We recommend that you visit this page regularly to stay informed about any modifications. If significant changes are made that require your new consent, we will publish the update and request your new consent.</p>
  `
  }
]

function Politica() {
  const { language } = useParams();
  console.log("language", language);
  const [term, setTerm] = useState(null);
  useEffect(() => {
    let found = TERMS.find((t) => t.language === language);
    if(!found) found = TERMS.find((t) => t.language === "pt-br");
    
    setTerm(found);
  }, [language]);
  if(!term) return <div>...</div>;

  return <TermHolder
    dangerouslySetInnerHTML={{ __html: term.content }}
  >
    
  </TermHolder>;
}

const TermHolder=  styled.div`
  padding: 30px;
  line-height: 1.6;
  > h1 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #003adb;
  }
  > h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;

    color: #003adb;
  }
  > h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #003adb;
  }
  > p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  > ul {
    margin-bottom: 10px;
  }
  > li {
    margin-bottom: 5px;
  }
  > a {
    color: #007bff;
    text-decoration: none;
  }
  > a:hover {
    text-decoration: underline;
  }
  > strong {
    font-weight: bold;
  }

`

export default Politica;
