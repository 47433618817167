import React from "react";


import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/helpdesk/logo-small.png";
import I18n from "../../../components/I18n/I18n";

import { Col } from "reactstrap";
import * as S from "../styles";

import { Button } from "../../main-styles";
function Header() {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const navigate = useNavigate();

  function navigateTo(path) {
    navigate(path);
  }

  function scrollToId(id) {

    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <Col md={12}>
      <S.Nav>
        <S.LogoContainer>
          <S.Logo src={logo} />
        </S.LogoContainer>
        <S.Options>
          <div style={{cursor: 'pointer'}}onClick={() => scrollToId("FAQ")}>{t("home.header.faq")}</div>
          <div style={{cursor: 'pointer'}}onClick={() => scrollToId("pricing")}>
            {t("home.header.pricing")}
          </div>
          <div>
            <I18n />
          </div>
        </S.Options>
        <S.ActionButtons>
          {/* <Button

            backgroundColor={COLORS.grey}
            className="animate__animated animate__backInDown"
            onClick={() => navigateTo("/auth/sign-in")}
          >
            Acessar
            <FontAwesomeIcon icon={faPortalEnter} style={{ marginLeft: 5 }} />
          </Button> */}

          <Button
            className="animate__animated animate__backInDown animate__delay-1s"
            onClick={() => navigateTo(`/${language}/workspace/new`)}
          >
            {t("home.header.start-free")}
          </Button>
        </S.ActionButtons>
      </S.Nav>
    </Col>

  );
}

export default Header;
