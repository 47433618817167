import React, { useEffect } from "react";

import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Modal } from "reactstrap";
import styled from "styled-components";
import { KNOWLEDGEBASE } from "../../../../api/KNOWLEDGEBASE";
import { REQ_STATUS } from "../../../../util/constants";
import { Button } from "../../../main-styles";
import { TitleContainer } from "../../styles";
import * as S from "../styled";
import ArticleSelector from "./ArticleSelector";
import NovaPasta from "./Nova";
import FolderNavigator from "./View";

//context
export const FoldersContext = React.createContext(null);

function Pastas() {
  const { parent, id } = useParams();
  const [state, setState] = React.useState({
    status: REQ_STATUS.LOADING,
    folderInfo: null,
    pastas: [],
    artigos: [],
    articleSearch: "",
    showNew: false,
    newFolderName: "",
    newFolderPublic: false,
    newFolderVisible: false,
    selectedFolder: null,
  });
  const {
    status,
    pastas,
    artigos,
    articleSearch,
    showNew,
    newFolderName,
    newFolderPublic,
    newFolderVisible,
    selectedFolder,
    folderInfo,
  } = state;

  useEffect(() => {
    if (selectedFolder) {
      updateField("showNew", true);
    } else {
      updateField("showNew", false);
    }
  }, [selectedFolder]);

  function updateField(field, value) {
    setState((s) => ({ ...s, [field]: value }));
  }

  async function addArticleToFolder(article) {
    if (!folderInfo?.id) {
      toast.error("Selecione uma pasta para adicionar o artigo");
      return;
    }
    setState((s) => ({ ...s, artigos: newArticles }));
    let newArticles = [...artigos, article];
    let response = await KNOWLEDGEBASE.set.addArticleToFolder(id, article.id);
    if (response) {
      toast.success("Artigo adicionado com sucesso");
    }
  }

  function editFolder(id) {
    updateField("selectedFolder", id);
  }

  async function removeArticleFromFolder(articleId) {
    let newArticles = artigos.filter((a) => a.id !== articleId);
    setState((s) => ({ ...s, artigos: newArticles }));
    let response = await KNOWLEDGEBASE.set.removeArticleFromFolder(
      id,
      articleId
    );
    if (response) {
      toast.success("Artigo removido com sucesso");
    }
  }

  function closeFolderViewCallback(result) {
    console.log("RESULT", result);
    const { nova, folder, deleted } = result;
    if(deleted){
      setState((s) => ({ ...s, pastas: pastas.filter((p) => p.id !== deleted.id), selectedFolder: null, showNew: false }));
      return;
    }
    if (nova) {
      setState((s) => ({ ...s, pastas: [...pastas, folder], selectedFolder: null, showNew: false }));
    } else {
      setState((s) => {
        let newPastas = s.pastas.map((p) => {
          console.log(`Comparando ${p.id} com ${folder.id}`);
          if (p.id === folder.id) {
            return folder;
          }
          return p;
        });
        return { ...s, pastas: newPastas, selectedFolder: null };
      });
    }
  }

  return (
    <FoldersContext.Provider
      value={{
        state,
        updateField,
        addArticleToFolder,
        removeArticleFromFolder,
        editFolder,
      }}
    >
      <Navegador>
        <Visualizador>
          <S.Bordered style={{ gap: 0 }}>
            <TitleContainer bottom={true}>
              {folderInfo?.id ? folderInfo.name : "Pastas"}
            </TitleContainer>
            <div style={{ padding: 10, borderBottom: "1px solid #e2e2e2" }}>
              <Button
                style={{ padding: 5 }}
                onClick={() => updateField("showNew", true)}
              >
                Nova pasta
              </Button>
            </div>
            <FolderNavigator parent={parent} id={id} />
          </S.Bordered>
        </Visualizador>
        <Artigos>
          <S.Bordered style={{ gap: 0 }}>
            <TitleContainer bottom={true}>Biblioteca de Artigos</TitleContainer>
            {folderInfo?.id ? (
              <ArticleSelector />
            ) : (
              <div className="alert alert-secondary m-3">
                Não é possível adicionar artigos na raiz da biblioteca
              </div>
            )}
          </S.Bordered>
        </Artigos>
        {showNew && (
          <Modal
            isOpen={showNew}
            toggle={() => {
              updateField("selectedFolder", null);
              updateField("showNew", false);
            }}
          >
            <NovaPasta
              id={selectedFolder}
              parent={id}
              toggle={closeFolderViewCallback}
            />
          </Modal>
        )}
      </Navegador>
    </FoldersContext.Provider>
  );
}

const Navegador = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  height: 100%;
`;
const Visualizador = styled.div`
  // 4 span
  grid-column: span 4;
`;
const Artigos = styled.div`
  // 1 span
  grid-column: span 1;
`;
export default Pastas;
