export const isDev = process.env.NODE_ENV === 'development';

export const API_URL = process.env.REACT_APP_API_URL

export const MAIN_APP_URL = isDev ? 'http://localhost:3000' : 'https://help-desk.app';

export const REQ_STATUS = {
    IDLE: 'idle',
    PENDING: 'pending',
    SUCCESS: 'success',
    ERROR: 'error',
    LOADING: 'loading',
    SENDING: 'sending',
    RELOADING: 'reloading'
};


export const TicketTypes = {
  MINE: "mine",
  MY_COMPANY: "myCompany",
};

export const TICKET_STATUS = { 
  CREATED: 'created',
  QUEUED: 'queued',
  IN_PROGRESS: 'in_progress',
  WAITING_CUSTOMER: 'waiting_customer',
  CLOSED: 'closed',
  RESOLVED: 'resolved'
}

export const TICKET_STATUS_COLORS = {
  created: '#85aef7',
  queued: '#ffb151',
  in_progress: '#8d2ccb',
  waiting_customer: '#f54323',
  closed: '#ccc',
  resolved: '#039f3c'
}

export const TICKET_STATUS_NAMES = {
  created: 'Enviado',
  queued: 'Em fila',
  in_progress: 'Em andamento',
  waiting_customer: 'Aguardando cliente',
  closed: 'Fechado',
  resolved: 'Resolvido'
}

export const USER_STATUS = {
  PREACTIVE: 'preactive',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'deleted',
  BLOCKED: 'blocked'
}

// order that the tickets will be displayed in the client portal
export const CLIENT_TICKET_PRIORITY_ORDER = [
  TICKET_STATUS.WAITING_CUSTOMER,
  TICKET_STATUS.IN_PROGRESS,
  TICKET_STATUS.CREATED,
  TICKET_STATUS.QUEUED,
  TICKET_STATUS.RESOLVED,
  TICKET_STATUS.CLOSED,
]

// order that the tickets will be displayed in the agent portal
// NONE! IT SHOULD BE LISTED AS PRIORITY IN THE DATABASE
export const AGENT_TICKET_PRIORITY_ORDER = [
  TICKET_STATUS.CREATED,
  TICKET_STATUS.QUEUED,
  TICKET_STATUS.IN_PROGRESS,
  TICKET_STATUS.WAITING_CUSTOMER,
  TICKET_STATUS.RESOLVED,
  TICKET_STATUS.CLOSED,
]