import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  FormGroup,
  Label,
  Button,
  Table,
  Spinner,
  Modal,
} from "reactstrap";
import Select from "react-select"; // React-Select
import moment from "moment-timezone";
import { REPORTS } from "../../../../../api/REPORTS";
import ReportLayout from "../../../../../components/ReportLayout";
import { USERS } from "../../../../../api/USERS";
import AgentSelect from "../../../../../components/AgentSelect";
import {
  Description,
  FH,
  Subtitle,
  Text,
  Title,
} from "../../../../main-styles";
import { FilterArea } from "../../styled";
import Stars from "../../../../../components/Stars";
import styled from "styled-components";
import TicketStatus from "../../../../../components/TicketStatus";
import { useNavigate } from "react-router-dom";
import View from "../../../Tickets/View";
import TicketViewer from "../../../../../components/TicketViewer";
import LineUser from "../../../../../components/LineUser";
import Solve from "./Solve";
import { useSelector } from "react-redux";

const LowCSATReport = ({ tenantId }) => {
  const authReducer = useSelector((state) => state.authReducer);
  const { user } = authReducer;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("day").toDate());
  const [csatScore, setCsatScore] = useState(3);
  const [loading, setLoading] = useState(false);
  const [solvingTicket, setSolvingTicket] = useState(null);

  const [previewTicket, setPreviewTicket] = useState(null);

  useEffect(() => {
    // block scroll parent when previewing
    if (previewTicket) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [previewTicket]);

  const updateTicket = (ticket, action) => {
    console.log("Vamos atualizar o ticket:> ", ticket);
    console.log("Com ação:> ", action);
    setSolvingTicket(null);
    setData((prev) =>
      prev.map((item) =>
        item.ticket_id === ticket
          ? {
              ...item,
              csat_action_taken: action,
              csat_issue_solved: true,
              csat_issue_solved_at: new Date().toISOString(),
              csat_issue_solved_by_name: user.name,
              csat_issue_solved_by_email: user.email,
              csat_issue_solved_by_thumbnail: user.thumbnail,

            }
          : item
      )
    );
  };

  // Função para buscar os grupos
  const fetchGroups = async () => {
    try {
      const response = await USERS.get.groups(tenantId);
      setGroups(
        response.map((group) => ({ value: group.id, label: group.name }))
      );
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  // Função para buscar os agentes
  const fetchAgents = async () => {
    try {
      const response = await USERS.get.agents();
      console.log("Agents:", response);
      setAgents(
        response.map((agent) => ({
          value: agent.id,
          label: agent.name,
          image: agent.thumbnail,
          email: agent.email,
        }))
      );
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  // Função para buscar os dados do relatório
  const fetchLowCSATData = async () => {
    console.log("Fetching low CSAT data...");
    setLoading(true);
    try {
      const response = await REPORTS.get.agents.low_csat(
        startDate,
        endDate,
        csatScore,
        selectedGroup?.value || null,
        selectedAgent?.value || null,
        tenantId
      );
      setData(response);
    } catch (error) {
      console.error("Error fetching low CSAT data:", error);
    } finally {
      setLoading(false);
    }
  };

  function gotoTicket(id) {
    navigate(`/agents/tickets/${id}`);
  }

  useEffect(() => {
    fetchGroups();
    fetchAgents();
    fetchLowCSATData();
  }, []);

  return (
    <ReportLayout
      title="Relatório de Clientes com Baixa Satisfação"
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      fetchData={fetchLowCSATData}
      subtitle="Clientes com CSAT Baixo"
      style={{ padding: 0 }}
      button={false}
    >
      <FilterArea style={{ justifyContent: "space-between", paddingTop: 0 }}>
        <Label for="csatScore">Nota CSAT Máxima</Label>

        <div style={{ flex: 1 }}>
          <Select
            id="csatScore"
            options={[1, 2, 3, 4, 5].map((score) => ({
              value: score,
              label: score.toString(),
            }))}
            value={{ value: csatScore, label: csatScore.toString() }}
            onChange={(option) => setCsatScore(option.value)}
            placeholder="Selecione a nota CSAT"
            isClearable={false}
            // make it width auto
          />
        </div>

        <Label for="groupSelect">Grupo</Label>
        <div style={{ flex: 1 }}>
          <Select
            id="groupSelect"
            options={groups}
            value={selectedGroup}
            onChange={setSelectedGroup}
            placeholder="Selecione um grupo"
            isClearable
            styles={{
              menu: (base) => ({
                ...base,
                width: "max-content",
                minWidth: "100%",
              }),
              control: (provided) => ({
                ...provided,
                width: "max-content",
                minWidth: "100%",
              }),
            }}
          />
        </div>

        <Label for="agentSelect">Agente</Label>

        <div style={{ flex: 1 }}>
          <AgentSelect
            value={selectedAgent?.value}
            options={agents}
            onChange={(option) => setSelectedAgent(option)}
            placeholder="Selecione um agente"
          />
        </div>
        <Button color="primary" onClick={fetchLowCSATData}>
          Atualizar
        </Button>
      </FilterArea>
      <div style={{ padding: "3rem" }}>
        {loading ? (
          <div className="text-center my-4">
            <Spinner color="primary" />
            <p>Carregando dados...</p>
          </div>
        ) : data.length === 0 ? (
          <h5 className="text-center">
            Nenhum dado encontrado para os filtros selecionados.
          </h5>
        ) : (
          <Tickets>
            {data
              .sort((a, b) => {
                return b.ticket_date - a.ticket_date;
              })
              .sort((a, b) => {
                // not solved first
                return a.csat_issue_solved - b.csat_issue_solved;
              })
              .map((item, index) => (
                <Ticket style={item.csat_issue_solved ? { opacity: 0.5 } : {}}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={item.user_photo || "https://via.placeholder.com/100"}
                      alt={`${item.user_name}`}
                      style={{
                        width: 100,
                        height: 100,
                        borderRadius: "50%",
                        objectFit: "cover",
                        marginBottom: 10,
                      }}
                    />
                    <Title style={{ fontSize: 15 }}>{item.user_name}</Title>
                    <Description style={{ fontSize: 12, color: "#ccc" }}>
                      {item.user_email}
                    </Description>
                    <Stars stars={item.csat_score} disabled />
                    {item.csat_issue_solved ? (
                      <Title style={{ color: "#cccccc" }}>Resolvido</Title>
                    ) : (
                      <Title style={{ color: "#cccccc" }}>Não Resolvido</Title>
                    )}
                  </div>
                  <TicketData>
                    <FH>
                      <TicketStatus status={item.ticket_status} />
                      <div style={{ lineHeight: 1.2 }}>
                        <Subtitle
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          #{item.ticket_id} - {item.ticket_title}
                        </Subtitle>
                        <Text style={{ fontSize: 12, color: "#858484" }}>
                          {moment(item.ticket_date).format("DD/MM/YYYY HH:mm")}
                        </Text>
                      </div>
                    </FH>
                    <hr />
                    <Text>
                      <strong>Agente:</strong> <br />
                      {item.agent_name ? (
                        <LineUser
                          name={item.agent_name}
                          email={item.agent_email}
                          image={item.agent_thumbnail}
                        />
                      ) : (
                        "Sem agente alocado"
                      )}
                    </Text>
                    <Text>
                      <strong>Tempo de Fechamento:</strong>{" "}
                      {moment
                        .duration(parseFloat(item.closure_time), "hours")
                        .humanize()}
                    </Text>

                    <Text>
                      <strong>O ticket foi resolvido? </strong>
                      {item.csat_solved ? "Sim" : "Não"}
                    </Text>
                    <Text>
                      <strong>Prioridade: </strong>
                      {item.ticket_priority}
                    </Text>
                    <Text>
                      <strong>Mensagens trocadas: </strong>
                      {item.messages_count}
                    </Text>
                    <Text>
                      <strong>Grupo: </strong>
                      {item.group_name || "Sem Grupo"}
                    </Text>
                    <hr />
                    <Text>
                      <strong>Descrição:</strong>
                      <br />
                      {item.ticket_description}
                    </Text>
                    <hr />
                    <FH>
                      <Button
                        color="primary"
                        onClick={() => setPreviewTicket(item.ticket_id)}
                      >
                        Ver Ticket
                      </Button>
                      {!item.csat_issue_solved && (
                        <Button
                          color="primary"
                          onClick={() => setSolvingTicket(item.ticket_id)}
                        >
                          Resolver
                        </Button>
                      )}
                    </FH>
                    {item.csat_issue_solved && (
                      <>
                        <hr />
                        <FH>
                          <strong>
                            <div>Ações de resolução tomadas por: </div>
                            <LineUser
                              name={item.csat_issue_solved_by_name}
                              email={item.csat_issue_solved_by_email}
                              image={item.csat_issue_solved_by_thumbnail}
                            />{" "}
                            em{" "}
                            {moment(item.csat_issue_solved_at).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </strong>
                          <hr/>
                        </FH>
                        <Text>
                          <strong>Ações tomadas</strong>
                        </Text>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.csat_action_taken,
                          }}
                        />
                      </>
                    )}
                  </TicketData>
                </Ticket>
              ))}
          </Tickets>
        )}
      </div>
      {previewTicket && (
        <Modal isOpen={true} size="xl" toggle={() => setPreviewTicket(null)}>
          <TicketViewer ticket={previewTicket} />
        </Modal>
      )}
      {solvingTicket && (
        <Modal isOpen={true} size="xl" toggle={() => setSolvingTicket(null)}>
          <Solve ticket={solvingTicket} update={updateTicket} />
        </Modal>
      )}
    </ReportLayout>
  );
};

const Tickets = styled.div`
  display: grid;
  gap: 1rem;
`;

const Ticket = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 1rem;
`;

const TicketData = styled.div`
  // span 2
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > hr {
    width: 100%;
    margin: 0.5rem 0;
  }
`;

export default LowCSATReport;
