import {
  faArrowUp,
  faBook,
  faNewspaper,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BasicContent from "../../../components/BasicContent";
import { KNOWLEDGEBASE } from "../../../api/KNOWLEDGEBASE";
import styled from "styled-components";
import TagsViewer from "../../../components/TagsViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Article() {
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const { folder, id } = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: true,
    data: [],
    headers: [],
  });

  const { loading, data, headers } = state;

  useEffect(() => {
    init();
  }, [id]);

  async function init() {
    setState((s) => ({ ...s, loading: true, data: [] }));
    try {
      let article = isAuthenticated
        ? await KNOWLEDGEBASE.get.article(id)
        : await KNOWLEDGEBASE.get.public_article(id);

      article.content = addAnchorToHeaders(article.content);
      let headers = extractHeaderAndAnchor(article.content);

      setState((s) => ({ ...s, loading: false, data: article, headers }));
    } catch (error) {
      console.error(error);
      setState((s) => ({ ...s, loading: false, data: [] }));
    }
  }

  function addAnchorToHeaders(content) {
    return content.replace(
      /<h([1-6])(.*?)>(.*?)<\/h[1-6]>/g,
      (match, level, attrs, content) => {
        let id = content
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-")
          .replace(/-$/, "");
        return `<h${level}${attrs} id="${id}">${content}</h${level}>`;
      }
    );
  }

  function extractHeaderAndAnchor(content) {
    let headers = [];
    content.replace(
      /<h([1-6])(.*?)>(.*?)<\/h[1-6]>/g,
      (match, level, attrs, content) => {
        let id = content
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-")
          .replace(/-$/, "");
          let stripped = content.replace(/<[^>]*>?/gm, '');
        headers.push({
          level: level,
          content: stripped,
          id: id,
        });
      }
    );
    return headers;
  }

  function renderHeaders() {
    return headers.map((header) => (
      <a
        href={`#${header.id}`}
        style={{ marginLeft: parseInt(header?.level || 0) * 10 }}
      >
        {header.content}
      </a>
    ));
  }
  function goBackToFolder() {
    navigate(`/folder/${folder}`);
  }
  if (loading) {
    return (
      <BasicContent title={"Carregando..."} icon={faSpinner} back={goBackToFolder}></BasicContent>
    );
  }

  

  console.log("HEADERS: ", headers);

  return (
    <BasicContent title={data.title} icon={faNewspaper} back={goBackToFolder}>
      <TagsViewer
        all={true}
        tags={data.tags}
        style={{
          borderBottom: "1px solid #eaeaea",
          paddingBottom: 10,
        }}
      />
      <Container>
        <Content dangerouslySetInnerHTML={{ __html: data.content }}></Content>
        {headers.length > 0 && (
          <Navigator>
            <a href="#top"><FontAwesomeIcon icon={faArrowUp} /> Topo</a>
            {renderHeaders()}
          </Navigator>
        )}
      </Container>
    </BasicContent>
  );
}
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 1fr;
  // align top
  align-items: start;
`;
const Content = styled.div`
  padding: 30px;
  // grid span 5
  grid-column: span ${(props) => props.span || 4};
`;

const Navigator = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 10px 20px 30px 10px;
  position: sticky;
  top: 40px;
  border-left: 3px solid #eaeaea;
  line-height: 1;
  a {
    margin-bottom: 10px;
    font-size: 14px;
    color: #333;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
      color: #555;
    }
  }
`;

export default Article;
