import axios from "axios";
import { API_URL } from "../../util/constants";

import { store } from "../../redux/configureStore";
// Create an instance of Axios
const api = axios.create({
  baseURL: API_URL, // Replace with your API base URL
});

export const AUTH = {
  get: {
    authenticate: async (email, password, tid) => {
      try {
        let response = await api.post("/auth", { email, password, tid });
        return response;
      } catch (error) {
        console.log("ERROR:", error);
        return {
          error: error.response.data.error,
        };
      }
    },
    recover: async (email) => {
      const { tid } = store.getState().appReducer.app;
      try {
        let response = await api.post(`/users/reset-password/${tid}`, { email });
        return response;
      } catch (error) {
        console.log("ERROR:", error);
        return {
          error: error.response.data.error,
        };
      }
    },
    refreshToken: async () => {},
  },
  set: {
    new: async (data) => {},
    update: async (id, data) => {},
    delete: async (id) => {},
    activate: async (id) => {},
  },
};
