import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

function Stars({ stars, setStars, disabled, sent }) {
  return (
    <STARContainer>
      <STAR
        disabled={disabled}
        active={stars && stars >= 1}
        onClick={() => !sent && setStars(1)}
      >
        <FontAwesomeIcon icon={faStar} />
      </STAR>
      <STAR
        disabled={disabled}
        active={stars && stars >= 2}
        onClick={() => !sent && setStars(2)}
      >
        <FontAwesomeIcon icon={faStar} />
      </STAR>
      <STAR
        disabled={disabled}
        active={stars && stars >= 3}
        onClick={() => !sent && setStars(3)}
      >
        <FontAwesomeIcon icon={faStar} />
      </STAR>
      <STAR
        disabled={disabled}
        active={stars && stars >= 4}
        onClick={() => !sent && setStars(4)}
      >
        <FontAwesomeIcon icon={faStar} />
      </STAR>
      <STAR
        disabled={disabled}
        active={stars && stars >= 5}
        onClick={() => !sent && setStars(5)}
      >
        <FontAwesomeIcon icon={faStar} />
      </STAR>
    </STARContainer>
  );
}

const STARContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const STAR = styled.div`
  color: ${(props) => (props.active ? "#ffbf00" : "#cccccc")};
  transition: color 0.2s;
  cursor: pointer;
  font-size: 1.5rem;
  ${(props) => props.disabled && "pointer-events: none;"}
  &:hover {
    color: #ffce39;
    // scale: 1.1;
    scale: 1.3;
  }
`;

export default Stars;
