import React, { useEffect, useState } from "react";

import * as S from "../../auth/styles";

import { useSelector } from "react-redux";
import mainlogo from "../../../assets/helpdesk/logo-small.png";
import Button from "../../../components/Button";
import Or from "../../../components/Or";

import { NavLink, useParams } from "react-router-dom";
import PoweredLink from "../../../components/PoweredLink";
import { FH, Input, Link, Logo, Text, Title } from "../../main-styles";
import { REQ_STATUS } from "../../../util/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faMagnifyingGlass,
  faSpinner,
  faSquare,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { APP } from "../../../api/APP";
import toast from "react-hot-toast";
import { validateEmail } from "../../../util/functions";
import { useTranslation } from "react-i18next";
import I18n from "../../../components/I18n/I18n";

function NewWorkspace() {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  // let { language } = useParams();
  // if (language === "br") language = "pt-br";
  const { ws } = useParams();
  const { appReducer } = useSelector((state) => state);
  const [agreed, setAgreed] = React.useState(false);
  const [state, setState] = useState({
    workspace: ws || "",
    workspace_loading: false,
    workspace_valid: false,
    workspace_validated: false,
    email: "",
    password: "",
    confirmPassword: "",
    status: REQ_STATUS.IDLE,
  });
  const {
    workspace,
    email,
    password,
    confirmPassword,
    status,
    workspace_loading,
    workspace_valid,
    workspace_validated,
  } = state;
  const { app } = appReducer;
  const colors = app?.colors;
  const backgroundColor = `${colors?.main}1c`;

  useEffect(() => {
    // focus on the first input
    document?.getElementById("workspace")?.focus();
    // title
    let windowtitle = t("new-workspace.help-desk-app-novo-workspace");
    document.title = windowtitle;
  }, []);

  async function verifyWorkspace() {
    if (workspace_validated || workspace_loading) return;
    let error;
    if (workspace.length < 3) {
      error = t(
        "new-workspace.o-nome-do-workspace-deve-ter-no-minimo-3-caracteres"
      );
      toast.error(error);
      setState((s) => ({
        ...s,
        workspace_loading: false,
        workspace_validated: true,
        workspace_valid: false,
        status: REQ_STATUS.IDLE,
      }));
      return;
    }
    setState((s) => ({
      ...s,
      workspace_loading: true,
      workspace_validated: false,
      workspace_valid: false,
    }));
    let available = await APP.get.check(workspace);
    console.log("AVAILABLE / workspace_valid", available);
    if (!available) {
      error = t("new-workspace.ja-existe-um-workspace-com-esse-nome");
      toast.error(error);
    }
    setState((s) => ({
      ...s,
      workspace_loading: false,
      workspace_validated: true,
      workspace_valid: available,
      status: REQ_STATUS.IDLE,
    }));
  }

  async function validate() {
    let error;
    if (status !== REQ_STATUS.IDLE) return;
    console.log("STATUS", status);
    if (!workspace_valid) {
      toast.error(t("new-workspace.o-nome-do-workspace-nao-e-valido"));
      return;
    }
    if (!agreed) {
      toast.error(
        t(
          "new-workspace.voce-precisa-concordar-com-os-termos-de-uso-e-politica-de-privacidade"
        )
      );
      return;
    }
    if (password.length < 6) {
      toast.error(t("new-workspace.a-senha-deve-ter-no-minimo-6-caracteres"));
      return;
    }
    if (password !== confirmPassword) {
      toast.error(t("new-workspace.as-senhas-nao-conferem"));
      return;
    }
    if (!validateEmail(email)) {
      toast.error(t("new-workspace.email-invalido"));
      return;
    }
    createWS();
  }

  async function createWS() {
    setState((s) => ({ ...s, status: REQ_STATUS.PENDING }));
    console.log("CREATE WS");
    try {
      let response = await APP.set.cadastro_basico({
        workspace,
        email,
        password,
      });
      console.log("RESPONSE", response);
      setState((s) => ({ ...s, status: REQ_STATUS.SUCCESS }));
    } catch (error) {
      console.log("ERROR", error);
      toast.error(t("new-workspace.erro-ao-criar-workspace"));
      setState((s) => ({ ...s, status: REQ_STATUS.IDLE }));
    }
  }

  let icon;
  if (workspace_loading || workspace_validated) {
    icon = workspace_loading ? faSpinner : workspace_valid ? faCheck : faTimes;
  }

  if (status === REQ_STATUS.SUCCESS) {
    // full screen success, check email

    return (
      <S.WorkspaceContainer backgroundColor={backgroundColor}>
        <S.WorkspaceWhiteContainer style={{ alignItems: "center" }} full={true}>
          <Logo src={app?.darklogo || mainlogo} />
          <div style={{ borderBottom: "2px solid #dddddd", width: "50%" }} />
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ fontSize: 50, color: "#00b324" }}
          />
          <Title>{t("new-workspace.verifique-seu-email")}</Title>
          <Text style={{ marginTop: 20 }}>
            {t("new-workspace.enviamos-um-email-para", email)}
          </Text>
        </S.WorkspaceWhiteContainer>
      </S.WorkspaceContainer>
    );
  }

  return (
    <S.WorkspaceContainer backgroundColor={backgroundColor}>
      <S.WorkspaceWhiteContainer>
        <Logo src={app?.darklogo || mainlogo} />
        <I18n />
        <Title style={{ marginTop: 30 }}>
          {t("new-workspace.novo-workspace")}
        </Title>
        <Text style={{ textAlign: "left", width: "100%", marginTop: 20 }}>
          {t("new-workspace.vamos-configurar-o-workspace-de-sua-empr")}
        </Text>
        <FH>
          <Text>https://</Text>
          <Input
            id="workspace"
            type="text"
            placeholder={t("new-workspace.sua-empresa")}
            value={workspace}
            onBlur={verifyWorkspace}
            onChange={(e) =>
              setState({
                ...state,
                workspace: e.target.value,
                workspace_valid: false,
                workspace_validated: false,
                workspace_loading: false,
              })
            }
            style={{
              maxWidth: 150,
              border: 0,
              height: 20,
              borderBottom: "1px solid #ccc",
              borderRadius: 0,
            }}
          />
          <Text>.help-desk.app</Text>
          {icon && (
            <div style={{ display: "inline-block" }}>
              <FontAwesomeIcon
                icon={icon}
                style={{
                  marginLeft: 10,
                  color: workspace_loading
                    ? "#ccc"
                    : workspace_valid && workspace_validated
                    ? "#ccc"
                    : "#e74009",
                }}
                spin={workspace_loading ? true : false}
              />
            </div>
          )}
        </FH>

        <Input
          type="text"
          placeholder={t("new-workspace.email")}
          autoComplete="email"
          value={email}
          onChange={(e) => setState({ ...state, email: e.target.value })}
          style={{ marginTop: 30 }}
        />
        <Input
          type="password"
          placeholder={t("new-workspace.senha")}
          autoComplete="new-password"
          value={password}
          onChange={(e) => setState({ ...state, password: e.target.value })}
        />
        <Input
          type="password"
          placeholder={t("new-workspace.confirmacao-de-senha")}
          value={confirmPassword}
          onChange={(e) =>
            setState({ ...state, confirmPassword: e.target.value })
          }
        />
        <FH onClick={() => setAgreed(!agreed)}>
          <FontAwesomeIcon icon={agreed ? faCheckSquare : faSquare} />

          <Text style={{ marginLeft: 10, textAlign: "left" }}>
            {t("new-workspace.concordo-com-os")}{" "}
            <a
              href={`https://www.help-desk.app/${language}/termos-de-uso`}
              target="_blank"
            >
              {t("new-workspace.termos-de-uso")}
            </a>{" "}
            {t("new-workspace.and")}{" "}
            <a
              href={`https://www.help-desk.app/${language}/privacidade`}
              target="_blank"
            >
              {t("new-workspace.politica-de-privacidade")}
            </a>
          </Text>
        </FH>
        <Button onClick={validate}>
          {status === REQ_STATUS.PENDING
            ? t("new-workspace.creating-wait")
            : t("new-workspace.create-workspace")}
        </Button>
        <Or />
        <Text>
          {t("new-workspace.ja-tem-uma-conta")}{" "}
          <NavLink to="/auth/sign-in" style={{ color: colors?.main }}>
            {t("new-workspace.clique-aqui")}
          </NavLink>
        </Text>
      </S.WorkspaceWhiteContainer>
    </S.WorkspaceContainer>
  );
}

export default NewWorkspace;
