import React from "react";
import * as S from "./styles";
import { Button } from "../main-styles";
import { useNavigate } from "react-router-dom";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMinus, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
const Pricing = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const navigate = useNavigate();
  console.log("language", language);
  function goto() {
    navigate(`/${language}/workspace/new`);
  }
  function openmail() {
    const email = "contato@help-desk.app";
    const subject =
      language === "pt-BR" ? "Cotação para enterprise" : "Quote for enterprise";
    const body =
      language === "pt-BR"
        ? `Olá, gostaria de saber mais sobre a cotação para o plano enterprise.
%0D%0A%0D%0A
Preciso de XXXX agentes. %0D%0A
Atendemos cerca de XXXX tickets por mês.
 %0D%0A%0D%0A
Nome da empresa: XXXX%0D%0A
Nome do contato: XXXX%0D%0A
Telefone para contato: XXXX (whatsapp) %0D%0A%0D%0A

Obrigado!
        `
        : `Hello, I would like to know more about the quote for the enterprise plan.
%0D%0A%0D%0A
I need XXXX agents. %0D%0A
We serve about XXXX tickets per month.
  %0D%0A%0D%0A
Company name: XXXX%0D%0A
Contact name: XXXX%0D%0A
Contact phone: XXXX (whatsapp) %0D%0A%0D%0A
`;
    const mailto = `mailto:${email}?subject=${subject}&body=${body}`;
    window.open(mailto, "_blank");
  }
  return (
    <>
      <S.Container id="pricing">
        <h1>{t('home.pricing.precificacao')}</h1>
        <div className="pricing-table">
          <div className="column">
            <p>{t("home.pricing.gratuito")}</p>
            <h2>{t("home.pricing.price")}</h2>
            <p>{t('home.pricing.ate-4-agentes')}</p>
            <Button onClick={goto}>{t('home.pricing.escolher')}</Button>
          </div>
          <div className="column" style={{ backgroundColor: "#f6fff6" }}>
            <p>
              {t('home.pricing.pro-plan')}
              <br />
              <span style={{ color: "#00c71b", fontWeight: "bold" }}>
                {t('home.pricing.mais-escolhido')}
              </span>
            </p>
            <h2>{t('home.pricing.r12-por-agente')}</h2>
            <p>{t('home.pricing.por-mes')}</p>
            <Button backgroundColor="#00c71b" onClick={goto}>
              {t('home.pricing.escolher')}
            </Button>
          </div>
          <div className="column">
            <p>{t('home.pricing.enterprise-plan')}</p>
            <h2>{t('home.pricing.customizado')}</h2>
            <p>{t('home.pricing.muitos-agentes')}</p>
            <Button backgroundColor="#1d1d1d" onClick={openmail}>
              {t('home.pricing.solicitar-contato')}
            </Button>
          </div>
        </div>
      </S.Container>
      <S.Container id="resources">
        <h2>{t('home.pricing.recursos')}</h2>
        <ResourcesList />
      </S.Container>
    </>
  );
};

function ResourcesList() {

  const { t, i18n } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th style={{ textAlign: "center" }}>{t('home.pricing.gratuito')}</th>
          <th style={{ textAlign: "center" }}>{t('home.pricing.pro-plan')}</th>
          <th style={{ textAlign: "center" }}>{t('home.pricing.enterprise-plan')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t('home.pricing.agentes')}</td>
          <td style={{ textAlign: "center" }}>{t('home.pricing.ate-4')}</td>
          <td style={{ textAlign: "center" }}>{t('home.pricing.ilimitados')}</td>
          <td style={{ textAlign: "center" }}>{t('home.pricing.ilimitados')}</td>
        </tr>
        <tr>
          <td>{t('home.pricing.assistente-de-respostas-por-ia')}</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>{t('home.pricing.resumo-de-chamados-por-ia')}</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>{t('home.pricing.pesquisa-de-satisfacao-csat')}</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>{t('home.pricing.tickets-ilimitados')}</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>{t('home.pricing.contatos-ilimitados')}</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>{t('home.pricing.empresas-ilimitadas')}</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>{t('grupos-ilimitados')}</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>{t('home.pricing.abertura-de-chamado-por-email')}</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>{t('home.pricing.painel-de-suporte-para-abertura-de-chama')}</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        <tr>
          <td>{t('home.pricing.base-de-conhecimento')}</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>

        <tr>
          <td>{t('home.pricing.relatorios-de-chamados')}</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr>
        {/* <tr>
          <td>Subdomínio .help-desk.app</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr> */}
        {/* <tr>
          <td>Domínio customizado</td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faTimes} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faTimes} />
          </td>
          <td style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faCheck} />
          </td>
        </tr> */}
      </tbody>
    </Table>
  );
}

export default Pricing;
