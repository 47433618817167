import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { REQ_STATUS } from "../../../../../util/constants";
import { TitleContainer } from "../../../styles";
import { Content } from "../../styled";
import { Button, Description, Input, Label } from "../../../../main-styles";
import toast from "react-hot-toast";
import { USERS } from "../../../../../api/USERS";
import { Spinner } from "reactstrap";
const roles = [
  { value: "agent", label: "Agente" },
  { value: "manager", label: "Gerente" },
  { value: "admin", label: "Administrador" },
];
function NovoGrupo({ toggle, id }) {
  const { app } = useSelector((state) => state.appReducer);

  const [state, setState] = useState({
    status: REQ_STATUS.IDLE,
    nome: "",
  });

  const { nome, status } = state;

  useEffect(() => {
    if (id) {
      loadGroup();
    }
  }, []);

  async function loadGroup() {
    try {
      const response = await USERS.get.group(id);
      console.log("RESPONSESESES", response);
      setState({ ...state, nome: response.name });
    } catch (error) {
      toast.error("Erro ao carregar grupo");
    }
  }

  async function save() {
    if (state.status !== REQ_STATUS.IDLE) return;
    if (!nome) {
      return toast.error("Nome é obrigatório");
    }
    setState((s) => ({ ...state, status: REQ_STATUS.SENDING }));
    if (id) {
      try {
        const response = await USERS.set.updateGroup(nome, id);
        toast.success("Grupo atualizado com sucesso");
        setState({ ...state, status: REQ_STATUS.IDLE });
        toggle();
      } catch (error) {
        console.log(error);
        toast.error("Erro ao atualizar grupo");
        setState({ ...state, status: REQ_STATUS.IDLE });
      }
      
    } else {
      try {
        const response = await USERS.set.newGroup(nome);
        toast.success("Grupo adicionado com sucesso");
        setState({ ...state, nome: "", status: REQ_STATUS.IDLE });
        toggle();
      } catch (error) {
        console.log(error);

        let erro = error.response.data.error;
        switch (erro) {
          case "Group already exists":
            toast.error("Já existe um grupo com este nome");
            break;

          default:
            toast.error(erro);
            break;
        }
        setState({ ...state, status: REQ_STATUS.IDLE });
      }
    }
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <Spinner style={{ width: "3rem", height: "3rem" }} color="primary" />
    );
  }

  return (
    <div style={{ overflow: "auto" }}>
      <TitleContainer bottom={true}>
        {id ? "Editar Grupo" : "Novo Grupo"}
      </TitleContainer>
      <Content>
        <Label>Nome</Label>
        <Input
          value={nome}
          onChange={(e) => setState({ ...state, nome: e.target.value })}
        />
        {id ? (
          <Button onClick={save}>
            {status === REQ_STATUS.SENDING ? "Salvando..." : "Salvar"}
          </Button>
        ) : (
          <Button onClick={save}>
            {status === REQ_STATUS.SENDING ? "Adicionando..." : "Adicionar"}
          </Button>
        )}
      </Content>
    </div>
  );
}

export default NovoGrupo;
