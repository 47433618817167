import React, { useEffect, useState } from "react";

import { faColumns, faColumns3 } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import {
  AGENT_TICKET_PRIORITY_ORDER,
  REQ_STATUS,
} from "../../../util/constants";
import { Button, FH } from "../../main-styles";
import * as S from "../styles";
import List from "./List";
import View from "./View";
import Resizable from "react-resizable-layout";
import { Spinner } from "reactstrap";
import { USERS } from "../../../api/USERS";
import { TICKETS } from "../../../api/TICKETS";

// create agents context
export const TicketsContext = React.createContext(null);

function Tickets() {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const params = useParams();
  const [maxed, setMaxed] = useState(true);
  const [width, setWidth] = useState(300);
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    data: [],
    page: query?.page || 0,
    perPage: query?.perPage || 10,
    needle: query?.needle || "",
    currentTicket: params?.id || "",
    agents: [],
    loadedAgents: false,
    tickets: [],
    loading: true,
    hasMore: true,
    onlyMine: true,
    showClosed: false,
  });

  const {
    status,
    data,
    page,
    perPage,
    needle,
    currentTicket,
    agents,
    loadedAgents,
    tickets,
    loading,
    hasMore,
    onlyMine,
    showClosed,
  } = state;

  useEffect(() => {
    loadMoreTickets(true);
  }, [showClosed, onlyMine]);

  async function loadMoreTickets(init) {
    if (!hasMore && !init) return;
    setState((s) => ({ ...s, loading: true }));
    // load tickets
    if (init) updateField("page", 0);
    let newTickets = init
      ? await TICKETS.get.agents.all(0, perPage, showClosed, onlyMine)
      : await TICKETS.get.agents.all(page, perPage, showClosed, onlyMine);
    // concat only non existant tickets

    newTickets = ordenaTickets(newTickets);
    setState((s) => {
      const hasMore = newTickets.length === perPage;
      if (init)
        return { ...s, tickets: newTickets, page: 1, loading: false, hasMore };
      return {
        ...s,
        tickets: s.data.concat(newTickets),
        page: s.page + 1,
        loading: false,
        hasMore,
      };
    });

    if (init) {
      const ticket = newTickets[0];
      // select first ticket
      if (ticket) setState((s) => ({ ...s, currentTicket: ticket.id }));

      if (ticket) {
        navigate(`/agents/tickets/${ticket.id}`);
      }
    }
  }

  function ordenaTickets(tickets) {
    // primeiro ordena por data de atualização
    tickets = tickets.sort((a, b) => {
      return new Date(b.last_update) - new Date(a.last_update);
    });
    // depois ordena por status de prioridade
    tickets = tickets.sort((a, b) => {
      return (
        AGENT_TICKET_PRIORITY_ORDER.indexOf(a.status) -
        AGENT_TICKET_PRIORITY_ORDER.indexOf(b.status)
      );
    });

    return tickets;
  }

  useEffect(() => {
    init();
    loadAgents();
  }, []);

  async function loadAgents() {
    let agents = await USERS.get.agents();
    setState((s) => ({ ...s, agents, loadedAgents: true }));
  }

  useEffect(() => {
    // rewritePath();
  }, [page, perPage, needle, currentTicket]);

  useEffect(() => {
    if (currentTicket) {
      navigate(`/agents/tickets/${currentTicket}`);
    } else {
      navigate(`/agents/tickets`);
    }
  }, [currentTicket]);

  function rewritePath() {
    // function responsible by rewriting the URL path
    const params = new URLSearchParams();
    params.set("page", page);
    params.set("perPage", perPage);
    params.set("needle", needle);
    params.set("t", currentTicket || "");
    window.history.pushState({}, "", `${window.location.pathname}?${params}`);
  }

  async function init() {
    // get width from local storage
    const width = localStorage.getItem("ticketListWidth");
    if (width) {
      setWidth(width);
    }
    setState((s) => ({ ...s, status: REQ_STATUS.IDLE }));
  }

  function updateField(field, value) {
    setState((s) => ({ ...s, [field]: value }));
  }

  function updateTicket(id, field, value) {
    console.log(
      `Vamos atualizar o campo ${field} do ticket ${id} para ${value}`
    );

    setState((s) => {
      console.log("S.tickets", s.tickets);
      let novaData = s.tickets.map((ticket) => {
        if (parseInt(ticket.id) === parseInt(id)) {
          console.log("Encontramos o ticket", ticket);
          return { ...ticket, [field]: value };
        } else {
          console.log(`Não era o ticket: ${id} !== ${ticket.id}`);
        }
        return ticket;
      });
      // reordena
      novaData = ordenaTickets(novaData);
      return {
        ...s,
        tickets: novaData,
      };
    });
  }

  function selectTicket(ticket) {
    console.log("selectedTicket", ticket);
    updateField("currentTicket", ticket);
  }

  function saveWidth(width) {
    console.log("width", width.position);
    // save to local storage
    localStorage.setItem("ticketListWidth", width.position);
    setWidth(width.position);
  }

  function clearSelection() {
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  }

  function callForUrl() {
    fetch("http://localhost:3005/emit-novo-ticket");
  }

  function ticketRespondido() {
    fetch("http://localhost:3005/emit-ticket-respondido");
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <TicketsContext.Provider
      value={{
        state,
        updateField,
        selectTicket,
        updateTicket,
        loadMoreTickets,
      }}
    >
      <S.TicketsGrid maxed={maxed}>
        <Resizable
          axis={"x"}
          onResizeEnd={saveWidth}
          width={width}
          min={250}
          initial={width}
        >
          {({ position, separatorProps }) => (
            <>
              <S.TicketsList maxed={maxed} style={{ width: position }}>
                {/* <S.TicketListLeft>
            <S.TitleContainer>Inbox</S.TitleContainer>
            <div>
              Meus
              <br />
              Prioridade Alta
              <br />
              Todos
              <br />
              Não Atribuídos
              <br />
              Finalizados
              <br />
            </div>
          </S.TicketListLeft> */}
                {/* <div
        {...separatorProps}
        style={{
          ...separatorProps.style,
          left: position + separatorProps.style.left,
        }}
      /> */}

                <S.TicketListRight maxed={maxed}>
                  <S.TitleContainer bottom={true}>
                    <FH
                      style={{ width: "100%", justifyContent: "space-between" }}
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setMaxed(!maxed)}
                      >
                        <FontAwesomeIcon
                          icon={maxed ? faColumns3 : faColumns}
                        />
                      </div>
                      <span>Tickets</span>
                    </FH>
                  </S.TitleContainer>
                  <List />
                </S.TicketListRight>
              </S.TicketsList>
              <S.Separator
                {...separatorProps}
                style={{
                  ...separatorProps.style,
                }}
                onMouseDown={clearSelection}
                onMouseMove={clearSelection}
              />
              <S.TicketView maxed={maxed}>
                <View />
              </S.TicketView>
              <S.TicketDetails>
                <S.TitleContainer bottom={true}>Detalhes</S.TitleContainer>
                {/* <Button onClick={callForUrl}>Emitir Novo Ticket</Button>
                <Button onClick={ticketRespondido}>Ticket Repondido</Button> */}
              </S.TicketDetails>
            </>
          )}
        </Resizable>
      </S.TicketsGrid>
    </TicketsContext.Provider>
  );
}

export default Tickets;
